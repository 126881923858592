import React from "react";
import PropTypes from "prop-types";
import { Button, Card, Col, Radio, Row, Select, Checkbox, Switch, Form, Input, Popconfirm } from 'antd';
import { Icon } from '@ant-design/compatible';
import { FolderOutlined, TableOutlined, ForkOutlined, SkinOutlined, BgColorsOutlined } from '@ant-design/icons';
//import Icon from '@ant-design/icons';
import { withTranslation } from 'react-i18next'

import { isEmptyValue, isUndefined } from "../../../utils/JsObjectHelper";
//import {folderPropShape} from "../../../shapes/BmcEntryShape";
//import ButtonGroup from "antd/lib/button/button-group";
import ColorPickerModal from "../../controls/ColorPickerModal";
import IconPickerModal from "../../controls/IconPickerModal";
import { getEntryTypeAttributesSelectOptions } from "../../../utils/EntryTypeHelper";
import { SETTINGS_WORKFLOW_USETYPE_ONRUNBYUSER } from "../../../utils/EmptySettings";
const Option = Select.Option;

class FolderProperties extends React.Component {

	constructor(props) {
		super(props);
		this.onMount = this.onMount.bind(this);
		this.handleColorModalVisible = this.handleColorModalVisible.bind(this);
		this.handleIconModalVisible = this.handleIconModalVisible.bind(this);
		this.handleColorChange = this.handleColorChange.bind(this);
		this.handleIconChange = this.handleIconChange.bind(this);

		this.state = {
			isColorModalVisible: false,
			isIconModalVisible: false,
			deleteAttribut: false,
			showPopover: false,
			deleteType: null
		}
	}

	componentDidMount() {
		this.onMount();
	}

	onMount() {
		this.props.onMount();
	}

	handleColorModalVisible = (visible) => {
		this.setState({ isColorModalVisible: visible });
	};

	handleIconModalVisible = (visible) => {
		this.setState({ isIconModalVisible: visible });
	};

	handleColorChange = (e) => {
		this.props.formRef.current.setFieldsValue({ properties: { folderIconColor: e } });
		this.setState({ isColorModalVisible: false });
	};

	handleIconChange = (e) => {
		this.props.formRef.current.setFieldsValue({ properties: { folderIcon: e } });
		this.setState({ isIconModalVisible: false });
	};

	handleChangeDeselect = (deleteType) => {
		let delType = this.props.entryTypesRequestResult.getData().find((el) => el.type === deleteType);
		let attributeInForm = this.props.formRef.current.getFieldValue(["properties", "childrenDisplayAttributes"]);
		let usingAtt = attributeInForm.find((att) => delType.properties.attributes.find((el) => el.techName === att));
		if (usingAtt) {
			this.setState({
				showPopover: true,
				deleteType: deleteType
			});
			this.props.disabledBtn(true);
		}
	};
	handleOk = () => {
		let attributeInForm = this.props.formRef.current.getFieldValue(["properties", "childrenDisplayAttributes"]);
		let entryTypeInForm = this.props.formRef.current.getFieldValue(["properties", "childObjectEntryTypes"]);
		let delType = this.props.entryTypesRequestResult.getData().find((el) => el.type === this.state.deleteType);
		let delAttribut = attributeInForm.filter((att) => delType.properties.attributes.find((el) => el.techName === att));
		let attributeUsing = []
		this.props.entryTypesRequestResult.getData().forEach((entryType) => {
			entryTypeInForm.forEach((en) => {
				if (en === entryType.type) {
					attributeUsing = entryType.properties.attributes.filter((attribute) => attributeInForm.find((el) => el === attribute.techName));
					let index = delAttribut.findIndex((el) => attributeUsing.find((att) => el === att.techName))
					if (index != -1) {
						delAttribut.splice(index, 1);
					}
				}
			});
		});


		//attributeUsing.forEach((el) => index = delAttribut.indexOf(el.techName));

		delAttribut.forEach((el) => {
			attributeInForm.splice(attributeInForm.indexOf(el), 1);
		});

		this.props.formRef.current.setFieldsValue({ properties: { childrenDisplayAttributes: attributeInForm } });
		this.setState({ showPopover: false });
		this.props.disabledBtn(false);
	}
	handleCancel = () => {
		let entryTypeInForm = this.props.formRef.current.getFieldValue(["properties", "childObjectEntryTypes"]);
		entryTypeInForm.push(this.state.deleteType);

		this.props.formRef.current.setFieldsValue({ properties: { childObjectEntryTypes: entryTypeInForm } });
		this.setState({ showPopover: false });
		this.props.disabledBtn(false);
	}

	render() {
		const { t } = this.props;

		let options = null;
		let optionsSubFolders = null;
		/** @type {RequestResult} */
		const rr = this.props.entryTypesRequestResult;

		options = (!isUndefined(rr.getData()) && !isEmptyValue(rr.getData())) ?
			rr.getData().filter(oT => oT.systemType !== "folder").map(d => <Option value={d.type} key={d.name} title={d.name}>{d.name}</Option>) :
			[];

		//oT.type === "folder" => now types are only filtered to simple folder
		//This will be deleted when users can create new folder types
		optionsSubFolders = (!isUndefined(rr.getData()) && !isEmptyValue(rr.getData())) ?
			rr.getData().filter(oT => oT.systemType === "folder" && oT.type === "folder").map(d => <Option value={d.type} key={d.name} title={d.name}>{d.name}</Option>) :
			[];

		let lineageOptions = this.props.lineageSettingsList.map(lS => <Option value={lS.name} key={lS.name}>{lS.value.lineageName}</Option>);

		const entryTechAttoptions = [
			{ label: t('app.entry.tools.technical.tblCreated'), value: 'created' },
			{ label: t('app.entry.tools.technical.tblCreatedBy'), value: 'createdByUserName' },
			{ label: t('app.entry.tools.technical.tblLastUpdated'), value: 'updated' },
			{ label: t('app.entry.tools.technical.tblLastUpdatedBy'), value: 'modifiedByUserName' },
			{ label: t('app.entry.edit.lblFillQuality'), value: 'fillQuality' },
		];
		let workflowForFolder = (isUndefined(this.props.workflowSettingsList) ? null :
        this.props.workflowSettingsList
        .filter((w) => 
            w.value.workflowUseType === SETTINGS_WORKFLOW_USETYPE_ONRUNBYUSER && w.value.isEnabled && w.value.objectType === "folder"
        )
        .map((w) => {
            return <Option value={w.name} key={w.name} title={w.value.workflowName}>{w.value.workflowName}</Option>;
        })
    );

		return [
			<Row align="top" gutter={[{ xs: 6, sm: 6, md: 6, lg: 12 }, 20]} key="entryTypesRow">
				<Col xs={24} sm={24} md={12} lg={12} xl={12}>
					<Form.Item label={t('app.entry.edit.lblSubFolderTypes')} key="childFolderEntryTypes"
						name={["properties", "childFolderEntryTypes"]}
					>
						<Select
							showSearch
							mode="multiple"
							// style={{width: 300}}
							placeholder={t('app.entry.edit.lblSubFolderTypesHint')}
							optionFilterProp="value"
							filterOption={(input, option) =>
								//option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
								option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							allowClear={true}
						>
							{optionsSubFolders}
						</Select>
					</Form.Item>
				</Col>
				<Col xs={24} sm={24} md={12} lg={12} xl={12}>
					<Popconfirm
						title={t('app.entry.edit.messageDeleteEntryType')}
						visible={this.state.showPopover}
						onConfirm={this.handleOk}
						onCancel={this.handleCancel}
						okText={t('app.entry.edit.btnYesDelete')}
						cancelText={t('app.entry.edit.btnNoDelete')}
					>
						<Form.Item label={t('app.entry.edit.lblEntryTypes')} key="childObjectEntryTypes"
							name={["properties", "childObjectEntryTypes"]}
						>

							<Select
								showSearch
								mode="multiple"
								disabled={this.state.showPopover}
								// style={{width: 300}}
								placeholder={t('app.entry.edit.lblEntryTypesHint')}
								optionFilterProp="children"
								onDeselect={this.handleChangeDeselect}
								filterOption={(input, option) =>
									//option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								allowClear={true}
							>
								{options}
							</Select>

						</Form.Item>
					</Popconfirm>
				</Col>
			</Row>,
			<Row align="top" gutter={[{ xs: 6, sm: 6, md: 6, lg: 12 }, 20]} key="dispTypeRow">
				<Col xs={24} sm={24} md={12} lg={8} xl={6}>
					{/* Hidden form items for icon type and collor */}
					<Form.Item name={["properties", "folderIcon"]} hidden><Input /></Form.Item>
					<Form.Item name={["properties", "folderIconColor"]} hidden><Input /></Form.Item>

					<Form.Item label={t('app.entry.edit.lblFolderIcon')} key="folderIcon"
					//name={["properties",""]}
					>
						<Card style={{ width: "fit-content" }}>
							<Icon type={
								!isUndefined(this.props.formRef.current) && !isUndefined(this.props.formRef.current.getFieldValue(["properties", "folderIcon"])) ?
									this.props.formRef.current.getFieldValue(["properties", "folderIcon"]) :
									"folder-open"
							}
								style={{
									color: (!isUndefined(this.props.formRef.current) && !isUndefined(this.props.formRef.current.getFieldValue(["properties", "folderIconColor"])) ?
										this.props.formRef.current.getFieldValue(["properties", "folderIconColor"]) :
										"#223b6f")
								}}
								className="folderIconPreview" />
							<Button icon={<SkinOutlined />} onClick={() => this.handleIconModalVisible(true)}>{t('app.entry.edit.btnPickIcon')}</Button>
							<IconPickerModal
								iconValue={!isUndefined(this.props.formRef.current) ? this.props.formRef.current.getFieldValue(["properties", "folderIcon"]) : "folder-open"}
								isVisible={this.state.isIconModalVisible}
								onCancel={() => this.handleIconModalVisible(false)}
								onSelect={this.handleIconChange}
							></IconPickerModal>

							<Button icon={<BgColorsOutlined />} onClick={() => this.handleColorModalVisible(true)}>{t('app.entry.edit.btnPickColor')}</Button>
							<ColorPickerModal
								colorValue={!isUndefined(this.props.formRef.current) ? this.props.formRef.current.getFieldValue(["properties", "folderIconColor"]) : "#223b6f"}
								isVisible={this.state.isColorModalVisible}
								onCancel={() => this.handleColorModalVisible(false)}
								onSelect={this.handleColorChange}
							></ColorPickerModal>
						</Card>
					</Form.Item>
				</Col>
				<Col xs={24} sm={24} md={12} lg={8} xl={6}>
					<Form.Item label={t('app.entry.edit.lblSubfolderDisplayType')} key="subFolderDisplayType"
						name={["properties", "subFolderDisplayType"]}
					>
						<Radio.Group>
							<Radio.Button value="icons">
								<FolderOutlined />&nbsp;{t('app.entry.edit.lblSubfolderDisplayTypeIcons')}
							</Radio.Button>
							<Radio.Button value="table">
								<TableOutlined />&nbsp;{t('app.entry.edit.lblSubfolderDisplayTypeTable')}
							</Radio.Button>
						</Radio.Group>
					</Form.Item>
				</Col>
				<Col xs={24} sm={24} md={12} lg={8} xl={6}>
					<Form.Item label={t('app.entry.edit.lblChildrensDisplayType')} key="childrenDisplayType"
						name={["properties", "childrenDisplayType"]}
					>
						<Radio.Group>
							<Radio.Button value="table">
								<TableOutlined />&nbsp;{t('app.entry.edit.lblChildrensDisplayTypeTable')}
							</Radio.Button>
							<Radio.Button value="hierarchy">
								<ForkOutlined rotate={180} />&nbsp;{t('app.entry.edit.lblChildrensDisplayTypeHierarchy')}
							</Radio.Button>
						</Radio.Group>
					</Form.Item>
				</Col>
				<Col xs={24} sm={24} md={12} lg={8} xl={6}>
					<Form.Item label={t('app.entry.edit.lblChildrensLineageId')} key="childrenDisplayHierarchyDefId"
						name={["properties", "childrenDisplayHierarchyDefId"]}
					>
						<Select
							// style={{width: 300}}
							placeholder={t('app.entry.edit.lblLineageDDLHint')}
							allowClear={true}
						>
							{lineageOptions}
						</Select>
					</Form.Item>
				</Col>
			</Row>,
			<Row align="top" gutter={[{ xs: 6, sm: 6, md: 6, lg: 12 }, 20]} key="childAttrRow">
				<Col xs={24} sm={24} md={12} lg={8} xl={8}>
					<Form.Item label={t('app.entry.edit.lblDisplayFirstAttributes')} key="displayTechAttributesFirst"
						name={["properties", "displayTechAttributesFirst"]}
						valuePropName="checked"
					>
						<Switch checkedChildren={t('app.entry.edit.lblDisplayFirstTechAttributes')} unCheckedChildren={t('app.entry.edit.lblDisplayFirstUserAttributes')} />
					</Form.Item>
				</Col>
				<Col xs={24} sm={24} md={12} lg={8} xl={8}>
					<Form.Item label={t('app.entry.edit.lblChildrensTechAttributes')} key="childrenDisplayTechAttr"
						name={["properties", "childrenDisplayTechAttr"]}
					>
						<Checkbox.Group options={entryTechAttoptions} style={{ display: 'grid', justifyItems: 'start' }} />
					</Form.Item>
				</Col>
				<Col xs={24} sm={24} md={12} lg={8} xl={8}>
					<Form.Item key="childrenDisplayAttributes"
						shouldUpdate={(prevValues, curValues) => prevValues.properties.childObjectEntryTypes !== curValues.properties.childObjectEntryTypes}
					>
						{() => {
							let optionsAttributes = [];
							if (!isUndefined(rr.getData()) && !isEmptyValue(rr.getData()) && !isUndefined(this.props.formRef.current)) {
								optionsAttributes = getEntryTypeAttributesSelectOptions(rr.getData(), this.props.formRef.current.getFieldValue(["properties", "childObjectEntryTypes"]), false, false, true);
							}
							return (<Form.Item label={t('app.entry.edit.lblChildrensAttributes')} key="childrenDisplayAttributes"
								name={["properties", "childrenDisplayAttributes"]}
								dependencies={["properties", 'childObjectEntryTypes']}
							>
								<Select
									showSearch
									mode="multiple"
									// style={{width: 300}}
									disabled={this.state.showPopover}
									placeholder={t('app.entry.edit.lblChildrensAttributesHint')}
									optionFilterProp="value"
									filterOption={(input, option) =>
										option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									allowClear={true}
								>
									{optionsAttributes}
								</Select>
							</Form.Item>
							);
						}}
					</Form.Item>
					<Form.Item label={t('app.entry.edit.lblFolderRelatedWorkflow')} key="workflowForFolder"
						name={["properties", "workflowForFolder","onRunByUser"]}
					>
						<Select
						mode="multiple"
							placeholder={t('app.entry.edit.lblOnSaveEventHint')}
						>
							<Option value="" key="" > --- </Option>
							{workflowForFolder}
						</Select>
					</Form.Item>
				</Col>
			</Row>
		];
	}
};

export default withTranslation()(FolderProperties);

FolderProperties.propTypes = {
	//propertyData: folderPropShape.isRequired,
	//onPropertyChange: PropTypes.func.isRequired,
	formRef: PropTypes.any.isRequired
};