import React from "react";
import { isEmptyObject, isEmptyValue, isUndefined } from "../../utils/JsObjectHelper";
import { PageHeader, Tag, Row, Card } from "antd";
import EntryToolsPanel from "./view/EntryToolsPanel";
import ReactHtmlParser from 'react-html-parser';
import { entriesRequestResult, entryResultDetailShape, incomingRelationsRequestResult, outgoingRelationsRequestResult, entryTypesRequestResult } from "../../shapes/RequestResult";
import AttributesSection from '../../containers/detail/AttributesSection';
//import EntryChildTable from './view/EntryChildTable';
import EntryChildSection from './view/EntryChildSection';
import EntrySubFolders from './view/EntrySubFolders';
import EntryRelationsTable from './view/EntryRelationsTable';
import EntryTypeTag from '../controls/EntryTypeTag';
import { withTranslation } from 'react-i18next';


// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/plugins.pkgd.min.css';
import '../../froala_custom.css';

// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';
//import { getTextColorFromBackgroundHex } from "../../utils/ColorHelper";

class EntryView extends React.Component {

    componentDidMount() {
        this.props.onMount();
        if (isUndefined(localStorage.getItem('historyEntry'))) {
            let now = new Date();
            let arr = [];
            arr.push({ entryID: this.props.entryID, date: now })
            let str = JSON.stringify(arr)
            localStorage.setItem('historyEntry', str);
        }

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.entryID !== this.props.entryID) {
            let arr = JSON.parse(localStorage.getItem('historyEntry'))
            let now = new Date();
            if (arr.length === 30) {
                arr.pop()
            }
            if (arr.find((el) => el.entryID === this.props.entryID)) {
                let index = arr.findIndex((el) => el.entryID === this.props.entryID)
                arr.splice(index, 1)
            }
            arr.unshift({ entryID: this.props.entryID, date: now })
            let str = JSON.stringify(arr)
            localStorage.setItem("historyEntry", str);
        }
    }

    render() {
        const { t } = this.props;
        let showEntriAttributeContent = true;
        let entryTypeNameList = [];

        if (this.props.entryTypesRequestResult.getState().isDone()) {
            entryTypeNameList = this.props.entryTypesRequestResult.getData().map((item, key) => { return { type: item.type, name: item.name, color: item.properties.typeColor }; });
        }

        /** @type {RequestResult} */
        const rr = this.props.entryRequestResult;
        /** @type {RequestResult} */
        const rrchild = this.props.childrenRequestResult;

        if (isEmptyObject(rr) || rr.getState().isLoading() || isEmptyObject(rr.getData()))
            return <div>&nbsp;</div>;
        else if (rr.getData()) {
            /** @type {BaseEntryWithParent} */
            const entry = rr.getData();
            /** @type {Array<BaseEntry>|null} */
            let children = [];
            if (!isEmptyObject(rrchild) && rrchild.getState().isDone() && !isEmptyObject(rrchild.getData())) {
                children = rrchild.getData();
            }

            //Sub Folders a Childs se zobrazují pouze pro FOLDER a HOME
            let subFolders = null;
            let childs = null;
            let childsData = null;
            let relations = null;

            if (entry.type === "folder" || entry.type === "home") {
                showEntriAttributeContent = false;
                let defaultSubFoldersDisplayType = "icons";
                if (!isUndefined(entry.properties) && !isEmptyValue(entry.properties.subFolderDisplayType)) {
                    defaultSubFoldersDisplayType = entry.properties.subFolderDisplayType
                }
                //SUB FOLDERS
                subFolders = <EntrySubFolders
                    childsDataList={children}
                    defaultDisplayType={defaultSubFoldersDisplayType}
                    entryTypeNameList={entryTypeNameList}
                ></EntrySubFolders>;

                //CHILDS
                childsData = children.filter(fol => { return fol.type !== "folder" }).map(
                    (item, key) => {
                        let itemType = item.type;
                        if (entryTypeNameList.length > 0) {
                            itemType = entryTypeNameList.find(eT => eT.type === item.type).name;
                        }
                        //return {id: item.id, entry: item.name, entryType: itemType, key: key, created: item.created, createdByUserName: item.createdByUserName};
                        return { entry: item.name, entryType: itemType, key: key, ...item };
                    }
                );

                //Notify user that childs are still loading
                if (rrchild.getState().isLoading()) {
                    childs = <Card loading={true}></Card>
                }

                //Dont display child box if there are no childrens
                if (childsData.length > 0) {
                    childs = <EntryChildSection
                        childsDataList={childsData}
                        folderProperties={entry.properties}
                        entryTypesRequestResult={this.props.entryTypesRequestResult}></EntryChildSection>;
                }
            } else {
                relations = <EntryRelationsTable
                    entryRequestResult={this.props.entryRequestResult}
                    incomingRelationsRequestResult={this.props.incomingRelationsRequestResult}
                    outgoingRelationsRequestResult={this.props.outgoingRelationsRequestResult}
                    entryTypesRequestResult={this.props.entryTypesRequestResult}
                >
                </EntryRelationsTable>
            }

            let attSection = null;

            if (showEntriAttributeContent) {
                attSection = <AttributesSection
                    data={entry}
                    relationData={this.props.outgoingRelationsHolder}
                    isEditMode={false}
                    isLoadingRelations={this.props.outgoingRelationsRequestResult.getState().isLoading()}
                />
            }

            //Entry Type TAG
            let entryTypeTag = null;
            switch (entry.type) {
                case "home":
                    entryTypeTag = null;
                    break;
                case "folder":
                    entryTypeTag = <Tag color="#ffc069">{t('app.entry.view.entryTypeFolder')}</Tag>;
                    break;
                default:
                    entryTypeTag = <EntryTypeTag entryTypeName={entry.type} entryTypeNameList={entryTypeNameList}></EntryTypeTag>
                    /*if (entryTypeNameList.length > 0) {
                        let thisType = entryTypeNameList.find(t=>t.type===entry.type);
                        let tagTextColor = getTextColorFromBackgroundHex(thisType.color);
                        entryTypeTag = <Tag color={isEmptyValue(thisType.color) ? "blue" : thisType.color} style={{color:tagTextColor}}>{thisType.name}</Tag>;
                    } else {
                        entryTypeTag = <Tag color="blue">{entry.type}</Tag>;
                    }*/
                    break;
            }

            return <div>
                <PageHeader
                    title={entry.name}
                    tags={entryTypeTag}
                    className="entryPageHeader"
                    extra={[
                        <EntryToolsPanel key="tools-panel" entry={entry}></EntryToolsPanel>,
                    ]}
                >
                    <Row className="pgContent"/*  type="flex" */>
                        <div className="fr-box fr-basic fr-top">
                            {/* <div className="fr-wrapper"> */}
                            <div className="fr-element fr-view" style={{ padding: '0px' }}>
                                {ReactHtmlParser(entry.description)}
                            </div>
                            {/* </div>*/}
                        </div>
                    </Row>
                </PageHeader>

                {subFolders}

                <br />

                {childs}

                {attSection}

                <br />

                {relations}

            </div>;
        }
    }
}

export default withTranslation()(EntryView);

EntryView.propTypes = {
    entryRequestResult: entryResultDetailShape,
    childrenRequestResult: entriesRequestResult,
    incomingRelationsRequestResult: incomingRelationsRequestResult,
    outgoingRelationsRequestResult: outgoingRelationsRequestResult,
    entryTypesRequestResult: entryTypesRequestResult.isRequired
};

