import React from "react";

import { isEmptyObject, isEmptyValue, isUndefined } from "../../utils/JsObjectHelper";
import { Result, PageHeader, Spin, Select, Collapse, Descriptions, Badge, Button, List, message, Drawer, Divider, Card, Space, Input } from "antd";
import { DownloadOutlined, ToolOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import {
    entryResultDetailShape,
    entryTypesRequestResult,
    incomingRelationsRequestResult,
    outgoingRelationsRequestResult
} from "../../shapes/RequestResult";

import { fetchEntryLineage, fetchEntryRelIncomingLineage, fetchEntryRelOutgoingLineage } from '../../apicalls/fetchEntryLineage'

import { withTranslation } from 'react-i18next'
import PropTypes from "prop-types";
import cytoscape from 'cytoscape';
import klay from 'cytoscape-klay';
//import contextMenus from 'cytoscape-context-menus';

import { contextMenus } from '../controls/cytoscape-context-menus/cytoscape-context-menus'
import update from 'immutability-helper';

// import CSS for context menus
//import 'cytoscape-context-menus/cytoscape-context-menus.css';
import '../controls/cytoscape-context-menus/cytoscape-context-menus.css';
import { getTextColorFromBackgroundHex } from "../../utils/ColorHelper";
import { Helmet } from "react-helmet";
import ResponseStatusException from "../../exceptions/ResponseStatusException";
import { isMobile } from "../../utils/AdaptiveLayout";

const cloneDeep = require('lodash.clonedeep');

const fs = require("fs");

const { Option } = Select;
const { Panel } = Collapse;
const { Search } = Input;

const REL_TYPE_OUT = 1;
const REL_TYPE_IN = 2;
const REL_TYPE_BOTH = 3;

class Lineage extends React.Component {
    constructor(props) {
        super(props);
        this.onMount = this.onMount.bind(this);
        this.createGraph = this.createGraph.bind(this);
        this.createLayout = this.createLayout.bind(this);
        this.runLayout = this.runLayout.bind(this);
        this.drawChart = this.drawChart.bind(this);
        this.addNewEntry = this.addNewEntry.bind(this);
        this.onMenuItemSelected = this.onMenuItemSelected.bind(this);
        this.onEntryLoaded = this.onEntryLoaded.bind(this);
        this.onEntryRelIncomingLoaded = this.onEntryRelIncomingLoaded.bind(this);
        this.onEntryRelOutgoingLoaded = this.onEntryRelOutgoingLoaded.bind(this);
        this.loadEntry = this.loadEntry.bind(this);
        this.getColorsForTypes = this.getColorsForTypes.bind(this);
        this.onEdgeStyleSelected = this.onEdgeStyleSelected.bind(this);
        this.onEntryTypeSelected = this.onEntryTypeSelected.bind(this);
        this.onNodeSelected = this.onNodeSelected.bind(this);
        this.loadEntryWithoutExpand = this.loadEntryWithoutExpand.bind(this);
        this.calculateShortestPath = this.calculateShortestPath.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onLineageSettingChange = this.onLineageSettingChange.bind(this);
        this.clearHighlightedGraphElements = this.clearHighlightedGraphElements.bind(this);
        
        this.state = {
            rootLoaded: false,
            expandedEntries: [],
            inExpandedHistory: [],
            outExpandedHistory: [],
            parentDefinitions: [],
            entryDefinition: [],
            displayEntryTypes: [],
            hiddeRelationTypes: [],
            usedTypes: [],
            isLoadingEntry: false,
            loadingEntryId: null,
            loadingUseRelType: REL_TYPE_BOTH,
            loadingEntry: null,
            loadingEntryRelIncoming: null,
            loadingEntryRelOutgoing: null,
            loadingExpandFollows: true,
            selectedEdgeStyle: "edgeBazier",
            selectedNodeId: null,
            pathStartId: null,
            pathStopId: null,
            toolPanelVisible: false
        }
    }

    componentDidMount() {
        this.onMount(this.props.match.params.entryID);
        //this.createGraph();
    }

    onMount(entryId) {
        this.props.onMount(entryId);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.match.params.entryID !== prevProps.match.params.entryID) {
            this.onMount(this.props.match.params.entryID, true);
        }

        if (!this.state.rootLoaded &&
            this.props.entryRequestResult.getState().isDone() &&
            this.props.incomingRelationsRequestResult.getState().isDone() &&
            this.props.outgoingRelationsRequestResult.getState().isDone() &&
            this.props.entryTypesRequestResult.getState().isDone()
        ) {
            let entryTypeNameList = this.props.entryTypesRequestResult.getData()
                .filter(et => { return (et.systemType === "object"); })
                .map(et => et.type);

            this.setState(
                (prevState, props) => {
                    return update(prevState, {
                        rootLoaded: { $set: true },
                        expandedEntries: { $push: [this.props.entryRequestResult.getData().id] },
                        inExpandedHistory: { $push: [this.props.entryRequestResult.getData().id] },
                        outExpandedHistory: { $push: [this.props.entryRequestResult.getData().id] },
                        displayEntryTypes : { $set: entryTypeNameList },
                    });
                }
                , () => {
                    this.addNewEntry(this.props.entryRequestResult.getData(),
                        this.props.incomingRelationsRequestResult.getData(),
                        this.props.outgoingRelationsRequestResult.getData(),
                        null)
                });
        }

        if (this.state.isLoadingEntry &&
            this.state.loadingEntryId !== null &&
            this.state.loadingEntry !== null &&
            this.state.loadingEntryRelIncoming !== null &&
            this.state.loadingEntryRelOutgoing !== null
        ) {
            this.setState(
                (prevState, props) => {
                    if (prevState.loadingExpandFollows) {
                        return update(prevState, {
                            isLoadingEntry: { $set: false },
                            expandedEntries: { $push: [prevState.loadingEntryId] }
                        });
                    } else {
                        return update(prevState, {
                            isLoadingEntry: { $set: false }
                        });
                    }
                }
                , () => {
                    this.addNewEntry(this.state.loadingEntry,
                        this.state.loadingEntryRelIncoming,
                        this.state.loadingEntryRelOutgoing,
                        this.state.loadingEntryId)
                });
        }
    }

    onMenuItemSelected(menuItemName, evnt) {
        switch (menuItemName) {
            case 'IN':
                this.loadEntry(evnt.target.data('id'), REL_TYPE_IN);
                break;
            case 'OUT':
                this.loadEntry(evnt.target.data('id'), REL_TYPE_OUT);
                break;
            case 'BOTH':
                this.loadEntry(evnt.target.data('id'), REL_TYPE_BOTH);
                break;
            case 'CLOSE':
                this.closeEntry(evnt.target.data('id'));
                break;
            case 'OPEN':
                window.open("/entry/" + evnt.target.data('id'), "_blank");
                break;
            case 'HIGHLIGHT':
                this.clearHighlightedGraphElements();
                evnt.target.addClass("highlightedFriends");
                evnt.target.outgoers().addClass("highlightedFriends");
                evnt.target.incomers().addClass("highlightedFriends");

                /*setTimeout(function(){
                    that.cy.nodes().removeClass("highlightedFriends");
                    that.cy.edges().removeClass("highlightedFriends");
                    }, 3000);*/
                break;
            case 'FIT':
                this.cy.animate({
                    fit: { padding: 10 }
                  }, {
                    duration: 500
                  });
                break;
            case 'PATH_START':
                this.onPathStartSelected(evnt.target.data('id'));
                break;
            case 'PATH_END':
                this.onPathEndSelected(evnt.target.data('id'));
                break;
            default:
                break;
        }
    };

    onEdgeStyleSelected(evnt) {
        this.cy.edges().removeClass(this.state.selectedEdgeStyle);
        this.cy.edges().addClass(evnt);
        this.setState({ selectedEdgeStyle : evnt })
    };

    onEntryTypeSelected(selectedEntryTypes) {
        this.setState({ displayEntryTypes: selectedEntryTypes},
            () => {
                this.cy.nodes().remove();
                this.cy.edges().remove();
                this.drawChart(null, REL_TYPE_BOTH, true);//this.props.match.params.entryID);
            });
    };

    onRelationTypeSelected = (selectedRelTypes) => {
        this.setState({ hiddeRelationTypes: selectedRelTypes},
            () => {
                this.cy.nodes().remove();
                this.cy.edges().remove();
                this.drawChart(null, REL_TYPE_BOTH, true);
            });
    };

    onLineageSettingChange(lineageSettingName) {
        let currentSetting = this.props.lineageSettings.find(s=>s.name === lineageSettingName);
        let hierarchy = [];
        let entryTypes = [];

        if (!isEmptyValue(currentSetting)) {
            entryTypes = currentSetting.value.entryTypes;
            hierarchy = currentSetting.value.hierarchy;
        } else {
            entryTypes = this.props.entryTypesRequestResult.getData()
                .filter(et => { return (et.systemType === "object"); })
                .map(et => et.type);
        }

        this.setState({ 
            displayEntryTypes : entryTypes,
            parentDefinitions : hierarchy
         }, 
         () => {
            this.cy.nodes().remove();
            this.cy.edges().remove();
            this.drawChart(null, REL_TYPE_BOTH, true);
        });
    };

    onEntryLoaded(entry, error) {
        if(this.checkLoadedData(error) && this.state.isLoadingEntry) {
            this.setState({
                loadingEntry : entry
            });
        }
    };

    onEntryRelIncomingLoaded(entryRel, error) {
        if(this.checkLoadedData(error) && this.state.isLoadingEntry) {
            this.setState({
                loadingEntryRelIncoming : entryRel
            });
        }
    };

    onEntryRelOutgoingLoaded(entryRel, error) {
        if(this.checkLoadedData(error) && this.state.isLoadingEntry) {
            this.setState({
                loadingEntryRelOutgoing : entryRel
            });
        }
    };

    onNodeSelected(nodeId) {
        this.setState({ selectedNodeId: nodeId },
            () => {
                this.loadEntryWithoutExpand()
            });
    };

    onPathStartSelected(nodeId) {
        if (isEmptyValue(this.state.pathStopId) && isEmptyValue(this.state.pathStartId)) {
            this.clearHighlightedGraphElements(false);
        }

        if (!isEmptyValue(this.state.pathStartId)) {
            let startNode = this.cy.$id(this.state.pathStartId);
            if (!isEmptyValue(startNode))
                startNode.removeClass('pathNode');
        }

        let startNode = this.cy.$id(nodeId);
            if (!isEmptyValue(startNode))
                startNode.addClass('pathNode');

        this.setState({ pathStartId: nodeId }, () => { this.calculateShortestPath(); });
    };

    onPathEndSelected(nodeId) {
        if (isEmptyValue(this.state.pathStopId) && isEmptyValue(this.state.pathStartId)) {
            this.clearHighlightedGraphElements(false);
        }

        if (!isEmptyValue(this.state.pathStopId)) {
            let endNode = this.cy.$id(this.state.pathStopId);
            if (!isEmptyValue(endNode))
                endNode.removeClass('pathNode');
        }

        let endNode = this.cy.$id(nodeId);
            if (!isEmptyValue(endNode))
                endNode.addClass('pathNode');

        this.setState({ pathStopId: nodeId }, () => { this.calculateShortestPath(); });
    };

    onDownloadClick() {
        let expPng = this.cy.png( { output: 'base64', full: true } );
        var a = document.createElement("a"); //Create <a>
        a.href = "data:image/png;base64," + expPng; //Image Base64 Goes here
        a.download = "lineage.png"; //File name Here
        a.click(); //Downloaded file
    };

    onDownloadJsonClick = () => {
        let eDefJson = this.prepareNodes4Export();
        var a = document.createElement("a"); //Create <a>
        a.href = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(eDefJson));
        a.download = "lineage.json"; //File name Here
        a.click(); //Downloaded file
    };

    onDownloadCSVClick = () => {
        let eDefCsv = "entry_id;entry_name;entry_type;outgoing_relations\r\n";
        let nodeData = this.prepareNodes4Export();
        nodeData.forEach(nDat=>{
            eDefCsv += nDat.id + ";" + nDat.name + ";" + nDat.type + ";" + nDat.outRel.map(r=>r.targetId).join(",") + "\r\n"
        });

        var a = document.createElement("a"); //Create <a>
        a.href = "data:text/csv;charset=utf-8," + encodeURIComponent(eDefCsv);
        a.download = "lineage.csv"; //File name Here
        a.click(); //Downloaded file
    };

    prepareNodes4Export = () => {
        let preparedEntries = [];

        preparedEntries = this.cy.nodes().map(n => { 
            let outRelArr = this.cy.edges().filter(e => e.data("source") === n.data("id")).map(edg=>{
                return { relName: edg.data("label"), relType: edg.data("relType"), sourceId: edg.data("source"), targetId: edg.data("target") };
            });
            return { id: n.data("id"), name: n.data("label"), type: n.data("type"), outRel: outRelArr }; 
        });

        console.log(preparedEntries);

        return preparedEntries;
    };

    checkLoadedData = (error) => {
        const { t } = this.props;

        if (!isUndefined(error)) {
            //check if error is 403 - access rights
            if(error instanceof ResponseStatusException &&
                error.response.status === 403) {
                message.error(t('app.lineage.dataLoadingError'));
            }
            //reset data loading
            this.setState({ 
                isLoadingEntry : false,
                loadingEntryId : null,
                loadingEntry: null,
                loadingEntryRelIncoming: null,
                loadingEntryRelOutgoing: null
            });
            return false;
        } else {
            return true;
        }
    };

    calculateShortestPath() {
        if (!isEmptyValue(this.state.pathStartId) && !isEmptyValue(this.state.pathStopId)) {
            this.clearHighlightedGraphElements(false);

            let startNode = this.cy.$id(this.state.pathStartId);
            let endNode = this.cy.$id(this.state.pathStopId);

            var aStar = this.cy.elements().aStar({
                root: startNode,
                goal: endNode,
            });

            if (aStar.found) {
                this.cy.elements().not( aStar.path ).removeClass('pathNode');
                aStar.path.addClass('pathNode');

                /*setTimeout(function(){
                    that.cy.elements().removeClass("pathNode");
                    that.setState({ pathStartId :null, pathStopId: null });
                    }, 5000);*/
            }
        }
    };


    createGraph() {
        let graphPlaceholder = document.getElementById('lineageCytoscapePlaceholder');
        cytoscape.use(klay);
        if (typeof cytoscape('core', 'contextMenus') !== 'function') {
            //cytoscape.use(contextMenus);
            cytoscape('core', 'contextMenus', contextMenus);
        }

        let cytoscapeOptions = this.props.getCytoscapeOptions(graphPlaceholder);
        let typeColorOptions = this.getColorsForTypes();
        typeColorOptions.forEach(type => {
            cytoscapeOptions.style.push(type);
        });

        this.cy = cytoscape(cytoscapeOptions);

        this.cy.contextMenus(this.props.getMenuOptions(this.onMenuItemSelected));

        this.cy.on("tap", (e) => { 
            if(e.target === this.cy){
                this.setState({ selectedNodeId: null }); 
                this.clearHighlightedGraphElements();
            }
        })

        this.cy.maxZoom(1.5);

        this.runLayout();

        this.cy.center();
    };

    createLayout() {
        this.layout = this.cy.layout(this.props.getLayoutOptions());
    };

    runLayout() {
        this.createLayout();

        this.layout.run();
    };

    drawChart(expandParentID, useRelType = REL_TYPE_OUT, forceExpandByHistory = false) {
        var orderedTypes = [];

        //poznamenam historii rozbaleni pro pripadnou rekonstrukci lineage
        if (expandParentID !== null) {
            if (useRelType === REL_TYPE_OUT || useRelType === REL_TYPE_BOTH) {
                if (this.state.outExpandedHistory.indexOf(expandParentID) < 0) {
                    this.setState(
                        (prevState, props) => {
                            return update(prevState, {
                                outExpandedHistory: { $push: [expandParentID] }
                            });
                        });
                }
            } 
            if (useRelType === REL_TYPE_IN || useRelType === REL_TYPE_BOTH) {
                if (this.state.inExpandedHistory.indexOf(expandParentID) < 0) {
                    this.setState(
                        (prevState, props) => {
                            return update(prevState, {
                                inExpandedHistory: { $push: [expandParentID] }
                            });
                        });
                }
            }
        }

        //serad definice aby bylo jasne poradi u hierarchii
        this.state.parentDefinitions.forEach(type => {
            if (orderedTypes.indexOf(type.parent) === -1) {
                if (orderedTypes.indexOf(type.child) === -1) {
                    orderedTypes.push(type.parent);
                } else {
                    orderedTypes.splice(orderedTypes.indexOf(type.child), 0, type.parent);
                }

            }
            if (orderedTypes.indexOf(type.child) === -1) {
                orderedTypes.push(type.child);
            }
        });

        var foundNodes = [];
        var foundEdges = [];
        var leafNodes = [];
        
        //vytvor vsechny rozbalene nody
        this.state.expandedEntries.forEach(id => {
            let elem = this.state.entryDefinition.find(e => { return e.id === id });
            if (elem != null && !(elem.id in foundNodes) && this.state.displayEntryTypes.indexOf(elem.type) > -1) {
                foundNodes[elem.id] = { id: elem.id, name: elem.name, type: elem.type, parent: null };

                if (expandParentID === null || expandParentID === id) {
                    if ((!forceExpandByHistory && (useRelType === REL_TYPE_OUT || useRelType === REL_TYPE_BOTH)) || (forceExpandByHistory && this.state.outExpandedHistory.indexOf(id) > -1)) {
                        elem.outgoingRelations.forEach(rel => {
                            if (foundEdges.find(
                                edg => { return edg.s === elem.id && edg.t === rel.target.id && edg.name === rel.name; }
                            ) == null
                            ) {
                                let thisType = elem.type;
                                let thisRelName = rel.name;
                                //pridat pouze childy ktere jsou v povolenych zobrazenych
                                if (this.state.displayEntryTypes.indexOf(rel.target.type) > -1
                                    //a pouze ktere nemaji schovane vazby
                                    && this.state.hiddeRelationTypes.indexOf(thisType+"|#|"+thisRelName) < 0
                                ) {
                                    foundEdges.push({ s: elem.id, st: thisType, t: rel.target.id, tt: rel.target.type, name: thisRelName, type: thisRelName });
                                    leafNodes.push(rel.target.id);
                                    //pokud se jeste nebudou projizdet jako rozbalene, tak pridej cile do foundNodes
                                    if (this.state.expandedEntries.indexOf(rel.target.id) < 0)
                                        foundNodes[rel.target.id] = { id: rel.target.id, name: rel.target.name, type: rel.target.type, parent: null };
                                }
                            }
                        });
                    } 
                    if ((!forceExpandByHistory && (useRelType === REL_TYPE_IN || useRelType === REL_TYPE_BOTH)) || (forceExpandByHistory && this.state.inExpandedHistory.indexOf(id) > -1)) {
                        elem.incomingRelations.forEach(rel => {
                            if (foundEdges.find(
                                edg => { return edg.s === rel.source.id && edg.t === elem.id && edg.name === rel.name; }
                            ) == null
                            ) {
                                let thisType = rel.source.type;
                                let thisRelName = rel.name;
                                //pridat pouze childy ktere jsou v povolenych zobrazenych
                                if (this.state.displayEntryTypes.indexOf(thisType) > -1
                                    //a pouze ktere nemaji schovane vazby
                                    && this.state.hiddeRelationTypes.indexOf(thisType+"|#|"+thisRelName) < 0
                                ) {
                                    foundEdges.push({ s: rel.source.id, st: thisType, t: elem.id, tt: elem.type, name: thisRelName, type: thisRelName });
                                    leafNodes.push(rel.source.id);
                                    //pokud se jeste nebudou projizdet jako rozbalene, tak pridej cile do foundNodes
                                    if (this.state.expandedEntries.indexOf(rel.source.id) < 0)
                                        foundNodes[rel.source.id] = { id: rel.source.id, name: rel.source.name, type: thisType, parent: null };
                                }
                            }
                        });
                    }
                }
            }
        });

        //doplnit nody na které pouze vede vazba, ale nejsou ještě rozbaleny
        leafNodes.forEach(id => {
            let elem = this.state.entryDefinition.find(e => { return e.id === id });
            if (elem != null && !(elem.id in foundNodes)) {
                foundNodes[elem.id] = { id: elem.id, name: elem.name, type: elem.type, parent: null };
            }
        });

        //kontrola, jestli neodpovídá definici pro parenty -> udělat místo vazby parenta.
        foundEdges.forEach(edg => {
            if (edg.s in foundNodes && edg.t in foundNodes) {
                if (this.state.parentDefinitions.find(def => { return def.parent === edg.st && def.child === edg.tt && def.relation === edg.name; }) != null) {
                    if (isEmptyValue(foundNodes[edg.t].parent))
                        foundNodes[edg.t].parent = edg.s;
                    // else
                    //     edg.className = "hierarchyEdge";
                    //edg.doWriteRelation = true;
                } /*else {
                    edg.doWriteRelation = true;
                    //cy.add(createEdgeEntry(edg));
                }*/
            }
        });

        //otestuj, jestli neexistuje nahodou nerozbalena parent vazba -> doplnit nerozbalene nody do hierarchie
        leafNodes.forEach(id => {
            let elem = this.state.entryDefinition.find(e => { return e.id === id });
            if (elem != null && elem.outgoingRelations.length > 0) {
                var parDefs = this.state.parentDefinitions.filter(e => { return e.parent === elem.type });
                parDefs.forEach(pD => {
                    elem.outgoingRelations
                        .filter(oR => { return oR.name === pD.relation; })
                        .forEach(oRMatch => {
                            if (leafNodes.indexOf(oRMatch.target.id) > -1) {
                                foundNodes[oRMatch.target.id].parent = elem.id;
                            }
                        });
                });
            }
        });

        // Create items array
        var items = Object.keys(foundNodes).map(function (key) {
            return foundNodes[key];
        });

        // Sort the array based on the second element
        var sortedVals = items.sort(function (first, second) {
            if (first.type !== second.type)
                if (orderedTypes.indexOf(first.type) > -1 && orderedTypes.indexOf(second.type) > -1)
                    return orderedTypes.indexOf(second.type) < orderedTypes.indexOf(first.type)
                else
                    return first.type.localeCompare(second.type);
            else
                return first.name.localeCompare(second.name);
        });

        if (!this.cy) {
            this.createGraph();
        }

        //pridani novych nodu
        sortedVals.forEach(n => {
            var matchNodes = this.cy.$id(n.id);

            if (matchNodes.length === 0) {
                let className = ( n.id === this.props.match.params.entryID ? 'mainNode' : null);
                let newElem = this.cy.add(this.props.createNodeElement(n, className));
                newElem.on("tap", (e)=>{
                    this.onNodeSelected(e.target.data('id'));
                });
            }
        });

        //projet znovu a kontrolovat parenty
        sortedVals.forEach(n => {
            var matchNodes = this.cy.$id(n.id);

            if (matchNodes.length > 0) {
                //kontrola, jestli nemusi byt doplnen parent
                if (matchNodes[0].parent().length === 0 && n.parent != null) {
                    //console.log(n);
                    matchNodes[0].move({ parent: n.parent });
                }
            }
        });

        //pridani lajn
        foundEdges.forEach(edg => {
            edg.name = this.getRelationName(edg.st, edg.type);
            if (//edg.doWriteRelation &&
                this.cy.$id(`${edg.s}#${edg.t}`).length === 0) //#${edg.type}
            { //.elements("edge[id = '" + edg.s + '#' + edg.t + "']")
                let newEdge = this.cy.add(this.props.createEdgeElement(edg));
                if (!isEmptyValue(edg.className)) {
                    newEdge.addClass(edg.className);
                }
            } else {
                if (this.cy.$id(`${edg.s}#${edg.t}`).data("label").indexOf(`(${edg.name})`) === -1 && 
                    this.cy.$id(`${edg.s}#${edg.t}`).data("label") !== edg.name) {
                    if (this.cy.$id(`${edg.s}#${edg.t}`).data("label").indexOf('), (') > -1)
                        this.cy.$id(`${edg.s}#${edg.t}`).data("label", `${this.cy.$id(`${edg.s}#${edg.t}`).data("label")}, (${edg.name})`);
                    else
                        this.cy.$id(`${edg.s}#${edg.t}`).data("label", `(${this.cy.$id(`${edg.s}#${edg.t}`).data("label")}), (${edg.name})`);
                }
            }
            /*else {
                if (!isEmptyValue(edg.className)) {
                    this.cy.$id(edg.s + '#' + edg.t).removeClass(edg.className).addClass(edg.className);
                }
            }*/
        });

        //Mark last selected node
        this.cy.nodes().forEach(ele => {
            if (ele.data('id') === expandParentID) {
                ele.addClass("lastSelectedNode");
            } else {
                ele.removeClass("lastSelectedNode");
            }
        });

        //change global line type
        this.cy.edges().addClass(this.state.selectedEdgeStyle);

        //change style for parent-child lines
        this.cy.edges().removeClass("hierarchyEdge");
        this.cy.edges().forEach(edg => {
            if (this.state.parentDefinitions.find(def => { return def.parent === edg.data('sourceType') && def.child === edg.data('targetType') && def.relation === edg.data('relType'); }) != null) {
                edg.addClass("hierarchyEdge");
            }
        });

        //save used types for usi in legend
        let usedTypes = [...new Set(this.cy.nodes().map(e=>e.data('type')))];
        this.setState( {usedTypes : usedTypes} );
        
        this.runLayout();
    };

    addNewEntry(entry, inRel, outRel, expandParentID) {
        if( entry !== null && inRel !== null && outRel !== null ) {
            let combinedEntry = entry;
            let doExpand = (this.state.loadingExpandFollows ? true : false); //copy
            combinedEntry.incomingRelations = inRel;
            combinedEntry.outgoingRelations = outRel;
            this.setState(
                (prevState, props) => {
                    return update(prevState, {
                        loadingEntryId: { $set: null },
                        loadingEntry: { $set: null },
                        loadingEntryRelIncoming: { $set: null },
                        loadingEntryRelOutgoing: { $set: null },
                        entryDefinition: { $push: [combinedEntry] },
                        loadingExpandFollows: { $set: true },
                    });
                }
                , () => {
                    if (doExpand) {
                        this.drawChart(expandParentID, this.state.loadingUseRelType);
                    }
                });
        }
    };

    loadEntry(id, useRelType) {
        //if (this.state.expandedEntries.indexOf(id) < 0) {
        if(isEmptyValue(this.state.entryDefinition.find(e => e.id === id))) {
            this.setState({ 
                isLoadingEntry : true,
                loadingEntryId : id, 
                loadingUseRelType : useRelType
            });
            fetchEntryLineage(id, this.onEntryLoaded);
            fetchEntryRelIncomingLineage(id, this.onEntryRelIncomingLoaded);
            fetchEntryRelOutgoingLineage(id, this.onEntryRelOutgoingLoaded);
        } else {
            if (this.state.expandedEntries.indexOf(id) < 0) {
                this.setState(
                    (prevState, props) => {
                        return update(prevState, {
                            expandedEntries: { $push: [id] }
                        });
                    }
                    , () => {
                        this.drawChart(id, useRelType);
                    });
            } else {
                this.drawChart(id, useRelType);
            }
        }
    };

    closeEntry = (id) => {
        if (this.state.expandedEntries.indexOf(id) > -1) {
            let expEntries = cloneDeep(this.state.expandedEntries);
            let expIn = cloneDeep(this.state.inExpandedHistory);
            let expOut = cloneDeep(this.state.outExpandedHistory);

            expEntries = expEntries.filter((el) => el !== id);
            expIn = expIn.filter((el) => el !== id);
            expOut = expOut.filter((el) => el !== id);

            this.setState({ expandedEntries: expEntries, inExpandedHistory: expIn, outExpandedHistory: expOut }
                , () => {
                    this.cy.nodes().remove();
                    this.cy.edges().remove();
                    this.drawChart(null, REL_TYPE_BOTH, true);
                });
        }
    };

    loadEntryWithoutExpand() {
        if(isEmptyValue(this.state.entryDefinition.find(e => e.id === this.state.selectedNodeId))) {
            this.setState({ 
                isLoadingEntry : true,
                loadingEntryId : this.state.selectedNodeId, 
                loadingExpandFollows : false
            });
            fetchEntryLineage(this.state.selectedNodeId, this.onEntryLoaded);
            fetchEntryRelIncomingLineage(this.state.selectedNodeId, this.onEntryRelIncomingLoaded);
            fetchEntryRelOutgoingLineage(this.state.selectedNodeId, this.onEntryRelOutgoingLoaded);
        }
    };

    getColorsForTypes() {
        let typeColors = [];
        if(this.props.entryTypesRequestResult.getState().isDone()) {
            let entryTypeNameList = this.props.entryTypesRequestResult.getData().filter(et => { return (et.systemType === "object"); });

            entryTypeNameList.forEach(type => {
                let textColor = "#000000";
                
                if (type.properties.typeColor && type.properties.typeColor.indexOf('#') === 0) {
                    textColor = getTextColorFromBackgroundHex(type.properties.typeColor);
                }

                typeColors.push({
                    selector: `node.${type.type}`,
                    style: {
                        'background-color': type.properties.typeColor,
                        'color': textColor
                    }
                });
                //parent style must be revriten due to white background
                typeColors.push({
                    selector: `node:parent.${type.type}`,
                    style: {
                        'color': "#000000",
                        'text-outline-width' : '1px',
                        'text-outline-color' : '#ffffff',
                    }
                });
            });
        }

        return typeColors;
    };

    highlightType(type) {
        if (!isEmptyValue(type)) {
            this.clearHighlightedGraphElements();
            let typeNodes = this.cy.filter('node[type = "' + type + '"]');
            typeNodes.addClass("highlightedFriends");
            /*setTimeout(function(){
                typeNodes.removeClass("highlightedFriends");
                }, 1500);*/
        }
    };

    getRelationName(sourceType, attType) {
        let entryDef = this.props.entryTypesRequestResult.getData().find(eT => eT.type === sourceType);
        if (!isEmptyObject(entryDef) && !isUndefined(entryDef.properties.attributes)) {
            let attDef = entryDef.properties.attributes.find(att => att.techName === attType);
            return isEmptyObject(attDef.name) ? attType : attDef.name;
        }
        return attType;
    };

    getEntryTypeName = (entryType) => {
        if (this.props.entryTypesRequestResult.getState().isDone()) {
            let entryDef = this.props.entryTypesRequestResult.getData().find(eT => eT.type === entryType);
            if (!isUndefined(entryDef)) {
                return entryDef.name;
            }
        }
        return entryType;
    };

    clearHighlightedGraphElements = (clearStartEndPathData = true) => {
        this.cy.elements().removeClass("pathNode");
        this.cy.nodes().removeClass("highlightedSearched");
        this.cy.nodes().removeClass("highlightedFriends");
        this.cy.edges().removeClass("highlightedFriends");
        if (clearStartEndPathData)
            this.setState({ pathStartId :null, pathStopId: null });
    };

    showToolsDrawer = () => {
        this.setState({toolPanelVisible:true});
    };
    onToolsClose = () => {
        this.setState({toolPanelVisible:false});
    };

    onSearchEntries = (srchTxt) => {
        this.clearHighlightedGraphElements();
        if (!isEmptyValue(srchTxt)) {
            let typeNodes = this.cy.filter("node[label @*= '" + srchTxt + "']");
            typeNodes.addClass("highlightedSearched");
            this.cy.animate({
                    fit: { padding: 10 }
                }, {
                    duration: 500
                });
            this.setState({ toolPanelVisible: false});
        }
    };

    getHiddenRelationOptions = () => {
        let optionsArr = [];

        this.state.entryDefinition.forEach((e) => {
            e.incomingRelations.forEach((eIn) => {
                if (isUndefined(optionsArr.find(oA => oA.typeName === eIn.source.type && oA.relName === eIn.name))) {
                    optionsArr.push({typeName: eIn.source.type, relName: eIn.name});
                }
            });

            e.outgoingRelations.forEach((eIn) => {
                if (isUndefined(optionsArr.find(oA => oA.typeName === e.type && oA.relName === eIn.name))) {
                    optionsArr.push({typeName: e.type, relName: eIn.name});
                }
            });
        });

        return optionsArr.map(o => 
                <Option value={o.typeName+'|#|'+o.relName} key={o.typeName+'|#|'+o.relName}>
                    {this.getEntryTypeName(o.typeName)+' ('+this.getRelationName(o.typeName, o.relName)+')'}
                </Option>
            );
    };

    render() {
        const { t } = this.props;

//console.log(this.state.entryDefinition);

        /** @type {RequestResult} */
        const rr = this.props.entryRequestResult;

        let entryTypeNameList = [];

        if (this.props.entryTypesRequestResult.getState().isDone()) {
            entryTypeNameList = this.props.entryTypesRequestResult.getData()
                .filter(et => { return (et.systemType === "object"); })
                .map((item, key) => { return { type: item.type, name: item.name, color: item.properties.typeColor }; });
        } else {
            return <div></div>;
        }

        if (isEmptyObject(rr) || rr.getState().isLoading())
            return <div>{t('app.entry.header.msgLoading')}</div>;
        else if (rr.getState().isError() && rr.getState().getErrorCode() === 403) {
            return <Result
                status="403"
                title="403"
                subTitle={t('app.entry.header.msgUnauthorized')}
                extra={<Link to={`/`} type="primary">{t('app.entry.header.msgBackToLandingPage')}</Link>}
            />
        }
        else if (isEmptyObject(rr.getData()))
            return <Result
                status="404"
                title={t('app.entry.header.msgEntryNonFound')}
                subTitle={t('app.entry.header.msgEntryNonFoundHint')}
                extra={<Link to={`/`} type="primary">{t('app.entry.header.msgBackToLandingPage')}</Link>}
            />;
        else if (!isEmptyValue(rr.getData().deleted)) {
            return <Result
                status="warning"
                title={t('app.entry.header.msgEntryDeleted')}
                subTitle={rr.getData().name + " - " + t('app.entry.header.msgEntryDeleteDate') + ": " + t('datetime', { date: new Date(rr.getData().deleted) })}
                extra={<Link to={`/`} type="primary">{t('app.entry.header.msgBackToLandingPage')}</Link>}
            />;
        }
        else {

            let etOptions = entryTypeNameList.sort((a,b) => a.name.localeCompare(b.name)).map(et => <Option value={et.type} key={et.type}>{et.name}</Option>);
            //let initOptions = entryTypeNameList.map(et => et.type);//[rr.getData().type];//entryTypeNameList.map(et => et.type);

            let relTypeOptions = this.getHiddenRelationOptions();

            let isLoading = this.state.isLoadingEntry;
            //console.log(initOptions);
            let infoPanelOpened = !isEmptyValue(this.state.selectedNodeId);
            let infoPanelContent = null;

            if (infoPanelOpened) {
                let selectedNode = this.state.entryDefinition.find(e => e.id === this.state.selectedNodeId);
                if (!isEmptyValue(selectedNode)) {
                    infoPanelContent = <Descriptions size="small" column={1} style={{width: '250px'}}>
                        <Descriptions.Item label={t('app.lineage.lineageBoxName')}>
                            <a href={"/entry/" + selectedNode.id} target="_blank" rel="noopener noreferrer">{selectedNode.name}</a>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('app.lineage.lineageBoxType')}>
                            {entryTypeNameList.find(eT=>eT.type === selectedNode.type).name}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('app.lineage.lineageBoxFolder')}>
                            <a href={"/entry/" + selectedNode.parent.id} target="_blank" rel="noopener noreferrer">{selectedNode.parent.name}</a>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('app.lineage.lineageBoxIn')}>
                            <Badge status={this.state.inExpandedHistory.indexOf(this.state.selectedNodeId)<0?"error":"success"} text={selectedNode.incomingRelations.length}/>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('app.lineage.lineageBoxOut')}>
                            <Badge status={this.state.outExpandedHistory.indexOf(this.state.selectedNodeId)<0?"error":"success"} text={selectedNode.outgoingRelations.length}/>
                        </Descriptions.Item>
                    </Descriptions>;
                } else {
                    infoPanelContent = <div style={{textAlign: 'center'}}>
                        {t('app.lineage.lineageBoxLoading')}<br/>
                        {/* <Button onClick={this.loadEntryWithoutExpand}>Or load without expanding</Button> */}
                    </div>;
                }
            }

            let lineageSettings = this.props.lineageSettings
                .filter(lS => lS.value.isDisplayed)
                .map(lS => <Option value={lS.name} key={lS.name}>{lS.value.lineageName}</Option>);

            let legendData = [
                {
                    style: { borderColor: 'black', width:'40px', height:'20px', borderStyle: 'solid', borderWidth: '3px'},
                    title: t('app.lineage.lineageLegendMain'),
                    entryType: null
                },
                {
                    style: { borderColor: 'black', width:'40px', height:'20px', borderStyle: 'dashed', borderWidth: '3px'},
                    title: t('app.lineage.lineageLegendLast'),
                    entryType: null
                },
                {
                    style: { borderColor: '#f70000', width:'40px', height:'20px', borderStyle: 'solid', borderWidth: '4px'},
                    title: t('app.lineage.lineageLegendFriends'),
                    entryType: null
                },
                {
                    style: { borderColor: '#f70000', width:'40px', height:'20px', borderStyle: 'dashed', borderWidth: '4px'},
                    title: t('app.lineage.lineageLegendPath'),
                    entryType: null
                }
            ];

            this.state.usedTypes.forEach((uT) => {
                let type = entryTypeNameList.find(eT => eT.type === uT);
                legendData.push({
                    style: { backgroundColor: (!isEmptyValue(type.color) ? type.color : '#A3ADFF'), borderColor: 'black', width:'40px', height:'20px', borderStyle: 'solid', borderWidth: '1px'},
                    title: type.name,
                    entryType: uT
                });
            });

            return <div style={{ height: '100%' }}>
                <Helmet>
                    <title>Aphinit Lineage - {rr.getData().name}</title>
                </Helmet>
                <PageHeader
                    style={{
                        borderBottom: '1px solid rgb(235, 237, 240)',
                    }}
                    onBack={() => this.props.history.push(`/entry/${rr.getData().id}`)}
                    title={rr.getData().name}
                    //tags={/*<Tag color="blue">Running</Tag>*/}
                    subTitle={t('app.lineage.lineageTitle')}
                    extra={[
                        <Button type="primary" icon={<ToolOutlined />} title={t('app.lineage.lineageBtnToolPanel')} onClick={this.showToolsDrawer} key="btnConfiguration">{t('app.lineage.lineageBtnToolPanel')}</Button>,
                    ]}
                >
                </PageHeader>
                {/* <Divider /> */}
                <Spin spinning={isLoading} size="large"> 
                    <div id="lineageCytoscapePlaceholder" className="lineagePlaceholder">
                        {/* PLACEHOLDER FOR LINEAGE GRAPH */}
                    </div>
                </Spin>
                <Collapse 
                    activeKey={(infoPanelOpened ? "info" : "")}
                    style={{position: 'fixed', top: '205px'}}
                    expandIconPosition="right"
                    onChange={()=>{this.setState({ selectedNodeId : null })}}
                    >
                        <Panel header={t('app.lineage.lineageBoxTitle')} key="info">
                            {infoPanelContent}
                        </Panel>
                </Collapse>
                <Collapse
                    expandIconPosition="right"
                    defaultActiveKey={["legend"]}
                    // style={{position: 'fixed', right: '80px', bottom: '110px'}}
                    className={"lineageLegend"}
                    >
                        <Panel header={t('app.lineage.lineageLegendTitle')} key="legend">
                            <div>
                            <List 
                                // itemLayout="horizontal"
                                dataSource={legendData}
                                size="small"
                                renderItem={item => (
                                    <List.Item>
                                        <List.Item.Meta
                                            className="lineageLegendItem"
                                            avatar={<div style={item.style}>&nbsp;</div>}
                                            description={<Button type="link" size="small" onClick={()=>{this.highlightType(item.entryType)}}>{item.title}</Button>}
                                    />
                                    </List.Item>
                                )}
                            >
                            </List>
                            </div>
                        </Panel>
                </Collapse>
                <Drawer width={isMobile()?"100%":"30%"} placement="right" 
                    title={t('app.lineage.lineageToolPanelTitle')}
                    closable={false} visible={this.state.toolPanelVisible} onClose={this.onToolsClose}
                    >
                    <Space direction="vertical" size="large" style={{ display: 'flex' }}>
                        <Search
                            placeholder={t('app.lineage.lineageToolsQuickFindPlaceholder')}
                            allowClear
                            enterButton
                            size="large"
                            onSearch={this.onSearchEntries}
                        />
                        <Card 
                            title={t('app.lineage.lineageToolsCardSettingsTitle')}
                            size="small"
                            type="inner"
                        >
                            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                                <Space direction="vertical" size="small">
                                    {t('app.lineage.lineageToolsSelHierarchyTitle')}
                                    <Select
                                        key="selHierarchy"
                                        defaultValue="null"
                                        onChange={this.onLineageSettingChange}
                                    >
                                        <Option value="null" key="null">{t('app.lineage.lineageSelectNoHierarchy')}</Option>
                                        {lineageSettings}
                                    </Select>
                                </Space>
                                <Space direction="vertical" size="small">
                                    {t('app.lineage.lineageToolsSelTypesTitle')}
                                    <Select
                                        showSearch
                                        key="selTypes"
                                        mode="tags"
                                        style={{ width: '100%' }}
                                        placeholder={t('app.lineage.lineageSelectTypesHint')}
                                        //defaultValue={initOptions}
                                        value={this.state.displayEntryTypes}
                                        maxTagCount={5}
                                        onChange={this.onEntryTypeSelected}
                                        filterOption={
                                            (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {etOptions}
                                    </Select>
                                </Space>
                                <Space direction="vertical" size="small">
                                    {t('app.lineage.lineageToolsSelRelationsTitle')}
                                    <Select
                                        showSearch
                                        key="selRelTypes"
                                        mode="tags"
                                        style={{ width: '100%' , minWidth: '300px'}}
                                        placeholder={t('app.lineage.lineageSelectRelationTypesHint')}
                                        //defaultValue={initOptions}
                                        value={this.state.hiddeRelationTypes}
                                        maxTagCount={5}
                                        onChange={this.onRelationTypeSelected}
                                        filterOption={
                                            (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {relTypeOptions}
                                    </Select>
                                </Space>
                                <Space direction="vertical" size="small">
                                    {t('app.lineage.lineageToolsSelLineTitle')}
                                    <Select
                                        key="lineSelect"
                                        defaultValue="edgeBazier"
                                        onChange={this.onEdgeStyleSelected}
                                        style={{ width: 100 }}
                                    >
                                        <Option value="edgeBazier" key="edgeBazier">{t('app.lineage.lineageLinesBazier')}</Option>
                                        <Option value="edgeSegments" key="edgeSegments">{t('app.lineage.lineageLinesSegments')}</Option>
                                        <Option value="edgeTaxi" key="edgeTaxi">{t('app.lineage.lineageLinesTaxi')}</Option>
                                    </Select>
                                </Space>
                            </Space>
                        </Card>
                        <Card
                            title={t('app.lineage.lineageToolsCardDownloadTitle')}
                            size="small"
                            type="inner"
                        >
                            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                                <Button style={{width: '100%'}} icon={<DownloadOutlined />} title={t('app.lineage.lineageBtnDownload')} onClick={this.onDownloadClick} key="btnExport">{t('app.lineage.lineageBtnDownload')}</Button>
                                <Button style={{width: '100%'}} icon={<DownloadOutlined />} title={t('app.lineage.lineageBtnDownloadJson')} onClick={this.onDownloadJsonClick} key="btnExportJson">{t('app.lineage.lineageBtnDownloadJson')}</Button>
                                <Button style={{width: '100%'}} icon={<DownloadOutlined />} title={t('app.lineage.lineageBtnDownloadCsv')} onClick={this.onDownloadCSVClick} key="btnExportCSV">{t('app.lineage.lineageBtnDownloadCsv')}</Button>
                            </Space>
                        </Card>
                    </Space>
                </Drawer>
            </div>;
        }
    }
}

export default withTranslation()(Lineage);

Lineage.propTypes = {
    entryRequestResult: entryResultDetailShape,
    incomingRelationsRequestResult: incomingRelationsRequestResult,
    outgoingRelationsRequestResult: outgoingRelationsRequestResult,
    entryTypesRequestResult: entryTypesRequestResult.isRequired,
    onMount: PropTypes.func.isRequired,
    onUnmount: PropTypes.func.isRequired,
    getCytoscapeOptions: PropTypes.func.isRequired,
    getMenuOptions: PropTypes.func.isRequired,
    getLayoutOptions: PropTypes.func.isRequired,
    createNodeElement: PropTypes.func.isRequired,
    createEdgeElement: PropTypes.func.isRequired,
};