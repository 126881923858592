import React from "react";
import { Button, Modal, Space } from 'antd';
import PropTypes from 'prop-types';
//import ButtonGroup from "antd/lib/button/button-group";
import { withTranslation} from 'react-i18next'
import { Icon } from '@ant-design/compatible';

const predefinedIcons = [
    {
        name: "Basic",
        icons: ["read", "book", "gift", "tool", "tags", "picture", "notification", "calendar", "schedule", "environment", "experiment", "code", "control", "pushpin"],
        icons2: []
    },
    {
        name: "State",
        icons: ["question-circle","exclamation-circle","plus-circle","minus-circle","info-circle","check-circle", "clock-circle", "close-circle", "bulb","fire","bug","alert", "safety", "star"],
        icons2: []
    },
    {
        name: "Users",
        icons: ["user", "user-add", "user-delete", "team", "man", "woman", "contacts", "solution", "skin", "smile", "frown", "idcard"],
        icons2: []
    },
    {
        name: "Manipulation",
        icons: ["save", "delete", "snippets", "reconciliation", "audit", "paper-clip", "interaction", "printer"],
        icons2: []
    },
    {
        name: "Data",
        icons: ["database", "table", "border-horizontal", "hdd", "profile", "bars", "file", "file-zip", "file-pdf", "file-text", "file-done", "file-protect", "folder", "folder-open"],
        icons2: []
    },
    {
        name: "Infrastructure",
        icons: ["global", "home", "bank", "laptop", "cloud", "apartment", "api", "deployment-unit", "inbox", "cluster", "build"],
        icons2: []
    },
    {
        name: "Graph",
        icons: ["project", "area-chart", "pie-chart", "bar-chart", "dot-chart", "line-chart", "fund", "fall", "rise", "sliders", "box-plot", "dashboard"],
        icons2: []
    }
];

class IconPickerModal extends React.Component {
    
    render () {
        const {t} = this.props;
        let iconButtons = [];

        predefinedIcons.forEach( cDef => {
            let btns = cDef.icons.map(c => {
                let iconObject = <Icon type={c}></Icon>;
                if (c === this.props.iconValue) {
                    return <Button key={c} icon={iconObject} title={c}
                                type="primary" 
                                onClick={ f => {this.props.onSelect(c)}} 
                                size="large"
                                style={{fontSize: 'large'}}
                                ></Button>;
                } else {
                    return <Button key={c} icon={iconObject} title={c} 
                            onClick={ f => {this.props.onSelect(c)}} 
                            size="large"
                            style={{fontSize: 'large'}}
                        ></Button>;
                }
            });
            
            iconButtons.push(<div key={cDef.name}><span><b>{cDef.name}</b></span><br/><Space size={0}>{btns}</Space></div>);
        });

        return (
            <Modal
                title={t('app.iconPicker.modalTitle')}
                visible={this.props.isVisible}
                //onOk={this.handleOk}
                onCancel={this.props.onCancel}
                width="600px"
                footer={[<Button key="back" onClick={this.props.onCancel}>
                            {t('app.colorPicker.modalBtnReturn')}
                        </Button>]}
                >
                    {iconButtons}
            </Modal>
        )
    }
}

export default withTranslation() (IconPickerModal);

IconPickerModal.propTypes = {
    iconValue: PropTypes.string,
    isVisible: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};