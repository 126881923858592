import React from "react";
import ReactHtmlParser from 'react-html-parser';
import EntryArchiveComparePresenter from '../../components/detail/archive/EntryArchiveCompare';
import { connect } from 'react-redux';
import {fetchDetail} from "../../actions/entryDetail";
import {fetchEntryVersionList} from "../../actions/entryVersionList";
import {fetchObjectList} from "../../actions/objectList";
import {Link, withRouter} from "react-router-dom";
import { isEmptyObject, isEmptyValue } from '../../utils/JsObjectHelper';
import { Checkbox, Tag, Button, Modal, Collapse } from "antd";
import { SearchOutlined, UserOutlined } from '@ant-design/icons';
import { info } from "react-notification-system-redux";
import { fetchDetailOutgoingRelations } from "../../actions/entryDetailOutgoingRelations";
import { withTranslation} from 'react-i18next'
import { Icon } from '@ant-design/compatible';
const { Panel } = Collapse;

/**
 * 
 * @param {RequestResult} entry 
 * @param {RequestResult} entryVersions 
 * @param {string} useVersionsIDs 
 */
const getColumnList = (entry, entryVersions, useVersionsIDs, entryOutgoingRelations) => {
    let columnNamesList = {
        propNamesList : [],
        relNamesList : []
    };

    //Current Entry version will be displayed always. Do it first.
    if (!isEmptyObject(entry) && entry.getState().isDone()) {
        if (!isEmptyObject(entry.getData().properties)) {
            for (var prop in entry.getData().properties) {
                if (Object.prototype.hasOwnProperty.call(entry.getData().properties, prop)) {
                    columnNamesList.propNamesList.push(prop);
                }
            }
        }

        //entry relations
        if (!isEmptyValue(entryOutgoingRelations) && entryOutgoingRelations.getState().isDone()) {
            entryOutgoingRelations.getData().forEach(r=> {
                if (!columnNamesList.relNamesList.includes(r.name)) {
                    columnNamesList.relNamesList.push(r.name);
                }
            });
        }
    }

    //Filter history versions which should be used
    if (!isEmptyObject(entryVersions) &&  entryVersions.getState().isDone() && entryVersions.getData().length > 0 &&
        !isEmptyObject(useVersionsIDs) && useVersionsIDs.split(',').length > 0)
    {
        let usedVersions = entryVersions.getData().filter(v => useVersionsIDs.split(',').includes(v.version.toString()));
        usedVersions.forEach(v => {
            //version properties
            for (var prop in v.properties) {
                if (Object.prototype.hasOwnProperty.call(v.properties, prop)) {
                    if (!columnNamesList.propNamesList.includes(prop)) {
                        columnNamesList.propNamesList.push(prop);
                    }
                }
            }
            //version relations
            if (!isEmptyValue(v.relations) && !isEmptyValue(v.relations.outgoingRelations)) {
                v.relations.outgoingRelations.forEach(r=> {
                    if (!columnNamesList.relNamesList.includes(r.relationName)) {
                        columnNamesList.relNamesList.push(r.relationName);
                    }
                });
            }
        });
    }

    return columnNamesList;
};

/**
 * 
 * @param {*} entry 
 * @param {*} propName 
 */
const getEntryPropValue = (entry, propName) => {
    if (!isEmptyValue(entry.properties[propName])) {
        if (!Array.isArray(entry.properties[propName])) {
            return entry.properties[propName];
        } else {
            //return "Item count: " + entry.properties[propName].length;
            return JSON.stringify(entry.properties[propName]);
        }
    } else {
        return null;
    }
};

/**
 * 
 * @param {*} entry 
 * @param {*} relationName 
 */
const getEntryVersionRelationsValue = (entry, relationName) => {
    if (!isEmptyValue(entry.relations) && !isEmptyValue(entry.relations.outgoingRelations)) {
        let relationList = 
            entry.relations.outgoingRelations
                .filter(r=> r.relationName === relationName)
                .map(r => { return { name: r.name, id: r.id};});
        return JSON.stringify(relationList);
    } else {
        return null;
    }
};

/**
 * 
 * @param {*} entry 
 * @param {*} relationName 
 */
const getEntryRelationsValue = (entryRelList, relationName) => {
    if (!isEmptyValue(entryRelList)) {
        let relationList = 
            entryRelList.filter(r => r.name === relationName)
                .map(r => { return { name: r.target.name, id: r.target.id};});
        return JSON.stringify(relationList);
    } else {
        return null;
    }
};

const prepareNewRow = (columnName, attributeDefinitions) => {
    let newRow = {
        key: columnName,
        propName: columnName,
        propType: "",
        isDiff: false
    };

    //fill property information from entry definition
    if (!isEmptyObject(attributeDefinitions.find(d=> d.techName===columnName))) {
        newRow.propName = attributeDefinitions.find(d=> d.techName===columnName).name;
        newRow.propType = attributeDefinitions.find(d=> d.techName===columnName).type;
    }

    return newRow;
};

/**
 * 
 * @param {*} entry 
 * @param {*} entryVersions 
 * @param {*} useVersionsIDs 
 * @param {*} objectDefinitions 
 */
const getCompareTableData = (entry, entryVersions, useVersionsIDs, objectDefinitions, entryOutgoingRelations, translator) => {
    const columnNamesList = getColumnList(entry, entryVersions, useVersionsIDs, entryOutgoingRelations);
    
    let tableData = [];
    let usedVersions = [];
    let currentPropDefinitions = [];
    let rowValue = null;
    let parentVal = null;

    let columnConfig = [{
        title: "",
        dataIndex: "propName",
        className: "boldText",
        //fixed: 'left',
        width: 150
    }];

    if (objectDefinitions.getState().isDone() && entry.getState().isDone()) {
        currentPropDefinitions = objectDefinitions.getData().find(def=> def.type === entry.getData().type);
        if (!isEmptyObject(currentPropDefinitions)) {
            currentPropDefinitions = currentPropDefinitions.properties.attributes
                .map((a) => { return { name: a.name, type: a.type, techName: a.techName}});
        }
    }

    //Fill up Column Configuration. Current Entry and all selected versions
    if (!isEmptyObject(entry) && entry.getState().isDone()) {
        columnConfig.push({
            title: entry.getData().name + " (" + translator.t('app.entry.tools.versions.currentVersion') + ")",
            dataIndex: 'V' + entry.getData().version,
            render: renderPropValues,
            width: 300
        });
    }
    if (!isEmptyObject(entryVersions) &&  entryVersions.getState().isDone() && entryVersions.getData().length > 0 &&
    !isEmptyObject(useVersionsIDs) && useVersionsIDs.split(',').length > 0)
    {
        usedVersions = entryVersions.getData()
            .filter(v => useVersionsIDs.split(',').includes(v.version.toString()))
            .sort((a,b) => { return b.version - a.version});
        
        usedVersions.forEach(v => {
            columnConfig.push({
                title: v.name +" (V:" + v.version + ")",
                dataIndex: 'V' + v.version,
                render: renderPropValues,
                width: 300
            });
        });
    }
        
    //First line is create date
    let dateRow = {
        key: "created",
        propName: translator.t('app.entry.tools.versions.versionCreated'),
        propType: "date",
        isDiff: false
    };
    //Last modified by user row
    let creatorRow = {
        key: "versionLastModifiedByUserName",
        propName: translator.t('app.entry.tools.versions.versionModifiedBy'),
        propType: "identity",
        isDiff: false
    };
    //Parent folder ID - for tracking move changes
    let parentRow = {
        key: "parentId",
        propName: translator.t('app.entry.tools.versions.versionParent'),
        propType: "parentLink",
        isDiff: false
    };
    //Description
    let descRow = {
        key: "description",
        propName: translator.t('app.entry.tools.versions.versionDescription'),
        propType: "longtext",
        isDiff: false
    };

    if (!isEmptyObject(entry) && entry.getState().isDone()) {
        if (!isEmptyObject(entry.getData().updated))
            dateRow['V' + entry.getData().version] = translator.t('datetime', {date : new Date(entry.getData().updated)});
        else
            dateRow['V' + entry.getData().version] = translator.t('datetime', {date : new Date(entry.getData().created)});

        creatorRow['V' + entry.getData().version] = { userName: entry.getData().modifiedByUserName, userId : entry.getData().modifiedByUserId };
        rowValue = descRow['V' + entry.getData().version] = entry.getData().description;

        parentVal = parentRow['V' + entry.getData().version] = entry.getData().parent.id;
    }
    usedVersions.forEach(v => {
        if (!isEmptyObject(v.updated))
            dateRow['V' + v.version] = translator.t('datetime', {date : new Date(v.updated)});
        else
            dateRow['V' + v.version] = translator.t('datetime', {date : new Date(v.created)});
        //dateRow['V' + v.version] = translator.t('datetime', {date : new Date(v.archived)});
        creatorRow['V' + v.version] = { userName: v.versionLastModifiedByUserName, userId : v.versionLastModifiedByUserId };
        descRow['V' + v.version] = v.description;
console.log(creatorRow);
        if (rowValue !== v.description) {
            descRow.isDiff = true;
        }

        parentRow['V' + v.version] = v.parentId;
        if (parentVal !== v.parentId) {
            parentRow.isDiff = true;
        }
    });
    tableData.push(dateRow);
    tableData.push(creatorRow);
    tableData.push(parentRow);
    tableData.push(descRow);

    //Fill in all properties
    columnNamesList.propNamesList.forEach( p => {
        let newRow = prepareNewRow(p, currentPropDefinitions);

        rowValue = null;

        if (!isEmptyObject(entry) && entry.getState().isDone()) {
            rowValue = getEntryPropValue(entry.getData(), p);
            newRow['V' + entry.getData().version] = rowValue;
        }

        usedVersions.forEach(v => {
            newRow['V' + v.version] = getEntryPropValue(v, p);
            if (rowValue !== getEntryPropValue(v, p)) {
                newRow.isDiff = true;
            }
        });

        tableData.push(newRow);
    });

    //Fill in all relations
    columnNamesList.relNamesList.forEach( r => {
        let newRow = prepareNewRow(r, currentPropDefinitions);
        rowValue = null;

        //z aktualniho Entry je nutné dotáhnout outgoing relations!
        if (!isEmptyObject(entryOutgoingRelations) && entryOutgoingRelations.getState().isDone()) {
            let currentEntryRel = getEntryRelationsValue(entryOutgoingRelations.getData(), r);
            rowValue = currentEntryRel;
            newRow['V' + entry.getData().version] = rowValue;
        }

        //relations verzí
        usedVersions.forEach(v => {
            newRow['V' + v.version] = getEntryVersionRelationsValue(v, r);
            if (rowValue !== getEntryVersionRelationsValue(v, r)) {
                newRow.isDiff = true;
            }
        });

        tableData.push(newRow);
    });

    return {
        columnConfig: columnConfig,
        tableData: tableData
    };
};


const renderPropValues = (text, record) => {
    if (!isEmptyObject(record.propType)) {
        switch (record.propType) {
            case "identity":
                if (!isEmptyValue(text.userId))
                    return <a href={"/id2entry/" + text.userId} target="_blank" rel="noopener noreferrer"><UserOutlined /> {text.userName}</a>;
                else
                    return "---";
            case "number":
                return <span className="compareTableNumber">{text}</span>;
            case "boolean":
                return <Checkbox checked={text} disabled />;
            case "fileUrlArray":
                let urlArr = JSON.parse(text);
                if (!isEmptyValue(urlArr)) {
                    return <span>
                                {urlArr.map(tag => (
                                <Tag color="blue" key={tag.name}>
                                    <a href={tag.url} target="_blank" rel="noopener noreferrer">{tag.name}</a>
                                </Tag>
                                ))}
                            </span>;
                } else {
                    return null;
                }
            case "longtext":
                let txtLen = (!isEmptyValue(text) ? text.length : 0);
                let btnClicked = function() {
                    showRichTextCompare(record);
                };
                return <div>
                        <span>Richtext with {txtLen} characters</span>
                        <Button onClick={btnClicked} type="dashed" icon={<SearchOutlined />} size="small" style={{float:"right"}}>
                        See formated
                        </Button>
                    </div>
            case "relation":
                if (!isEmptyValue(text)) {
                    let relArr = JSON.parse(text);
                    return <span>
                                {relArr.map(tag => (
                                <Tag color="blue" key={tag.name}>
                                    <a href={"/entry/" + tag.id} target="_blank" rel="noopener noreferrer">{tag.name}</a>
                                </Tag>
                                ))}
                            </span>;
                } else {
                    return null;
                }
            case "parentLink":
                return <Link to={"/entry/" + text} target="_blank">{text}</Link>;
            case "hyperlink":
                return text && text.url?<a href={text.url} target="_blank">{text.label?text.label:text.url}</a>:text;
            default:
                return text;
        }
    }if(record.propName === "folderIcon"){
        return <Icon type={text}
            className="folderIconPreview" />
    }
    if(record.propName === "folderIconColor"){
        return  <div style={{display:'flex',alignItems:'center'}}>{text} <span style={{width:'24px',height:'14px',backgroundColor:text, display:'inline-block', marginLeft:'10px'}}></span></div> 

    }
    else {
        return text;
    }
};

const showRichTextCompare = (record) => {
    let boxContent = [];
    let firstId = [];

    for (const property in record) {
        if (property.startsWith('V')) {
            //expand only first 2
            if (firstId.length < 2) {
                firstId.push(property);
            }
            boxContent.push(
                <Panel key={property} header={property}>{ReactHtmlParser(record[property])}</Panel>
            );
        }
    }

    Modal.info({
        title: 'Richtext property compare',
        content: (
            <Collapse defaultActiveKey={firstId}>
                {boxContent}
            </Collapse>
        ),
        width: "100%",
        onOk() {},
      });

    //call modal window
    info();
};


const mapStateToProps = (state, ownProps) => {
    return {
        entryRequestResult: state.entryDetail,
        //outgoingRelationsRequestResult: state.entryDetailOutgoingRelations,
        entryVersionListRequestResult: state.entryVersionList,
        //columnList: getColumnList(state.entryDetail, state.entryVersionList, ownProps.match.params.versionIdList),
        compareTableData: getCompareTableData(state.entryDetail, state.entryVersionList, ownProps.match.params.versionIdList, state.objectList, state.entryDetailOutgoingRelations, ownProps.i18n),
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount: (entryId, versionList, shouldUpdateOnly = false) => {
            dispatch(fetchDetail(entryId, shouldUpdateOnly));
            dispatch(fetchDetailOutgoingRelations(entryId, shouldUpdateOnly));
            dispatch(fetchEntryVersionList(entryId));
            dispatch(fetchObjectList());
        },
        onUnmount: () => {
            //dispatch(unmountDetail());
        },
    }
};

const EntryArchiveCompare = connect(
    mapStateToProps,
    mapDispatchToProps
)(EntryArchiveComparePresenter);

export default withTranslation() (withRouter(EntryArchiveCompare));