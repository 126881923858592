import React from "react";
import "antd/dist/antd.css";
import { Button, Descriptions, Modal, Select,DatePicker, Space, Typography } from "antd";
import {
	CloseOutlined,
	AppstoreAddOutlined,
	UserAddOutlined
} from "@ant-design/icons";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import moment from 'moment';
import { isUndefined,  isArray, isObject } from "../../utils/JsObjectHelper";
import cloneDeep from "lodash.clonedeep";
import { index } from "react-froala-wysiwyg";
import { isMobile } from "../../utils/AdaptiveLayout";
const { Option } = Select;
const { Text } = Typography;
class BpmnAdminPanel extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modalAddCandidate: false,
			modalSetAssignee: false,
			groups:[],
			users:[],
			delUsers:[],
			delGroups:[],
			assignee: null,
		};
	}
	componentDidMount() {
		this.props.onMount();
	}
	componentDidUpdate(prevProps, prevState){
		if(prevProps.taskId !== this.props.taskId){
			this.props.onMount();
		}
	}

	changeCandidate = (list) => {
		if (this.props.groupListRequestResult.data.find((el) => list.find((e) => el.id === e))) {
			let newGroup = list.map((el)=>({groupId:el,type:"candidate"}))	
			console.log("if grop func changeCandidate");
			this.setState({
				groups:newGroup 
			})
		}
		if (this.props.userListRequestResult.data.find((el) => list.find((e) => el.id === e))) {
			let newUsers = list.map((el)=>({userId:el,type:"candidate"}))
			console.log("if user func changeCandidate");
			this.setState({
				users:newUsers
			})
		}
	}
	deCandidate = (list) => {
		let candidate = ""
		let delUser = cloneDeep(this.state.delUsers)
		let delGroup = cloneDeep(this.state.delGroups)
		if (this.props.groupListRequestResult.data.find((el) => list === el.id )) {
			candidate = {groupId:list,type:"candidate"}	
			delGroup.push(candidate)
			this.setState({
				delGroups:delGroup
			})
		}
		if (this.props.userListRequestResult.data.find((el) => list === el.id )) {
			candidate={userId:list,type:"candidate"}
			delUser.push(candidate)
			this.setState({
				delUsers:delUser
			})
		}	
	}
	addCandidate =() =>{
		let newState =[...new Set([...this.state.groups,...this.state.users])]
		let deleteCandidate =[...new Set([...this.state.delGroups,...this.state.delUsers])]
		let candidate = this.props.candidate.getState().isLoading() ? [] : this.props.candidate.getData();
		let addCandidate = newState.filter((el)=> isUndefined(candidate.find((e)=>el.groupId === e.groupId )) && isUndefined(candidate.find((e)=>el.userId === e.userId )))
		if(deleteCandidate.length>0){
			this.props.deleteCandidate(deleteCandidate)
		}
		if(addCandidate.length >0){
			this.props.addCandidate(addCandidate)
		}
		this.setState({
			modalAddCandidate: false
		})
	}
	changeDate = (date, dateString) => {
		let newDate = {name:this.props.taskDetail.name,due: date.format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),priority:this.props.taskDetail.priority,description:this.props.taskDetail.description}
		this.props.setDueDate(newDate)
	};
	setAssignee = (id) => {
	    let identity={userId:id }
		this.setState({
			assignee: identity
		})
	}
	
	render() {
		const { t } = this.props;
		let groupList = this.props.groupListRequestResult.getState().isLoading() ? [] : this.props.groupListRequestResult.getData();
		let userList = this.props.userListRequestResult.getState().isLoading() ? [] : this.props.userListRequestResult.getData();
		let user = this.props.user
		let options = []
		let optionsUser = []

		if (isArray(groupList)) {
			groupList.forEach((el) => {
				options.push(<Option key={el.id} value={el.id}>
					{el.name}
				</Option>)
			})
		}
		if (isArray(userList)) {
			userList.forEach((el) => {
				optionsUser.push(<Option key={el.id} value={el.id}>
					{el.firstName} {el.lastName}
				</Option>)
			})
		}
		 let def = this.props.candidate.getState().isLoading() ? [] : this.props.candidate.getData();
		 let defaultValueGroups = []
		 let defaultValueUsers = []
		 let defaultAssignee = []
		if (def) {
			defaultValueGroups = def.filter((e) => !isUndefined(e.groupId)).map((el) => el.groupId)
			defaultValueUsers = def.filter((e) => !isUndefined(e.userId) && e.type.includes("candidate")).map((el) => el.userId)
			defaultAssignee = def.filter((e) => !isUndefined(e.userId) && e.type.includes("assignee")).map((el) => el.userId)
		}
		let modal =
			<Modal title={t("app.bpmn.addGroups")} visible={this.state.modalAddCandidate}
				onOk={this.addCandidate}
				onCancel={() =>
					this.setState({
						modalAddCandidate: false
					})
				}>
				<Space direction="vertical" size="middle" style={{ display: 'flex' }}>
					<Select
						mode="multiple"
						style={{ width: '100%' }}
						defaultValue={defaultValueGroups}
						placeholder={t("setup.bpmn.selectGroup")}
						onChange={this.changeCandidate}
						onDeselect={this.deCandidate}>
						
						{options}
					</Select>
					<Select
						mode="multiple"
						style={{ width: '100%' }}
						defaultValue={defaultValueUsers}
						placeholder={t("setup.bpmn.selectUser")}
						onChange={this.changeCandidate}
						onDeselect={this.deCandidate}
					>
						{optionsUser}
					</Select>
				</Space>
			</Modal>
		let modalSetAssignee =
			<Modal title={t("app.bpmn.setAssignee")} visible={this.state.modalSetAssignee}
				onOk={() =>{
					this.props.setAssignee(this.state.assignee)
					this.setState({
						modalSetAssignee: false
					})}}
				onCancel={() =>
					this.setState({
						modalSetAssignee: false
					})
				}>
				<Space direction="vertical" size="middle" style={{ display: 'flex' }}>
					<Select
						style={{ width: '100%' }}
						placeholder={t("setup.bpmn.selectAssignee")}
						onChange={this.setAssignee}>
						{optionsUser}
					</Select>
				</Space>

			</Modal>
		let assignee = null
		if (defaultAssignee && isArray(userList)) {
			let user = userList.find((el) => el.id === defaultAssignee[0])
			if (isObject(user)) {
				assignee = <> 
				<a style={{ color: "#003a8c", fontWeight: "bold" }} href={"/id2entry/" + user.id} target="_blank" >{user.firstName} {user.lastName}</a>
				<Button
					onClick={() => this.props.deleteAssignee(defaultAssignee[0])}
					type="text"
					style={{ color: "#003a8c", fontWeight: "bold" }}
				>
					<CloseOutlined />
				</Button></>
			}
			else {
				assignee = <Button
					type="text"
					style={{ color: "#003a8c", fontWeight: "bold" }}
					onClick={() => {
						this.setState({
							modalSetAssignee: true
						})
					}}
				>
					{t("app.bpmn.setAssignee")}<UserAddOutlined />
				</Button>
			}
		}
		let panel = null
		if (user.realmRoles.find((el) => el === "AP_SETUP")) {
			panel = (<>
				<Descriptions size="small" column={isMobile()?2: 3} >
					<Descriptions.Item>
						 <DatePicker
							allowClear={false}
							format="YYYY-MM-DD HH:mm:ss"
							onChange={this.changeDate}
							bordered={false}
							placeholder={t("app.bpmn.dueDate")}
							value={this.props.taskDetail.due? moment(this.props.taskDetail.due, "YYYY-MM-DD HH:mm:ss"):undefined}
							showTime={{
								defaultValue: moment('00:00:00', 'HH:mm:ss'),
							  }}
							/> 
					</Descriptions.Item>
					<Descriptions.Item>
					<Button
							type="text"
							onClick={() => {
								this.setState({
									modalAddCandidate: true
								})
							}}
							style={{ color: "#003a8c", fontWeight: "bold" }}
						>
							{defaultValueUsers.length > 0 || defaultValueGroups.length > 0? t("setup.bpmn.showCandidate"):t("app.bpmn.addGroups")}<AppstoreAddOutlined />
						</Button>
					</Descriptions.Item>
					<Descriptions.Item>
						{assignee}
					</Descriptions.Item>
				</Descriptions> </>
			)
		}
		return (<>
			{panel}
			{modal}
			{modalSetAssignee}
		</>)
	}
}

const AdminPageWithRouter = withRouter(BpmnAdminPanel);

export default withTranslation()(AdminPageWithRouter);
