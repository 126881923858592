import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Menu, Layout } from 'antd';
import {
    TeamOutlined,
    LayoutOutlined,
    ApartmentOutlined,
    PullRequestOutlined,
    OrderedListOutlined,
    SettingOutlined,
    ImportOutlined,
    AuditOutlined,
    BuildOutlined,
    CalendarOutlined,

    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
    DesktopOutlined,
    PieChartOutlined,
} from '@ant-design/icons';
import { withTranslation } from 'react-i18next'
import { isMobile, isTablet } from "../../utils/AdaptiveLayout";
const { Header, Sider, Content } = Layout;


const { SubMenu } = Menu;

class SetupMenuMobile extends React.Component {

    render() {
        const { t } = this.props;

        let itemKey = (this.props.setupType.split('_').length > 1 ? this.props.setupType.split('_')[1] : this.props.setupType);
        let groupKey = (this.props.setupType.split('_').length > 1 ? this.props.setupType.split('_')[0] : this.props.setupType);
        let collapsed = false
        if (isTablet() || isMobile()) {
            collapsed = true
        }
        return (
            <>
                <Layout>
                    <Sider trigger={null} collapsible collapsed={collapsed} theme="light" >
                        <Menu
                            theme="light"
                            mode="inline"
                            defaultSelectedKeys={['1']}
                            items={[
                                {
                                    key: 'general',
                                    icon: <SettingOutlined />,
                                    label: <Link to={`/setup/general`}>{t('setup.menu.generalSetup')}</Link>,
                                },
                                {
                                    key: 'users_sub',
                                    icon: <UserOutlined />,
                                    label: <Link to={`/setup/users`}>{t('setup.menu.users')}</Link>,
                                    children: [{
                                        key: 'users',
                                        label: <Link to={`/setup/users`}>{t('setup.menu.users')}</Link>,
                                    },
                                    {
                                        key: 'groups',
                                        label: <Link to={`/setup/groups`}>{t('setup.menu.groups')}</Link>,
                                    },
                                    {
                                        key: 'idmapping',
                                        label: <Link to={`/setup/idmapping`}>{t('setup.menu.usersMapping')}</Link>,
                                    },
                                    {
                                        key: 'id2entrymapping',
                                        label: <Link to={`/setup/id2entrymapping`}>{t('setup.menu.id2EntryMapping')}</Link>,
                                    },]
                                },
                                {
                                    key: 'content',
                                    icon: <LayoutOutlined />,
                                    label: <Link to={`/setup/content`}>&nbsp;{t('setup.menu.contentStructure')}</Link>,
                                },
                                {
                                    key: 'lineage',
                                    icon: <ApartmentOutlined />,
                                    label: <Link to={`/setup/lineage`}>&nbsp;{t('setup.menu.lineage')}</Link>,
                                },
                                {
                                    key: 'bpmn_sub',
                                    icon: <PullRequestOutlined />,
                                    label: t('setup.menu.bpmn'),
                                    children: [{
                                        key: 'infobpmn',
                                        label: <Link to={`/setup/infobpmn`}>{t('setup.menu.infoForBpmn')}</Link>,
                                    },
                                    {
                                        key: 'workflow',
                                        label: <Link to={`/setup/workflow`}>{t('setup.menu.workflow')}</Link>,
                                    },
                                    {
                                        key: 'tasklist',
                                        label: <Link to={`/setup/tasklist`}>{t('setup.menu.tasklist')}</Link>,
                                    },
                                    {
                                        key: 'bpmnprocess',
                                        label: <Link to={`/setup/bpmnprocess`}>{t('setup.menu.bpmnProcess')}</Link>,
                                    }
                                   ,]
                                },
                                {
                                    key: 'loader_sub',
                                    icon: <ImportOutlined />,
                                    label: t('setup.menu.loader'),
                                    children: [{
                                        key: 'loader',
                                        label: <Link to={`/setup/loader`}>{t('setup.menu.loaderSetup')}</Link>,
                                    },
                                    {
                                        key: 'loaderAudit',
                                        label: <Link to={`/setup/loaderAudit`}>{t('setup.menu.loaderAudit')}</Link>,
                                    }
                                    ]
                                },
                                {
                                    key: 'reindex',
                                    icon: <OrderedListOutlined />,
                                    label: <Link to={`/setup/reindex`}>&nbsp;{t('setup.menu.reindex')}</Link>,
                                }
                            ]}
                        />

                    </Sider>
                </Layout>
            </>
        );
    }
}


const SetupMenuMobileWithRouter = withRouter(SetupMenuMobile);

export default withTranslation()(SetupMenuMobile);
