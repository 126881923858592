import ObjectFormPresenter from '../../components/object/ObjectForm';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {fetchObjectList} from "../../actions/objectList";
import {baseObjectDefinitionShape} from "../../shapes/ObjectDefinitionShape";
import PropTypes from "prop-types";
import { getGeneralSettingsObject } from '../../utils/GeneralSettingsHelper';
import { fetchSettingsList } from '../../actions/settings';



const mapStateToProps = (state, ownProps) => {
    return {
        entryTypesRequestResult: state.objectList,
        generalSettingsObject: getGeneralSettingsObject(state.settingsList)
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount() {
            dispatch(fetchObjectList());
            dispatch(fetchSettingsList(false));
        },
    }
};

const ObjectForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(ObjectFormPresenter);

export default withRouter(ObjectForm);

ObjectForm.propTypes = {
    data: baseObjectDefinitionShape.isRequired,
    onSaveObjectDefinition: PropTypes.func.isRequired
};