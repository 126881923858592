import React from "react";

import PropTypes from "prop-types";
import { Layout } from 'antd';
import UserManagement from '../components/setup/UserManagement';
import SetupMenu from '../components/setup/SetupMenu';
import DoesNotExist from '../components/system/DoesNotExist';
import ObjectList from "../containers/object/ObjectList";
import ObjectDefinitionWizard from "../containers/setup/ObjectDefinitionWizard";
import LineageList from "../containers/setup/LineageList";
import LineageDetail from "../containers/setup/LineageDetail";
import WorkflowList from "../containers/setup/WorkflowList";
import WorkflowDetail from "../containers/setup/WorkflowDetail";
import { Helmet } from "react-helmet";
import Reindex from "./setup/Reindex";
import General from "../containers/setup/General";
import LoadSettingList from "../containers/setup/loader/LoadSettingList";
import LoadSettingDetail from "../containers/setup/loader/LoadSettingDetail";
import TaskList from "../containers/bpmn/TaskList";
import BpmnProcess from "../containers/bpmn/BpmnProcess";
import InfoForBpmn from "../containers/bpmn/InfoForBpmn";
import { isMobile, isTablet } from "../utils/AdaptiveLayout";
import UserMappingList from "../containers/setup/UserMappingList";
import UsersMappingDetail from "../containers/setup/UsersMappingDetail";
import SetupMenuMobile from "./adaptive/SetupMenuMobile";
import Id2EntryMapping from "../containers/setup/id2entry/Id2EntryMapping";
const { Content, Sider } = Layout;


class SetupMain extends React.Component {
    render() {
        let printType = this.props.setupType
        let collapsed = false
        let setupContent = '';

        switch (this.props.setupType) {
            case 'users':
                setupContent = <UserManagement displaySectionName="users"></UserManagement>;
                printType = 'users_users';
                break;
            case 'groups':
                setupContent = <UserManagement displaySectionName="groups"></UserManagement>;
                printType = 'users_groups';
                break;
            case 'domain':
                setupContent = <UserManagement displaySectionName="domain"></UserManagement>;
                printType = 'users_domain';
                break;
            case 'content':
                setupContent = <ObjectList></ObjectList>
                printType = 'list_content';
                break;
            case 'contentedit':
                setupContent = <ObjectDefinitionWizard />;
                printType = 'edit_content';
                break;
            case 'lineage':
                setupContent = <LineageList></LineageList>
                printType = 'list_lineage';
                break;
            case 'lineageedit':
                setupContent = <LineageDetail></LineageDetail>
                printType = 'edit_lineage';
                break;
            case 'workflow':
                setupContent = <WorkflowList></WorkflowList>
                printType = 'list_workflow';
                break;
            case 'workflowedit':
                setupContent = <WorkflowDetail></WorkflowDetail>
                printType = 'edit_workflow';
                break;
            case 'reindex':
                setupContent = <Reindex></Reindex>
                printType = 'reindex';
                break;
            case 'general':
                setupContent = <General></General>
                printType = 'general';
                break;
            case 'loader':
                setupContent = <LoadSettingList></LoadSettingList>
                printType = 'loader_list';
                break;
            case 'loaderedit':
                setupContent = <LoadSettingDetail></LoadSettingDetail>
                printType = 'loader_edit';
                break;
            case 'tasklist':
                setupContent = <TaskList showPanel={true} />;
                printType = 'bpmn_tasklist';
                break;
            case 'bpmnprocess':
                setupContent = <BpmnProcess />;
                printType = 'bpmn_process';
                break;
            case 'infobpmn':
                setupContent = <InfoForBpmn />;
                printType = 'bpmn_info';
                break;
            case 'idmapping':
                setupContent = <UserMappingList />;
                printType = 'idmapping';
                break;
            case 'idmappingedit':
                setupContent = <UsersMappingDetail />;
                printType = 'idmapping';
                break;
            case 'id2entrymapping':
                setupContent = <Id2EntryMapping />;
                printType = 'users_id2entrymapping';
                break;

            default:
                setupContent = <DoesNotExist titleText={this.props.setupType}></DoesNotExist>
                //setupContent = this.props.setupType
                break;
        }
        // let menu = <Sider>
        //     <SetupMenu
        //         setupType={printType}
        //     ></SetupMenu>
        // </Sider>
        if (isTablet() || isMobile()) {
            collapsed =true
        }
        let menu =<Sider trigger={null} collapsible collapsed={collapsed} theme="light" >
        <SetupMenuMobile setupType={printType} />
    </Sider>
        return (
            <Layout>

                {menu}
                <Content
                    style={{ /*height: '100%',*/ backgroundColor: '#ffff', paddingLeft: '24px' }}
                >
                    <Helmet>
                        <title>Aphinit Setup</title>
                    </Helmet>
                    {setupContent}
                </Content>
            </Layout>
        );
    }
}

export default SetupMain;

SetupMain.propTypes = {
    setupType: PropTypes.string.isRequired
}