import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Modal, Table, Select, Typography, Space, Tag, Alert } from 'antd';
import {  EditOutlined, SearchOutlined,  } from '@ant-design/icons';
import { withTranslation } from 'react-i18next'
import { fetchEntryDetailMinimal } from "../../../apicalls/fetchEntryDetail";
import { fetchIdentityToEntriesList } from "../../../apicalls/fetchIdentityToEntriesList";
import { isUndefined } from "../../../utils/JsObjectHelper";
import { fetchEntries } from "../../../apicalls/fetchEntries";
import EntryTypeTag from "../../controls/EntryTypeTag";
import { notificationSuccess,notificationWarning } from "../../../utils/NotificationsHelper";
import { fetchIdentityToEntry } from "../../../apicalls/fetchIdentityToEntry";
import { fetchCreateEntryToIdentity } from "../../../apicalls/fetchCreateEntryToIdentity";
import { getColumnCustomAttributeProps, getColumnSearchProps } from "../../../utils/TableHelper";
import { highlightSearchedText } from "../../../utils/TextHighlighter";
import debounce from 'lodash/debounce';
const { Option } = Select;
const { Text } = Typography;
class WithAssignedEntriesTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            searchedColumn: '',
            selectedRowKeys: [],
            isModalVisible: false,
            selectedMap: null,
            folderName: [],
            data: null,
            modalEditEntry: false,
            entry: null,
            searchedData: [],
            isSearchBoxLoading: false,
            identityID: null,

        };
        this.handleSearch = debounce(this.handleSearch, 500);

    }
    componentDidMount(){
        if (isUndefined(this.state.data)) {
            this.getData()
        }
    }
    getData = () => {
        let data = [];
        fetchIdentityToEntriesList((res) => {
            res.filter((el) => el.identity.deleted === null)
                .forEach((element) => {
                    let obj = {
                        entryId: element.entry.id,
                        identityId: element.identity.id,
                        key: element.identity.id,
                        name: element.identity.name,
                        entryName: element.entry.name,
                        folderName: element.entry.parent.name,
                        enabled: element.identity.enabled

                    }; data.push(obj)
                })
            this.setState({ data: data })
        })
    }
    onSelectChange = (newSelectedRowKeys) => {
        this.setState({
            selectedRowKeys: newSelectedRowKeys
        });
    };
    handleOk = () => {
        if (!isUndefined(this.state.selectedRowKeys) && !isUndefined(this.state.selectedMap)) {
            let obj = { identityIds: this.state.selectedRowKeys, mappingId: this.state.selectedMap.name }
            console.log(this.state.selectedRowKeys);
            console.log(this.state.selectedMap.name);
            fetchCreateEntryToIdentity(obj, () => this.getData())
            this.setState({ isModalVisible: false });
        }
    }

    handleCancel = () => {
        this.setState({ isModalVisible: false });
    }

    showModal = () => {
        if (this.state.selectedRowKeys.length > 0) {
            this.setState({ isModalVisible: true });
        }

    }

    onChangeSelect = (value) => {
        let idMap = this.props.idMapping.find((el) => el.name === value);
        fetchEntryDetailMinimal(idMap.value.folderId, search => this.setState({ folderName: search, selectedMap: idMap }));
    }


    editUserMapping = (e, record) => {
        this.setState({ modalEditEntry: true, identityID: record.identityId });

    }
    handleOkEditEntry = () => {
        let entryId = this.state.entry.split('|#|')
        fetchIdentityToEntry(this.state.identityID, entryId[1], this.connectedSuccess)
    }

    handleCancelEditEntry = () => {
        this.setState({ modalEditEntry: false });
    }
    handleSearch = (value) => {
        this.setState({ isSearchBoxLoading: true });
        fetchEntries(value,"withAssignedEntriesTable", searchData => this.handleDataSearched(searchData), null, ["object"]);
    };
    handleDataSearched = (searchData) => {
        this.setState({ searchedData: searchData, isSearchBoxLoading: false })
    };
    handleSearchChange = (value) => {
        this.setState({ entry: value });
    };
    connectedSuccess = (err) => {
        const { t } = this.props;
        if (err.message === "Couldn't get successful response") {
            notificationWarning(t('setup.identity2entry.warningNotificationTitle'),t('setup.identity2entry.warningNotificationMessage'))
        }
        else {
            notificationSuccess(t('app.userPage.notificationTitle'))
            this.setState({ modalEditEntry: false });
        }
    }

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    handleSearchName = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleRenderTextColumn = (text, record, dataIndex) => {
        return this.state.searchedColumn === dataIndex ? (
            <a href={"/id2entry/" + record.identityId} target="_blank" >{highlightSearchedText(text, this.state.searchText)}</a>
        ) : (
            <a href={"/id2entry/" + record.identityId} target="_blank" >{text}</a>
        );
    };
    handleRenderTextColumnEntry = (text, record, dataIndex) => {
        return this.state.searchedColumn === dataIndex ? (
            <a href={"/entry/" + record.entryId} target="_blank" >{highlightSearchedText(text, this.state.searchText)}</a>
        ) : (
            <a href={"/entry/" + record.entryId} target="_blank" >{text}</a>
        );
    };

    render() {
        const { t } = this.props;
        let entryTypeNameList = null;

        if (this.props.entryTypesRequestResult.getState().isDone()) {
            entryTypeNameList = this.props.entryTypesRequestResult.getData().map((item, key) => { return { type: item.type, name: item.name, color: item.properties.typeColor }; });
        }
        let modalEditEntry = null;
       
        let options = []
        if (this.props.idMapping) {
            options = this.props.idMapping.map((el) => <Option key={el.name} value={el.name}>{el.value.idMapName}</Option>);
        }

        const columns = [
            {
                title: t('setup.identity2entry.withAssignedEntriesTblAction'),
                key: "action",
                width: "10%",
                render: (text, record) => {
                    return (<span>
                        <Button onClick={(e) => { this.editUserMapping(e, record) }}><EditOutlined /> {t('setup.identity2entry.withAssignedEntriesTblBtn')}</Button>
                    </span>)
                }
            },
            {
                title: t('setup.identity2entry.withAssignedEntriesTblName'),
                dataIndex: "name",
                key: "name",
                ...getColumnSearchProps("name", this.handleSearchName, this.handleReset, this.handleRenderTextColumn, t, this.searchInput)

            },
            {
                title: t('setup.identity2entry.withAssignedEntriesTblEntryName'),
                dataIndex: "entryName",
                ...getColumnSearchProps("entryName", this.handleSearchName, this.handleReset, this.handleRenderTextColumnEntry, t, this.searchInput)
            },
            {
                title: t('setup.identity2entry.withAssignedEntriesTblFolderName'),
                dataIndex: "folderName",
                filters: isUndefined(this.state.data) ? null : Array.from(new Set(this.state.data.map(itm => itm.folderName))).map(
                    itmName => {
                        return { text: itmName, value: itmName };
                    }
                ),
                onFilter: (value, record) => record.entry.parent.name.indexOf(value) === 0,
            },
            {
                ...getColumnCustomAttributeProps(
                    "enabled",
                    t('setup.identity2entry.withAssignedEntriesTblEnabled'),
                    "boolean",
                    t
                )
            },
        ];
        let selectedRowKeys = this.state.selectedRowKeys
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,

        };
        let detailMap = null
        if (this.state.selectedMap) {
            detailMap = <Space direction="vertical" style={{ marginTop: '20px', marginLeft: '10px' }}>
                <Text >{t('setup.identity2entry.modalItemEntryType')} <EntryTypeTag entryTypeName={this.state.selectedMap.value.entryType} entryTypeNameList={entryTypeNameList}></EntryTypeTag> </Text>
                <Text>{t('setup.identity2entry.modalItemFolderName')}<span style={{ fontWeight: 'bold' }}>{this.state.folderName.name}</span></Text>
            </Space>
        }
        let modal = <Modal title={t('setup.identity2entry.modalTitle')} visible={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
            <div>
                <Select placeholder={t('setup.identity2entry.modalSelectPlaceholder')} style={{ width: "100%" }}
                    onChange={this.onChangeSelect}>
                    {options}
                </Select>
                {detailMap}
            </div>
        </Modal>
        if (!isUndefined(entryTypeNameList)) {
            const optionsEntry = this.state.searchedData.map(d =>
                <Option key={"search|#|" + d.id}>
                    <div>
                        <span>{d.name}</span>
                        <span style={{ float: 'right' }}><EntryTypeTag entryTypeName={d.type} entryTypeNameList={entryTypeNameList}></EntryTypeTag></span>
                    </div>
                </Option>
            );
            modalEditEntry = <Modal title={t('setup.identity2entry.withAssignedEntriesModalTitleEditEntry')} visible={this.state.modalEditEntry} onOk={this.handleOkEditEntry} onCancel={this.handleCancelEditEntry}>
                <div>
                    <Select
                        showSearch={true}
                        value={this.state.entry}
                        placeholder={t('setup.identity2entry.modalSearchPlaceholder')}
                        style={{ width: '100%' }}
                        defaultActiveFirstOption={false}
                        filterOption={false}
                        onSearch={this.handleSearch}
                        onChange={this.handleSearchChange}
                        notFoundContent={<span>{t('app.entry.attributes.boxRelationToFound')}</span>}
                        loading={this.state.isSearchBoxLoading}
                        suffixIcon={<SearchOutlined />}
                    >
                        {optionsEntry}
                    </Select>
                </div>
            </Modal>
        }



        return (
            <div>
                {modalEditEntry}
                {modal}
                <Alert
                    message={t('setup.identity2entry.withAssignedEntriesWarningMessage')}
                    description={t('setup.identity2entry.withAssignedEntriesWarningDescription')}
                    type="warning"
                    showIcon

                />

                <Table rowSelection={rowSelection} columns={columns} loading={isUndefined(this.state.data)} dataSource={this.state.data} scroll={{
                    x: true,
                }}
                    footer={() => <Button type="primary" key="button" onClick={this.showModal} disabled={this.state.selectedRowKeys.length > 0 ? false : true}>
                        {t('setup.identity2entry.btnMove')}
                    </Button>} />
            </div>
        );
    }
}

const WithAssignedEntriesTableEditRouter = withRouter(WithAssignedEntriesTable);

export default withTranslation()(WithAssignedEntriesTableEditRouter);