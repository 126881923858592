import React from "react";
import PropTypes from "prop-types";
import { Input, Checkbox, Card, Empty, InputNumber, Form, Spin, Select, Tag } from 'antd';
import {Link} from "react-router-dom";
import {isUndefined, isEmptyValue, isArray, isEmptyObject} from "../../../utils/JsObjectHelper";
import RelationAttributeTagCloud from '../../../containers/detail/RelationAttributeTagCloud';
import FileUrlArrayAttribute from './FileUrlArrayAttribute';
import { withTranslation} from 'react-i18next';
import moment from "moment";
import FormDatePicker from "../../controls/FormDatePicker";
import { getTextColorFromBackgroundHex } from "../../../utils/ColorHelper";

const cloneDeep = require('lodash.clonedeep');
const { Option } = Select;

class AttributesSectionDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {values: cloneDeep(props.relationData), attValues: cloneDeep(props.attributeValues)};
        this.onCloseTag = this.onCloseTag.bind(this);
        this.onAddTag = this.onAddTag.bind(this);
    }
    
    onCloseTag(relationName, entryId) {
        this.setState((prevState, props) => {
            /** @type RelationsHolder */
            let holder = cloneDeep(prevState.values);
            let replaceRelations = holder.find(x => { return x.name === relationName });
            replaceRelations.relatedEntries = replaceRelations.relatedEntries.filter(x => {
                return x.id !== entryId
            });
            this.props.onRelationChange(holder);
            return {values: holder};
        });
    };

    onAddTag(relationName, entry) {
        this.setState((prevState, props) => {
            /** @type RelationsHolder */
            let holder = cloneDeep(prevState.values);
            let replaceRelations = holder.find(x => { return x.name === relationName });
            if(isUndefined(replaceRelations)) {
                replaceRelations = {name: relationName, relatedEntries: [entry]};
                holder.push(replaceRelations);
            } else {
                replaceRelations.relatedEntries.push(entry);
            }
            this.props.onRelationChange(holder);
            return {values: holder};
        });
    };

    /**
     * Changed from deprecated componentWillReceiveProps
     * @param {*} prevProps 
     * @param {*} prevState 
     */
    componentDidUpdate(prevProps, prevState) {
        if (this.props.relationData !== prevProps.relationData) {
            this.setState({values: cloneDeep(this.props.relationData)});
        }

        if (this.props.attributeValues !== prevProps.attributeValues) {
            this.setState({attValues: cloneDeep(this.props.attributeValues)});
        }
    }

    render () {
        const {t, i18n} = this.props;

        let content = [];
        
        this.props.attributeIndexes.forEach(attID => {
            let att = this.props.attributeDefinitions[attID];
            if (!isUndefined(att) && !att.deleted) {
                let attValue = null;
                switch (att.type) {
                    case "relation":
                        let relations = this.props.relationData.find(e => {return e.name === att.techName});
                        if (isUndefined(relations) && this.props.isEditMode) {
                            relations = {name: att.techName, relatedEntries: []};
                        }
                        let relEntryTypes = att.relationEntryType;
                        if (!isArray(relEntryTypes)) {
                            relEntryTypes = [relEntryTypes];
                        }
                        let relEntryParents = att.relationEntryParent;
                        if (!isEmptyValue(relEntryParents) && !isArray(relEntryParents)) {
                            relEntryParents = [relEntryParents];
                        }
                        if (!isUndefined(relations)) {
/*console.log('PREPARING RELATIONS');
console.log(att);
console.log(relations);
console.log(isEmptyObject(this.props.attributesProperties));
console.log(this.props.attributesProperties);*/
                            content.push(
                                <RelationAttributeTagCloud 
                                    isEditMode={this.props.isEditMode}
                                    title={att.name}
                                    relationHolder={relations}
                                    entryType={relEntryTypes}
                                    entryParent={relEntryParents}
                                    onCloseTag={this.onCloseTag}
                                    onAddTag={this.onAddTag}
                                    key={att.techName}
                                    attributeTechName={att.techName}
                                    attributesProperties={this.props.attributesProperties}
                                >
                                </RelationAttributeTagCloud>
                            );
                        } else {
                            if(this.props.isLoadingRelations) {
                                content.push(<Spin key={att.techName} style={{width: '100%'}}></Spin>);
                            }
                        }
                        break;
                    case "boolean":
                        attValue = (!isUndefined(this.props.attributeValues) ? this.props.attributeValues[att.techName] : null);
                        if(this.props.isEditMode) {
                            let rules = [];
                            if (att.isRequired) {
                                rules.push({ required: true, message: t('app.entry.attributes.msgAttributeIsRequired', {att_name : att.name}) });
                            }
                            content.push(
                                this.addFormCheckBoxItem(att.techName, attValue, att.name, att.name, rules)
                            );
                        } else {
                            if (!isUndefined(attValue)) {
                                content.push(
                                    <div key={att.techName} className="ant-form-item">
                                        <div className="ant-form-item-label">
                                            <b>{att.name}</b>
                                        </div>
                                        <div>
                                            <Checkbox checked={attValue} disabled />
                                        </div>
                                    </div>
                                );
                            }
                        }
                        break;
                    case "date":
                        attValue = (!isUndefined(this.props.attributeValues) ? this.props.attributeValues[att.techName] : null);
                        if(this.props.isEditMode) {
                            let rules = [];
                            let dateValue = null;
                            if (att.isRequired) {
                                rules.push({ required: true, message: t('app.entry.attributes.msgAttributeIsRequired', {att_name : att.name}) });
                            }
                            content.push(
                                this.addFormDatePickerItem(att.techName, dateValue, att.name, att.name, rules, t("datetimeFormat"))
                            );
                        } else {
                            if (!isUndefined(attValue)) {
                                let dateValue = new Date(attValue);
                                let dateValFormatted = attValue; //use value as fallback option
                                //Check if date is valid
                                if (dateValue instanceof Date && !isNaN(dateValue)) {
                                    if (dateValue.getMinutes() === 0 && dateValue.getHours() === 0) {
                                        dateValFormatted = t('date', {date : dateValue});
                                    } else {
                                        dateValFormatted = t('datetime', {date : dateValue});
                                    }
                                }
                                content.push(
                                    <div key={att.techName} className="ant-form-item">
                                        <div className="ant-form-item-label">
                                            <b>{att.name}</b>
                                        </div>
                                        <div>
                                            {dateValFormatted}
                                        </div>
                                    </div>
                                );
                            }
                        }
                        break;
                    case "fileUrlArray":
                        attValue = (!isUndefined(this.state.attValues) ? this.state.attValues[att.techName] : null);
                        if (!isEmptyObject(attValue) || this.props.isEditMode) {
                            //pro edit
                            /*if (isEmptyObject(attValue)) {
                                attValue = [];
                            }*/
                            if(this.props.isEditMode) {
                                let rules = [];
                                if (att.isRequired) {
                                    rules.push({ required: true, message: t('app.entry.attributes.msgAttributeIsRequired', {att_name : att.name}) });
                                }
                                content.push(
                                    this.addFormFileArrayItem(att.techName, attValue, att.name, att.contentType, this.onFileAdded, this.onFileDeleted, rules)
                                );
                            } else {
                                content.push(
                                    <FileUrlArrayAttribute 
                                        key={att.techName}
                                        attName={att.techName}
                                        isEditMode={this.props.isEditMode}
                                        title={att.name}
                                        contentType={att.contentType}
                                        fileUrlArrayData={attValue}
                                    >
                                    </FileUrlArrayAttribute>
                                );
                            }


                        }
                        break;
                    case "number":
                        attValue = (!isUndefined(this.props.attributeValues) ? this.props.attributeValues[att.techName] : null);
                        if(this.props.isEditMode) {
                            let rules = [];
                            let minVal = -Infinity;
                            let maxVal = Infinity;
                            if(!isUndefined(att.minLength)) {
                                minVal = att.minLength;
                            }
                            if(!isUndefined(att.maxLength)) {
                                maxVal = att.maxLength;
                            }
                            if (att.isRequired) {
                                rules.push({ required: true, message: t('app.entry.attributes.msgAttributeIsRequired', {att_name : att.name}) });
                            }
                            content.push(
                                this.addFormInputNumberItem(i18n, att.techName, attValue, att.name, att.name, rules, minVal, maxVal)
                            );
                        } else {
                            if (!isUndefined(attValue) && !isEmptyValue(attValue)) {
                                let numberMark = null;
                                if(!isUndefined(att.displayFormat)) {
                                    numberMark = att.displayFormat;
                                }
                                content.push(
                                    <div key={att.techName} className="ant-form-item">
                                        <div className="ant-form-item-label">
                                            <b>{att.name}</b>
                                        </div>
                                        <div>
                                            {attValue.toLocaleString(i18n.language)} {numberMark}
                                        </div>
                                    </div>
                                );
                            }
                        }

                        break;
                    case "hyperlink":
                        attValue = (!isUndefined(this.props.attributeValues) ? this.props.attributeValues[att.techName] : null);
                        if(this.props.isEditMode) {
                            let rules = [];
                            if (att.isRequired) {
                                rules.push({ required: true, message: t('app.entry.attributes.msgAttributeIsRequired', {att_name : att.name}) });
                            }
                            content.push(
                                this.addHyperlinkItem(att.techName, att.name, t('app.entry.attributes.hyperlinkLabelPlaceholder'), t('app.entry.attributes.hyperlinkUrlPlaceholder'), rules)
                            );
                        } else {
                            if (!isUndefined(attValue) && !isEmptyValue(attValue) && !isEmptyValue(attValue.url)) {
                                let hyperlinkLabel = (!isEmptyValue(attValue.label) ? attValue.label : attValue.url);
                                content.push(
                                    <div key={att.techName} className="ant-form-item">
                                        <div className="ant-form-item-label">
                                            <b>{att.name}</b>
                                        </div>
                                        <div>
                                            <a href={attValue.url} target="_blank" rel="noopener noreferrer">{hyperlinkLabel}</a>
                                        </div>
                                    </div>
                                );
                            }
                        }
                        break;
                    case "lov":
                        attValue = (!isUndefined(this.props.attributeValues) ? this.props.attributeValues[att.techName] : null);
                        if(this.props.isEditMode) {
                            let rules = [];
                            if (att.isRequired) {
                                rules.push({ required: true, message: t('app.entry.attributes.msgAttributeIsRequired', {att_name : att.name}) });
                            }
                            let optionsLoV = att.lovArray.map(lov => <Option value={lov.value} key={lov.value}>{lov.name}</Option>);
                            content.push(
                                this.addFormSelectItem(att.techName, optionsLoV, att.name, t('app.entry.attributes.lovPlaceholder'), rules)
                            );
                        } else {
                            if (!isUndefined(attValue) && !isEmptyValue(attValue)) {
                                let dispValue = (
                                        !isUndefined(att.lovArray.find(lov => lov.value === attValue)) ? 
                                        att.lovArray.find(lov => lov.value === attValue).name : 
                                        attValue
                                    );
                                let dispColor = (
                                        !isUndefined(att.lovArray.find(lov => lov.value === attValue)) ? 
                                        att.lovArray.find(lov => lov.value === attValue).color : 
                                        attValue
                                    );
                                let tagTextColor = getTextColorFromBackgroundHex(dispColor);
                                
                                content.push(
                                    <div key={att.techName} className="ant-form-item">
                                        <div className="ant-form-item-label">
                                            <b>{att.name}</b>
                                        </div>
                                        <div>
                                            <Tag color={dispColor} style={{color:tagTextColor}}>{dispValue}</Tag>
                                        </div>
                                    </div>
                                );
                            }
                        }
                        break;
                    default:
                        attValue = (!isUndefined(this.props.attributeValues) ? this.props.attributeValues[att.techName] : null);
                        if(this.props.isEditMode) {
                            let rules = [];
                            if (att.isRequired) {
                                rules.push({ required: true, message: t('app.entry.attributes.msgAttributeIsRequired', {att_name : att.name}) });
                            }
                            content.push(
                                this.addFormInputItem(att.techName, attValue, att.name, att.name, rules)
                            );
                        } else {
                            if (!isUndefined(attValue) && !isEmptyValue(attValue)) {
                                content.push(
                                    <div key={att.techName} className="ant-form-item">
                                        <div className="ant-form-item-label">
                                            <b>{att.name}</b>
                                        </div>
                                        <div>
                                            {attValue}
                                        </div>
                                    </div>
                                );
                            }
                        }

                        break;
                }

            }
        });
        
        if (content.length > 0) {
            return <Card title={this.props.sectionName}>{content}</Card>;
        } else {
            let cardContent = <span>{t('app.entry.attributes.msgAskOwnerToFill')}</span>;
            if (this.props.hasDraft) {
                cardContent = <span>{t('app.entry.attributes.msgCannotEditHasDraft')}</span>;
            } else if (this.props.showEditPrompt) {
                cardContent = <span><Link to={`/entry/${this.props.entryID}/edit`}>{t('app.entry.attributes.btnFillInformation')} </Link> {t('app.entry.attributes.msgFillInformation')}</span>;
            }

            return <Card title={this.props.sectionName} className='attSectionEmpty4Print'>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                        cardContent
                    }/>
                </Card>;
        }

    }

    /**
     *
     * @param {String} name
     * @param {Object} initialValue
     * @param {String} label
     * @param {String} placeholder
     * @param {Array<Object>} rules
     * @return {*}
     */
    addFormInputItem(name, initialValue = null, label = null, placeholder = '', rules = []) {
        return <Form.Item key={name} label={label} 
                    name={["properties", name]}
                    rules={rules}
            >
                <Input placeholder={placeholder} /*style={{ width: 300 }}*/ allowClear={true} />
            </Form.Item>;
    }



    addFormDatePickerItem(name, initialValue = null, label = null, placeholder = '', rules = [], displayFormat = "YYYY-MM-DD HH:mm:ss") {
        const timeOpts = {allowClear:true, defaultValue:moment("00:00:00.0", "HH:mm:ss.S")};
        return <Form.Item key={name} label={label} 
                    name={["properties", name]}
                    rules={rules}
            >
                <FormDatePicker showTime={timeOpts} format={displayFormat}></FormDatePicker>
            </Form.Item>;
    }

    /**
     * 
     * @param {i18n instance} i18n 
     * @param {String} name
     * @param {Object} initialValue
     * @param {String} label
     * @param {String} placeholder
     * @param {Array<Object>} rules
     * @param {Number} inputWidth
     * @param {Boolean} isDisabled 
     * @returns 
     */
     addFormInputNumberItem(i18n, name, initialValue = null, label = null, placeholder = '', rules = [], minVal = -Infinity, maxVal = Infinity) {
        return <Form.Item key={name} label={label} 
                    name={["properties", name]}
                    rules={rules}
            >
                <InputNumber
                    style={{minWidth : '100px', maxWidth: '300px', width: 'auto'}}
                    min={minVal} max={maxVal} 
                    decimalSeparator={(1.1).toLocaleString(i18n.language).replace(/1/g,'')} />
            </Form.Item>;
    }

    /**
     *
     * @param {String} name
     * @param {Object} initialValue
     * @param {String} label
     * @param {String} placeholder
     * @param {Array<Object>} rules
     * @return {*}
     */
    addFormCheckBoxItem(name, initialValue = null, label = null, placeholder = '', rules = []) {
        return <Form.Item key={name} shouldUpdate>
                {() => {return (
                    <Form.Item key={name} label={label} 
                            name={["properties", name]}
                            rules={rules}
                            valuePropName="checked"
                    >
                        <Checkbox placeholder={placeholder} 
                            indeterminate={
                                (
                                    isUndefined(this.props.formRef.current) ||
                                    (
                                        !isUndefined(this.props.formRef.current) && 
                                        isUndefined(this.props.formRef.current.getFieldValue(["properties", name]))
                                    )
                                )
                            } 
                            />
                    </Form.Item>
                )}}
            </Form.Item>;
    }

    /**
     *
     * @param {String} name
     * @param {Array<Option>} options
     * @param {String} label
     * @param {String} placeholder
     * @param {Array<Object>} rules
     * @param {Number} inputWidth
     * @param {String} inputMode
     * @return {*}
     */
     addFormSelectItem(name, options, label = null, placeholder = '', rules = [], inputWidth = 300, inputMode = null) {
        return <Form.Item key={name} label={label}
            name={["properties", name]}
            rules={rules}
        >
            <Select placeholder={placeholder} style={{ width: inputWidth }} mode={inputMode} optionFilterProp="children">
                {options}
            </Select>
        </Form.Item>;
    }

    addFormFileArrayItem(name, initialValue = null, label = null, contentType = null, fileAddMethod, fileDeletedMethod, rules = []) {
        return <Form.Item key={name} label={label}
                    name={["properties", name]}
                    rules={rules}
                    valuePropName="fileUrlArrayData"
            > 
                <FileUrlArrayAttribute 
                    key={name}
                    attName={name}
                    isEditMode={true}
                    title={label}
                    contentType={contentType}
                    //fileUrlArrayData={initialValue}
                >
                </FileUrlArrayAttribute>
            </Form.Item>;
    }

    /**
     * 
     * @param {String} name 
     * @param {String} label 
     * @param {String} placeholderLabel 
     * @param {String} placeholderUrl 
     * @param {Array<Object>} rules 
     * @returns 
     */
    addHyperlinkItem(name, label, placeholderLabel = '', placeholderUrl = '', rules = []) {
        return <Form.Item label={label}>
                <Input.Group compact>
                    <Form.Item
                    name={['properties', name, 'label']}
                    noStyle
                    >
                        <Input style={{ width: '40%' }} placeholder={placeholderLabel} />
                    </Form.Item>
                    <Form.Item
                    name={['properties', name, 'url']}
                    noStyle
                    rules={rules}
                    >
                        <Input style={{ width: '60%' }} placeholder={placeholderUrl} />
                    </Form.Item>
                </Input.Group>
            </Form.Item>;
    }
};

export default withTranslation() (AttributesSectionDetail);

AttributesSectionDetail.propTypes = {
    isEditMode: PropTypes.bool.isRequired,
    onRelationChange: PropTypes.func.isRequired,
    sectionName: PropTypes.string.isRequired,
    showEditPrompt: PropTypes.bool,
    formRef: PropTypes.any,
};