export const EMPTY_SETTINGS = {
    id: null,
    name: null,
    value: null
};

export const EMPTY_SETTINGS_LINEAGE = {
    lineageName: null,
    entryTypes: [],
    hierarchy: [],
    isDisplayed: true
};

export const EMPTY_SETTINGS_LINEAGE_HIERARCHY = {
    parent: null,
    child: null,
    relation: null,
    doExpandParent: false
};

export const EMPTY_SETTINGS_WORKFLOW = {
    workflowName: null,
    workflowDescription: null,
    bpmnKey: null,
    workflowUseType: null,
    entryTypes: [],
    groups: [],
    properties: {},
    dynamicMapping: [],
    staticMapping : [],
    isEnabled: true,
    objectType: "entry"
};
export const EMPTY_SETTINGS_ID_MAPPING = {
    idMapName: null,
    entryType: null,
    folderId:  null,
};

export const SETTINGS_WORKFLOW_USETYPE_ONSAVE = "onSave";
export const SETTINGS_WORKFLOW_USETYPE_ONRUNBYUSER = "onRunByUser";

export const SETTINGS_WORKFLOW_USETYPES = {
    onSave : "setup.workflowSetup.useTypeOnSave",
    onRunByUser : "setup.workflowSetup.useTypeOnRunByUser",
};