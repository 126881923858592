import AdvancedSearchFormPresenter from '../../components/search/AdvancedSearchForm';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {fetchObjectList} from "../../actions/objectList";
import { fetchNewUserCustomizations } from '../../apicalls/fetchNewUserCustomizations';
import { fetchUserCustomizations } from '../../actions/userCustomizations';

const getEntryTypeNameList = (objectList) => {
    let entryTypeNameList = [];

    if (objectList.getState().isDone()) {
        entryTypeNameList = objectList.getData().map((item, key) => { return {type: item.type, name: item.name, color: item.properties.typeColor};});
    }

    return entryTypeNameList;
};

const mapStateToProps = (state, ownProps) => {
    return {
        entryTypeNameList: getEntryTypeNameList(state.objectList),
        entryTypeRequestResult: state.objectList,
        propData: ownProps.propData,
        user: state.loggedUser.user
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount() {
            dispatch(fetchObjectList());
        },
        saveNewUserCustomization: (settings,callback) =>{
           let reload = (json)=>{
            dispatch(fetchUserCustomizations(true));
            callback(json);
        };
           fetchNewUserCustomizations(settings, (json) => reload(json));
        }
    }
};

const AdvancedSearchForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdvancedSearchFormPresenter);

export default withRouter(AdvancedSearchForm);