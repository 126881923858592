import React from "react";
import PropTypes from "prop-types";
import {bmcEntryShape, folderPropShape} from "../../../shapes/BmcEntryShape";
import { entryTypesRequestResult } from "../../../shapes/RequestResult";
import { isEmptyValue, isUndefined } from "../../../utils/JsObjectHelper";
import EntryChildTable from './EntryChildTable';
import EntryChildHierarchy from '../../../containers/detail/EntryChildHierarchy';
import { withTranslation} from 'react-i18next'
import { Radio } from "antd";
import { TableOutlined, ForkOutlined } from '@ant-design/icons';

const HierarchyTitle = ({translator, value, onChangeEvent}) => (
    <div>
        <span>{translator('app.entry.view.tblEntriesTitle')}</span>
        <Radio.Group onChange={onChangeEvent} value={value} style={{float:"right"}}>
            <Radio value="table"><TableOutlined title={translator('app.entry.edit.lblChildrensDisplayTypeTable')}/></Radio>
            <Radio value="hierarchy"><ForkOutlined title={translator('app.entry.edit.lblChildrensDisplayTypeHierarchy')}/></Radio>
        </Radio.Group>
    </div>
);

const TableTitle = ({translator, value, onChangeEvent}) => (
    <div className="childTableTitle">
        <span>{translator('app.entry.view.tblEntriesTitle')}</span>
        <Radio.Group onChange={onChangeEvent} value={value} style={{float:"right"}}>
            <Radio value="table"><TableOutlined title={translator('app.entry.edit.lblChildrensDisplayTypeTable')}/></Radio>
            <Radio value="hierarchy"><ForkOutlined title={translator('app.entry.edit.lblChildrensDisplayTypeHierarchy')}/></Radio>
        </Radio.Group>
    </div>
);

class EntryChildSection extends React.Component {

    state = {
        typeValue: (!isUndefined(this.props.folderProperties) && !isEmptyValue(this.props.folderProperties.childrenDisplayType) ? this.props.folderProperties.childrenDisplayType : "table"),
    }

    handleTypeChange = (e) => {
        this.setState({typeValue : e.target.value});
    };

    render() {
        const {t} = this.props;

        switch (this.state.typeValue) {
            case "hierarchy":
                return <EntryChildHierarchy 
                            childsDataList={this.props.childsDataList} 
                            folderProperties={this.props.folderProperties}
                            entryTypesRequestResult={this.props.entryTypesRequestResult}
                            cardTitle={<HierarchyTitle translator={t} value={this.state.typeValue} onChangeEvent={this.handleTypeChange}></HierarchyTitle>}
                            ></EntryChildHierarchy>;
            case "table":
            default:
                return <EntryChildTable 
                            childsDataList={this.props.childsDataList} 
                            folderProperties={this.props.folderProperties}
                            entryTypesRequestResult={this.props.entryTypesRequestResult}
                            tableTitle={<TableTitle translator={t} value={this.state.typeValue} onChangeEvent={this.handleTypeChange}></TableTitle>}
                            ></EntryChildTable>;
            
        }
    }
}

export default withTranslation() (EntryChildSection);

EntryChildSection.propTypes = {
    childsDataList: PropTypes.arrayOf(bmcEntryShape).isRequired,
    folderProperties: folderPropShape.isRequired,
    entryTypesRequestResult: entryTypesRequestResult.isRequired
};