import React from "react";
import { withRouter } from "react-router-dom";
import { Table, Tag, Alert, Collapse, Button, Spin, Checkbox, Badge } from 'antd';
import { withTranslation } from 'react-i18next'
import { isArray, isUndefined } from "../../utils/JsObjectHelper";
import { getAttributeTypeIcon } from "../../utils/EntryTypeHelper";
import { fetchEntries } from "../../apicalls/fetchEntries"
import { getColumnCustomAttributeProps } from "../../utils/TableHelper";
import { fetchUseEntryTypeInDashboard } from "../../apicalls/deleteEntryType/fetchUseEntryTypeInDashboard";
import { fetchUseEntryTypeInFolder } from "../../apicalls/deleteEntryType/fetchUseEntryTypeInFolder";
import { fetchDeleteEntryType } from "../../apicalls/deleteEntryType/fetchDeleteEntryType";
import { notificationError, notificationSuccess } from "../../utils/NotificationsHelper";
const { Panel } = Collapse;

class ObjectDelete extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			searchNameText: [],
			isModalVisible: false,
			entryListData: [],
			update: true,
			checkBox: false,
			customization: [],
			folders: [],
		};

	}
	componentDidMount() {
		this.props.onMount();
		fetchEntries('*', 'deleteEntryType', data => this.dataEntryList(data), this.props.entryType);
		fetchUseEntryTypeInDashboard(this.props.entryType, data => this.setState({ customization: data }));
		fetchUseEntryTypeInFolder(this.props.entryType, data => this.setState({ folders: data }));

	}
	componentDidUpdate(prevProps, prevState) {
		if ((prevProps.showModalCount < this.props.showModalCount)) {
			this.props.onShowChanged();
			fetchEntries('*', 'deleteEntryType', data => this.dataEntryList(data), this.props.entryType);
			fetchUseEntryTypeInDashboard(this.props.entryType, data => this.setState({ customization: data }));
			fetchUseEntryTypeInFolder(this.props.entryType, data => this.setState({ folders: data }));
		}
	}
	cancel() {
		this.props.closeModal()
	}
	delete() {
		const { t } = this.props;
			fetchDeleteEntryType(this.props.entryType, (err) => {
				if(err){
					notificationError(t('setup.entryTypes.deleteEntryType.notificationErrorDelete'))
				}
				else{
					this.props.updateObjectList()
					this.props.refresh(this.props.entryType)
					notificationSuccess(t('setup.entryTypes.deleteEntryType.notificationSuccessDelete'))
				}
			
			});
	}
	dataEntryList(data) {
		this.setState({ entryListData: data, update: false })
	}
	onChange(e) {
		let s = e.target.checked
		this.setState({ checkBox: s })
	}

	render() {
		const { t } = this.props;
		let dataEntryType = [];
		if (this.props.EntryTypeRequestResult.getData() && this.props.entryType) {
			this.props.EntryTypeRequestResult.getData().forEach((entryType) => {
				let relace = entryType.properties.attributes.filter((attribute) => attribute.type === 'relation')
				let useEntryType = relace.filter((el) => isArray(el.relationEntryType) ? el.relationEntryType.find((e) => e === this.props.entryType) : el.relationEntryType === this.props.entryType)
				if (useEntryType.length > 0) {
					dataEntryType.push({ entryTypeName: entryType.name, id: entryType.id, attributs: useEntryType })
				}
			});
		};
		let columsEntryType = [
			{
				title: t('setup.entryTypes.deleteEntryType.tableRelationSettingsName'),
				key: 'name',
				dataIndex: 'entryTypeName',
				render: (text, record) => <a href={"/setup/contentedit/" + record.id} target="blank" >{text}</a>
			},
			{
				title: t('setup.entryTypes.deleteEntryType.tableRelationSettingsAttributeName'),
				key: 'attributeName',
				render: (tags, record) =>
					<span>
						{record.attributs.map(tag => (
							<Tag color="blue" key={tag.name}>
								{getAttributeTypeIcon(tag.type)} {tag.name}
							</Tag>
						))}
					</span>
			}

		]
		let panelRelation = null;
		if (dataEntryType.length > 0) {
			panelRelation = <Panel header={<><span >{t('setup.entryTypes.deleteEntryType.panelRelationSettings')}</span><Badge count={dataEntryType.length} offset={[20, 12]}> </Badge> </>} key="1">
				   <Alert style={{marginBottom:'10px'}} message={t('setup.entryTypes.deleteEntryType.infoAlertRelationSettings')} type="info" showIcon />
				<Table
					loading={!this.props.EntryTypeRequestResult.getData()}
					columns={columsEntryType}
					dataSource={dataEntryType}
					rowKey="id"
					size="small"
					pagination={false}
					scroll={{
						x: true,
					  }}
				/>
			</Panel>
		}


		let dataWorkflow = [];
		if (this.props.workflow && this.props.entryType) {
			this.props.workflow.forEach((w) => { w.value.entryTypes.forEach((entry) => entry === this.props.entryType ? dataWorkflow.push(w) : null) });
		}
		let columsWorkflow = [
			{
				title: t('setup.entryTypes.deleteEntryType.tableWorkflowSettings'),
				key: 'name',
				dataIndex: ['value', 'workflowName'],
				render: (text, record) => <a href={"/setup/workflowedit/" + record.name} target="blank" >{text}</a>
			}
		]
		let panelWorkflow = null;
		if (dataWorkflow.length > 0) {
			panelWorkflow = <Panel header={<><span >{t('setup.entryTypes.deleteEntryType.panelWorkflowSettings')}</span><Badge count={dataWorkflow.length} offset={[8, 3]}></Badge></>} key="2">
				 <Alert style={{marginBottom:'10px'}}  message={t('setup.entryTypes.deleteEntryType.infoAlertWorkflowSettings')} type="info" showIcon />
				<Table
					loading={!this.props.workflow}
					columns={columsWorkflow}
					dataSource={dataWorkflow}
					rowKey="name"
					size="small"
					pagination={false}
					scroll={{
						x: true,
					  }}
				/>
			</Panel>
		}

		let dataLineage = [];
		if (this.props.lineage && this.props.entryType) {
			this.props.lineage.forEach((w) => { 
				let useEntryType =null;
				let useHierarchy =null;
				w.value.entryTypes.forEach((entry) => entry === this.props.entryType ? useEntryType=true : null);
				w.value.hierarchy.forEach((hierarchy)=>hierarchy.parent === this.props.entryType || hierarchy.child === this.props.entryType? useHierarchy = true : null );
				if(useEntryType || useHierarchy  ){
					w.useDefinitions = useHierarchy ? true : false;
					w.useEntryType = useEntryType? true : false;
					dataLineage.push(w)
				}
			});
		}
		let columsLineage = [
			{
				title: t('setup.entryTypes.deleteEntryType.tableLineageSettings'),
				key: 'name',
				dataIndex: ['value', 'lineageName'],
				render: (text, record) => <a href={"/setup/lineageedit/" + record.name} target="blank" >{text}</a>
			},
			{
				...getColumnCustomAttributeProps(
					"useDefinitions",
					t('setup.entryTypes.deleteEntryType.tableLineageSettingsDefinitions'),
					"boolean",
					t
				)
			
			},
			{
				...getColumnCustomAttributeProps(
					"useEntryType",
					t('setup.entryTypes.deleteEntryType.tableLineageSettingsEntryType'),
					"boolean",
					t
				)
			
			},
		]
		let panelLineage = null;
		if (dataLineage.length > 0) {
			panelLineage = <Panel header={<><span>{t('setup.entryTypes.deleteEntryType.panelLineageSettings')}</span> <Badge count={dataLineage.length} offset={[8, 3]}></Badge></>} key="3">
				 <Alert style={{marginBottom:'10px'}}  message={t('setup.entryTypes.deleteEntryType.infoAlertLineageSettings')} type="info" showIcon />
				<Table
					loading={!this.props.lineage}
					columns={columsLineage}
					dataSource={dataLineage}
					rowKey="name"
					size="small"
					pagination={false}
					scroll={{
						x: true,
					  }}
				/>
			</Panel>
		};


		let dataIdMapping = [];
		if (this.props.idMapping && this.props.entryType) {
			this.props.idMapping.forEach((idMap) => idMap.value.entryType === this.props.entryType ? dataIdMapping.push(idMap) : null);
		}
		let columsIdMapping = [
			{
				title: t('setup.entryTypes.deleteEntryType.tableIdMappingSettings'),
				key: 'name',
				dataIndex: ['value', 'idMapName'],
				render: (text, record) => <a href={"/setup/idmappingedit/" + record.name} target="blank" >{text}</a>
			}
		]
		let panelIdMapping = null;
		if (dataIdMapping.length > 0) {
			panelIdMapping = <Panel header={<><span>{t('setup.entryTypes.deleteEntryType.panelIdMapping')}</span> <Badge count={dataIdMapping.length} offset={[8, 3]}></Badge></>} key="4">
				<Alert style={{marginBottom:'10px'}}  message={t('setup.entryTypes.deleteEntryType.infoAlertIdMappingsSettings')} type="info" showIcon />
				<Table
					loading={!this.props.idMapping}
					columns={columsIdMapping}
					dataSource={dataIdMapping}
					rowKey="name"
					size="small"
					pagination={false}
					scroll={{
						x: true,
					  }}
				/>
			</Panel>
		}
		let columsEntryList = [
			{
				title: t('setup.entryTypes.deleteEntryType.tableEntryListName'),
				key: 'name',
				dataIndex: 'name',
				render: (text, record) => <a href={"/entry/" + record.id} target="blank" >{text}</a>
			},
			{
				...getColumnCustomAttributeProps(
					"inserted",
					t('setup.entryTypes.deleteEntryType.tableEntryListCreated'),
					"date",
					t
				)

			},
			{
				...getColumnCustomAttributeProps(
					"updated",
					t('setup.entryTypes.deleteEntryType.tableEntryListUpdated'),
					"date",
					t
				)

			}
		]
		let panelEntryList = null;
		if (this.state.entryListData.length > 0) {
			panelEntryList = <Panel header={<><span>{t('setup.entryTypes.deleteEntryType.panelActiveEntries')}</span> <Badge count={this.state.entryListData.length} offset={[8, 3]}></Badge></>} key="5">
					<Alert style={{marginBottom:'10px'}}  message={t('setup.entryTypes.deleteEntryType.infoAlertActiveEntries')} type="info" showIcon />
				<Table
					columns={columsEntryList}
					dataSource={this.state.entryListData}
					rowKey="id"
					size="small"
					pagination={false}
					scroll={{
						x: true,
					  }}
				/>
			</Panel>
		}
		let alertCustomization = null;
		if (this.state.customization.length > 0) {
			let lineageCount = this.state.customization[0].count;
			let searchCount = this.state.customization[1].count;
			if (lineageCount > 0 || searchCount > 0) {
				alertCustomization = <Alert
					style={{ marginTop: '10px' }}
					message={t('setup.entryTypes.deleteEntryType.warningForUserCustomizationTitle')}
					description={<><div>{t('setup.entryTypes.deleteEntryType.warningForUserCustomizationDescription', { lineageCount: lineageCount, searchCount: searchCount })}</div>
						<ul style={{listStyleType:"none",marginLeft:0,paddingLeft:0}}>
							<li><Badge count={lineageCount}  showZero={true} offset={[-5, 0]}/>User saved lineages </li>
							<li><Badge count={searchCount}  offset={[-5, 0]}/>User saved queries </li>
						</ul>

					</>}
					type="warning"
					action={
						<Checkbox checked={this.state.checkBox} onChange={(e) => this.onChange(e)}>{t('setup.entryTypes.deleteEntryType.checkboxLable')}</Checkbox>
					}
				/>
			}
		}
		let columsFolders = [
			{
				title: t('setup.entryTypes.deleteEntryType.tableRelationSettingsName'),
				key: 'name',
				dataIndex: 'name',
				render: (text, record) => <a href={`/entry/${record.id}/edit`} target="blank" >{text}</a>
			}

		]
		let panelFolders = null;
		if (this.state.folders.length > 0) {
			panelFolders = <Panel header={<><span>{t('setup.entryTypes.deleteEntryType.panelFolder')}</span> <Badge count={this.state.folders.length} offset={[8, 3]}></Badge></>} key="6">
				<Alert style={{marginBottom:'10px'}}  message={t('setup.entryTypes.deleteEntryType.infoAlertFoldersSettings')} type="info" showIcon />
				<Table
					columns={columsFolders}
					dataSource={this.state.folders}
					rowKey="name"
					size="small"
					pagination={false}
					scroll={{
						x: true,
					  }}
				/>
			</Panel>
		};

		let main = <Spin size="large" spinning={this.state.update} />
		let disabledBtn = true;
		if (!this.state.update) {
			main = <>
				<Alert
					style={{ marginBottom: '10px' }}
					message={t('setup.entryTypes.deleteEntryType.warningTitle')}
					description={t('setup.entryTypes.deleteEntryType.warningDescription')}
					type="error"
				/>
				<Collapse accordion>
					{panelEntryList}
					{panelRelation}
					{panelWorkflow}
					{panelLineage}
					{panelIdMapping}
					{panelFolders}
				</Collapse>
				{alertCustomization}

			</>
		}
		if (!panelRelation && !panelWorkflow && !panelLineage && !panelIdMapping && !panelEntryList && !panelFolders && this.state.checkBox) {
			disabledBtn = false;
		}
		if (!panelRelation && !panelWorkflow && !panelLineage && !panelIdMapping && !panelEntryList && !panelFolders && !alertCustomization) {/////////////////////////////////////////////////
			main = (<Alert
				message={t('setup.entryTypes.deleteEntryType.messageDeleteAllowedTitle')}
				//description="Error Description Error Description Error Description Error Description"
				type="success"
			/>);
			disabledBtn = false;

		}
		return (
			<div>
				{main}
				<div style={{ display: 'flex', justifyContent: 'end', marginTop: '50px' }}>
					<Button type="primary" onClick={() => this.cancel()} style={{ marginRight: '5px' }}>{t('setup.entryTypes.deleteEntryType.modalButtonCancel')}</Button>
					<Button disabled={disabledBtn} type="primary" danger onClick={() => this.delete()}>{t('setup.entryTypes.deleteEntryType.modalButtonDelete')}</Button>
				</div>
			</div>
		);
	}
}

const ObjectDeleteWithRouter = withRouter(ObjectDelete);

export default withTranslation()(ObjectDeleteWithRouter);