import EntryEditPresenter from '../../components/detail/EntryEdit';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {saveEditedValues} from "../../actions/entryDetail";
import {isArray, isEmptyObject, isEmptyValue, isUndefined} from "../../utils/JsObjectHelper";
import {wsMessagePublish} from "../../modules/websocket/actions";

/** @type {RelationsHolder} */
const EMPTY_INCOMING = {type: "incoming", relations: []};
/** @type {RelationsHolder} */
const EMPTY_OUTGOING = {type: "outgoing", relations: []};

/**
 *
 * @param {RequestResult} rr
 * @param {String} type incoming or outgoing
 */
const getRelations = (rr, type) => {
    if(!isUndefined(rr) && rr.getState().isDone()) {
        /** @type {Array<IncomingRelation|OutgoingRelation>} */
        let relations = rr.getData();
        if (!isUndefined(relations) && isArray(relations) && relations.length > 0 &&
            (type === "incoming" || type === "outgoing")
        ) {
            /** @type {RelationsHolder} */
            let result = {
                type: type,
                relations: []
            };
            let property = (type === "incoming") ? "source" : "target";
            for (const relation of relations) {
                /** @type {RelationHolder} */
                let relHolder = result.relations.find(x => x.name === relation.name);
                if (isUndefined(relHolder)) {
                    relHolder = {name: relation.name, relatedEntries: [relation[property]]};
                    result.relations.push(relHolder);
                } else {
                    relHolder.relatedEntries.push(relation[property]);
                }
            }
            return result;
        }
    }
    return (type === "incoming") ? EMPTY_INCOMING : EMPTY_OUTGOING;
};

const mapStateToProps = (state, ownProps) => {
    return {
        entryRequestResult: state.entryDetail,
        incomingRelationsRequestResult: state.entryDetailIncomingRelations,
        outgoingRelationsRequestResult: state.entryDetailOutgoingRelations,
        incomingRelationsHolder: getRelations(state.entryDetailIncomingRelations, "incoming"),
        outgoingRelationsHolder: getRelations(state.entryDetailOutgoingRelations, "outgoing"),
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSaveEdit(entryId, values) {
            dispatch(saveEditedValues(entryId, values));
        },
        sendEditEvent(entryId) {
            dispatch(wsMessagePublish({destination: "/app/entry/editing", body: JSON.stringify({id: entryId})}))
        },
        sendCancelEditEvent(entryId) {
            dispatch(wsMessagePublish({destination: "/app/entry/cancelEditing", body: JSON.stringify({id: entryId})}))
        }
    }
};

const EntryEdit = connect(
    mapStateToProps,
    mapDispatchToProps
)(EntryEditPresenter);

export default withRouter(EntryEdit);