import React from "react";
import "antd/dist/antd.css";
import { PageHeader, Button,  Tabs, Spin, Typography,Skeleton } from "antd";
import { isUndefined } from "../../utils/JsObjectHelper";
import { withTranslation } from "react-i18next";
import { UserOutlined, CloseOutlined, } from "@ant-design/icons";
import FormBpmn from "./Form";
import AdminPage from "../../containers/bpmn/AdminPage";

const { Title } = Typography;
const { TabPane } = Tabs;

class TaskDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {

		};
		this.clickComplete = this.clickComplete.bind(this);
	}
	clickComplete(variables) {
		if (variables) {
			this.props.complete(this.props.taskData.id, variables);
			this.props.closeDrawer()
			// this.props.tabKey('2');
		}
	}
	clickClaim(taskid) {
		const { t } = this.props;
		this.props.claim(taskid, t("app.bpmn.claimError"));
		//this.props.tabKey('2');
	}
	unClaim(taskid) {
		this.props.unClaim(taskid);
	}
	render() {
		const { t } = this.props;
		let taskDetail = isUndefined(this.props.taskData) ? [] : this.props.taskData;
		let formData = isUndefined(this.props.formData) ? [] : this.props.formData;
		let todoData = isUndefined(this.props.todoData) ? [] : this.props.todoData;
		let showForm = todoData.find((t) => this.props.taskid === t.id) ? false : true
		let adminPanel = null
		if(this.props.showAdminPanel){
			adminPanel = <AdminPage taskId={this.props.taskid} dueDate={taskDetail.due} taskDetail={taskDetail} />
		}
		let description =null
		{if(!isUndefined(taskDetail.description)){
			description =	<TabPane tab={t("app.bpmn.descriptions")} key="2">
				{taskDetail.description}
			  </TabPane> 
			}}
		let Taby = (
			<Tabs defaultActiveKey="1">
				<TabPane tab={t("app.bpmn.form")} key="1">
					<Spin spinning={isUndefined(this.props.formData)}>

						<FormBpmn
							data={formData}
							variables={this.props.Variables}
							complete={this.clickComplete}
							showForm={showForm}
							showOk={true}
						/>
					</Spin>
				</TabPane>
				{description}
				
				{/* <TabPane tab={t("app.bpmn.histori")} key="3">
          Histori
        </TabPane>
        <TabPane tab={t("app.bpmn.diagram")} key="4">
          Diagram
        </TabPane> */}
			</Tabs>
		);
		let Header = (
			<PageHeader key="pageHeder" ghost={false} title={taskDetail.name}
				extra={this.props.showAdminPanel?null:[
					todoData.find((t) => t.id === taskDetail.id) ? (
						<>
							<Title level={5}>{this.props.User}</Title>
							<Button
								onClick={() => this.unClaim(taskDetail.id)}
								type="text"
								style={{ color: "#003a8c", fontWeight: "bold" }}
								key="1"
							>
								<CloseOutlined />
							</Button>
						</>
					) : (
						<Button
							onClick={() => this.clickClaim(taskDetail.id)}
							type="text"
							style={{ color: "#003a8c", fontWeight: "bold" }}
							key="2"
							disabled={formData === "No form"? true : false}
						>
							<UserOutlined /> {t("app.bpmn.claim")}
						</Button>
					)

				]}
			>
				{adminPanel}
				{Taby}
			</PageHeader>
		);

		return <div><Skeleton style={{marginLeft: '30px',width:'95%'}} loading={taskDetail.length < 1} >  {Header}	</Skeleton></div>;
	}
}

export default withTranslation()(TaskDetail);
