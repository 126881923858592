import React from "react";
import PropTypes from "prop-types";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { Responsive, WidthProvider } from 'react-grid-layout';
import {Divider, Tag, Card, Input, Row, Col, Button, Select, Checkbox, InputNumber} from "antd";
import { CopyOutlined } from '@ant-design/icons';
// import HTML5Backend from 'react-dnd-html5-backend'
// import {DndProvider} from 'react-dnd'
import DraggableTag from "../draggable/DraggableTag";
import DroppableDiv from "../draggable/DroppableDiv";
//import update from 'immutability-helper';
//import {isUndefined} from "../../utils/JsObjectHelper";

import { withTranslation} from 'react-i18next'
import { isEmptyValue, isUndefined } from "../../utils/JsObjectHelper";
import { 
    ATTRIBUTE_DISPLAYTYPE_OPTIONS_EDIT, 
    ATTRIBUTE_DISPLAYTYPE_OPTIONS_VIEW, 
    ATTRIBUTE_PROPERTYTYPE_DISPLAYTYPE, 
    ATTRIBUTE_PROPERTYTYPE_RELATIONTABLEATTRIBUTES,
    ATTRIBUTE_PROPERTYTYPE_USE_CONTEXTENGINE,
    ATTRIBUTE_PROPERTYTYPE_CONTEXTENGINE_DEPTH
} from "../../utils/EmptyObjectDefinitions";
import { entryTypesRequestResult } from "../../shapes/RequestResult";
import { getAttributeTypeIcon, getEntryTypeAttributesSelectOptions } from "../../utils/EntryTypeHelper";

const { Option } = Select;

const ResponsiveGridLayout = WidthProvider(Responsive);
//const cloneDeep = require('lodash.clonedeep');

const EMPTY_STATE = {
    sectionSelectIndex : -2,
    sectionSelectName : "",
    sectionSelectAttributes: []
};

class ObjectEditLayout extends React.Component {
    constructor(props) {
        super(props);

        this.state = EMPTY_STATE;

        this.onDragStartTag = this.onDragStartTag.bind(this);
        this.onDragEndTag = this.onDragEndTag.bind(this);
        this.onTagDropped = this.onTagDropped.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.onLayoutChange = this.onLayoutChange.bind(this);
        this.onSectionSelect = this.onSectionSelect.bind(this);
        this.onCopyLayoutFromEditTemplate = this.onCopyLayoutFromEditTemplate.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.layoutTypeName !== prevProps.layoutTypeName) {
            this.setState(EMPTY_STATE);
        }
    }

    onLayoutChange = (layout) => {
        this.props.onLayoutChange(this.props.layoutTypeName, layout);
    };

    handleNext = (e) => {
        e.preventDefault();
        this.props.onActionNext();
    };

    handleBack = (e) => {
        e.preventDefault();
        this.props.onActionBack();
    };

    onDragStartTag() {
        // console.log("onDragStartTag");
        // this.setState({isDraggedTag: true});
    }

    onDragEndTag() {
        // this.setState({isDraggedTag: false});
        // console.log("onDragEndTag");
    }

    onTagDropped(tag, dropResult) {
        this.props.onTagDropped(this.props.layoutTypeName, tag, dropResult);
    }

    onSectionSelect(sectionData) {
        let relevantSectionAttributes = this.getRelevantAttributes(sectionData.attributeIndexes);
        this.setState({ 
            sectionSelectIndex : sectionData.index, 
            sectionSelectName : sectionData.name, 
            sectionSelectAttributes : relevantSectionAttributes
        });
    }

    onSectionNameChange(evnt) {
        this.setState({sectionSelectName : evnt.target.value}, 
            () => this.props.onSectionNameChanged(this.props.layoutTypeName, this.state.sectionSelectName, this.state.sectionSelectIndex));
    }

    onCopyLayoutFromEditTemplate() {
        this.setState(EMPTY_STATE);
        this.props.onCopyLayoutFromEditTemplate(this.props.layoutTypeName);
    }

    onAttributeLayoutPropertyChange(attributeTechName, propertyType, value) {
        this.props.onAttributeLayoutPropertyChange(this.props.layoutTypeName, attributeTechName, propertyType, value);
    }

    /**
     * Finds which attributes in section can be customized for layout.
     * 
     * @param {Array<Number>} attIndexes 
     * @returns Array of attributes technical names
     */
    getRelevantAttributes(attIndexes) {
        let attNames = [];

        if (!isUndefined(this.props.data.properties.attributes)) {
            attIndexes.forEach(i => {
                //For now only relations can be customized for layout
                if (this.props.data.properties.attributes[i].type === "relation") {
                    attNames.push(this.props.data.properties.attributes[i].techName);
                }
            });
        }

        return attNames;
    }

    render() {
        const {t} = this.props;

        const createNewAttributesObject = {index: -2, name: t('setup.entryTypes.detail.lblLayoutCreateNewSection'), attributeIndexes: []};
        const layoutLgStatic = [ {i: 'name', x: 0, y: 0, w: 24, h: 1, static: true},
            {i: 'description', x: 0, y: 1, w: 24, h: 1, static: true} ];

        const layoutsStatic = {
            lg: layoutLgStatic
        };

        let otherAttributes = this.props.data.properties.attributes.filter((attribute, attIndex, attributes) => {
            return !this.props.data.properties[this.props.layoutTypeName].attributesDivDefinition.some( x => x.attributeIndexes.includes(attIndex)) && attribute.deleted === false;
        });
        const othersAttributesObject = {index: -1, name: t('setup.entryTypes.detail.lblLayoutUnused'), attributeIndexes: []};
        let otherTags = otherAttributes.map((att, index) => {
            let attIndex = this.props.data.properties.attributes.findIndex(attributte => att === attributte);
            othersAttributesObject.attributeIndexes.push(attIndex);
            return <DraggableTag key={index} object={{index: attIndex}} onTagDropped={this.onTagDropped}>{getAttributeTypeIcon(att.type)} {att.name}</DraggableTag>;
        });

        const layoutsAttributesDivs = {
            lg: this.props.data.properties[this.props.layoutTypeName].layout,
        };
        let attributeDivs = this.props.data.properties[this.props.layoutTypeName].attributesDivDefinition.map((divAttribute, index) => {
            let tags = divAttribute.attributeIndexes.map((attIndex, indexTag) => {
                let att = this.props.data.properties.attributes[attIndex];
                return <DraggableTag key={indexTag} object={{index: attIndex}} onTagDropped={this.onTagDropped}>{getAttributeTypeIcon(att.type)} {att.name}</DraggableTag>;
            });
            return <div key={divAttribute.index.toString()}><DroppableDiv onSelect={this.onSectionSelect} object={divAttribute} on>{tags}</DroppableDiv></div>;
        });

        let layoutSetupCard = null;
        if (this.props.layoutTypeName === "viewLayout") {
            layoutSetupCard = <Card title={t('setup.entryTypes.detail.lblLayoutSetup')} style={{marginBottom:"10px"}}>
                                <Button icon={<CopyOutlined />} onClick={this.onCopyLayoutFromEditTemplate}>{t('setup.entryTypes.detail.btnLayoutCopy')}</Button>
                            </Card>;
        }

        let attributesPropertiesCards = [];
        if (!isUndefined(this.props.data.properties.attributes)) {
            this.state.sectionSelectAttributes.forEach(att => {
                let defaultValue = !isUndefined(this.props.data.properties[this.props.layoutTypeName].attributesProperties) && 
                                    !isUndefined(this.props.data.properties[this.props.layoutTypeName].attributesProperties[att]) ?
                                        this.props.data.properties[this.props.layoutTypeName].attributesProperties[att][ATTRIBUTE_PROPERTYTYPE_DISPLAYTYPE] : 
                                        null;
                let attributeDef = this.props.data.properties.attributes.find(a => a.techName === att);

                let attPropOptions = [];
                let relationAttributeProps = null;

                switch (this.props.layoutTypeName) {
                    case "editLayout":
                        let relPropUseContextEngine = !isUndefined(this.props.data.properties[this.props.layoutTypeName].attributesProperties) && 
                                                        !isUndefined(this.props.data.properties[this.props.layoutTypeName].attributesProperties[att]) ?
                                                            this.props.data.properties[this.props.layoutTypeName].attributesProperties[att][ATTRIBUTE_PROPERTYTYPE_USE_CONTEXTENGINE] : 
                                                            false;
                        let relPropContextEngineDepth = !isUndefined(this.props.data.properties[this.props.layoutTypeName].attributesProperties) && 
                                                        !isUndefined(this.props.data.properties[this.props.layoutTypeName].attributesProperties[att]) ?
                                                            this.props.data.properties[this.props.layoutTypeName].attributesProperties[att][ATTRIBUTE_PROPERTYTYPE_CONTEXTENGINE_DEPTH] : 
                                                            (!isEmptyValue(this.props.generalSettingsObject) && 
                                                            !isEmptyValue(this.props.generalSettingsObject.contextEngine) &&
                                                            !isEmptyValue(this.props.generalSettingsObject.contextEngine.depth) ? 
                                                                this.props.generalSettingsObject.contextEngine.depth : 
                                                                5);

                        Object.keys(ATTRIBUTE_DISPLAYTYPE_OPTIONS_EDIT).forEach(k => {
                            attPropOptions.push(
                                <Option value={k} key={k}>{t(ATTRIBUTE_DISPLAYTYPE_OPTIONS_EDIT[k].title)}</Option>
                            );
                        });

                        relationAttributeProps = [
                            <Checkbox
                                key="relPropUseContextEngine" 
                                onChange={(val) => this.onAttributeLayoutPropertyChange(att, ATTRIBUTE_PROPERTYTYPE_USE_CONTEXTENGINE, val.target.checked)}
                                defaultChecked={relPropUseContextEngine}
                                >
                                    {t('setup.entryTypes.detail.lblUseContextEngine')}
                            </Checkbox>,
                            <InputNumber 
                                style={{ width: '100%'}}
                                key="relPropContextEngineDepth"
                                addonBefore={t('setup.entryTypes.detail.lblContextEngineDepth')}
                                //prefix="CE Depth:"
                                size="small"
                                min={1} max={10} defaultValue={relPropContextEngineDepth} 
                                onChange={(val) => this.onAttributeLayoutPropertyChange(att, ATTRIBUTE_PROPERTYTYPE_CONTEXTENGINE_DEPTH, val)} />
                        ];
                        break;
                    case "viewLayout":
                        Object.keys(ATTRIBUTE_DISPLAYTYPE_OPTIONS_VIEW).forEach(k => {
                            attPropOptions.push(
                                <Option value={k} key={k}>{t(ATTRIBUTE_DISPLAYTYPE_OPTIONS_VIEW[k].title)}</Option>
                            );
                        });

                        //console.log(attributeDef.relationEntryType);
                        //console.log(this.props.data.properties.attributes[att]);
                        //console.log(this.props.data.properties[this.props.layoutTypeName].attributesProperties[att][ATTRIBUTE_PROPERTYTYPE_RELATIONTABLEATTRIBUTES]);
                        
                        if (!isEmptyValue(attributeDef.relationEntryType)) {
                            let entryTypesAttributeOptions = [];
                            if (!isUndefined(this.props.entryTypesRequestResult) && this.props.entryTypesRequestResult.getState().isDone()) {
                                entryTypesAttributeOptions = getEntryTypeAttributesSelectOptions(this.props.entryTypesRequestResult.getData(), attributeDef.relationEntryType, true, false, true);
                            }
                            relationAttributeProps = <Select
                                                        showSearch
                                                        mode="multiple"
                                                        // style={{width: 300}}
                                                        placeholder={t('setup.entryTypes.detail.attRelAhowInTableDDL')}
                                                        optionFilterProp="value"
                                                        filterOption={(input, option) =>
                                                            option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        allowClear={true}
                                                        style={{ width: '100%'}}
                                                        onChange={(val) => this.onAttributeLayoutPropertyChange(att, ATTRIBUTE_PROPERTYTYPE_RELATIONTABLEATTRIBUTES, val)}
                                                        defaultValue={(
                                                                isUndefined(this.props.data.properties[this.props.layoutTypeName].attributesProperties) || isUndefined(this.props.data.properties[this.props.layoutTypeName].attributesProperties[att]) ?
                                                                [] :
                                                                this.props.data.properties[this.props.layoutTypeName].attributesProperties[att][ATTRIBUTE_PROPERTYTYPE_RELATIONTABLEATTRIBUTES]
                                                                )}
                                                    >
                                                        {entryTypesAttributeOptions}
                                                    </Select>;
                        }
                        break;
                    default:
                        break;
                }

                attributesPropertiesCards.push(
                    <Card title={attributeDef.name} style={{ marginBottom: '15px'}} key={att}>
                        <Select defaultValue={defaultValue} style={{ width: '100%'}} onChange={(val) => this.onAttributeLayoutPropertyChange(att, ATTRIBUTE_PROPERTYTYPE_DISPLAYTYPE, val)}>
                            {attPropOptions}
                        </Select>
                        {relationAttributeProps}
                    </Card>
                );
            });
        }

        return (
            <Row gutter={[{ xs: 6, sm: 12, md: 24, lg: 32 }, 20]}>
                <Col span={18} style={{borderRight: '1px solid lightgrey'}}>
                    {/* <DndProvider backend={HTML5Backend}> */}
                        {/* <div>Edit layout of object {this.props.data.name}</div> */}
                        <ResponsiveGridLayout className="layout" layouts={layoutsStatic}
                                            breakpoints={{lg: 1200}}
                                            cols={{lg: 24}}
                                            rowHeight={50}
                                            draggableCancel={".NonDraggableArea"}
                        >
                            <div key="name"><Tag color="grey">{t('setup.entryTypes.detail.lblLayoutName')}</Tag></div>
                            <div key="description"><Tag color="grey">{t('setup.entryTypes.detail.lblLayoutDescription')}</Tag></div>
                        </ResponsiveGridLayout>
                        <ResponsiveGridLayout className="layout" layouts={layoutsAttributesDivs}
                                            breakpoints={{lg: 1200}}
                                            cols={{lg: 24}}
                                            rowHeight={75}
                                            onLayoutChange={this.onLayoutChange}
                                            draggableCancel={".NonDraggableArea"}
                        >
                            {attributeDivs}
                        </ResponsiveGridLayout>
                        <DroppableDiv object={createNewAttributesObject} baseColor="none" height="50px" />
                        <Divider orientation="left">{t('setup.entryTypes.detail.lblLayoutUnused')}</Divider>
                        <DroppableDiv object={othersAttributesObject} onSelect={()=>{return;}} height="75px">{otherTags}</DroppableDiv>
                    {/* </DndProvider> */}
                </Col>
                <Col span={6}>
                    {layoutSetupCard}
                    <Card title={t('setup.entryTypes.detail.lblLayoutProperties')} style={{ marginBottom: '15px'}}>
                        <Input placeholder={t('setup.entryTypes.detail.lblLayoutSectionName')} value={this.state.sectionSelectName} onChange={value => this.onSectionNameChange(value)}></Input>
                    </Card>
                    {attributesPropertiesCards}
                </Col>
            </Row>
        )
        
    }
}

export default withTranslation() (ObjectEditLayout);

ObjectEditLayout.propTypes = {
    data: PropTypes.object.isRequired,
    layoutTypeName: PropTypes.string.isRequired, //Kdyby do budoucna přibyl i jiný typ layoutu (print?, ...)
    onTagDropped: PropTypes.func.isRequired,
    onLayoutChange: PropTypes.func.isRequired,
    onActionBack: PropTypes.func.isRequired,
    onActionNext: PropTypes.func.isRequired,
    entryTypesRequestResult: entryTypesRequestResult,
    generalSettingsObject: PropTypes.object
};