import React from "react";
import { withRouter } from "react-router-dom";
import { PageHeader, Button, Modal, Table, Tag, Input } from 'antd';
import { PlusOutlined, EditOutlined, InfoCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next'
import { isEmptyObject, isEmptyValue, isUndefined } from "../../utils/JsObjectHelper";
import RelationVisualization from "../../containers/object/RelationVisualization";
import { getAttributeTypeIcon } from "../../utils/EntryTypeHelper";
import DuplicateAttribute from "./DuplicateAttribute";
import ObjectDelete from "../../containers/object/ObjectDelete";
import { isMobile, isTablet } from "../../utils/AdaptiveLayout"
import cloneDeep from "lodash.clonedeep";
import ImportContentStructure from "../../containers/setup/importExport/ImportContentStructure";
import ExportContentStructure from "../../containers/setup/importExport/ExportContentStructure";
const { Column } = Table;
const { Search } = Input;

class ObjectList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchNameText: [],
            advSearchPageSize: (isEmptyObject(localStorage.getItem("setupEntryTypePageSize")) ? 30 : parseInt(localStorage.getItem("setupEntryTypePageSize"))),
            entryType: null,
            showModal: false,
            showModalCount: 0,
            entryTypeName: null,
            entryList: [],
        };

        this.onMount = this.onMount.bind(this);
        this.filterEntryTypes = this.filterEntryTypes.bind(this);
    }

    showTotal = (total) => {
        return `(${total})`;
    };

    handlePageSizeChanged = (current, page) => {
        localStorage.setItem("setupEntryTypePageSize", page);
        this.setState({ advSearchPageSize: page });
    };

    componentDidMount() {
        this.onMount();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.listDataRequestResult.getData() !== this.props.listDataRequestResult.getData()) {
            let entryList = (this.props.listDataRequestResult.getState().isDone() ?
                this.props.listDataRequestResult.getData().filter(eT => eT.systemType === "object") : []);
            this.setState({ entryList: entryList });
        }
    }

    onMount(entryId) {
        this.props.onMount();
    }

    filterEntryTypes = (searchValue) => {
        this.setState({ searchNameText: searchValue.split(' ') });
    }

    createNewEntryType = () => {
        this.props.history.push(`/setup/contentedit/`);
    }

    editEntryType = (e, record) => {
        this.props.history.push(`/setup/contentedit/` + record.id);
    }

    handleCancel = (e) => {
        this.setState({ showModal: false });
    }
    refreshList = (e) => {
        let arr = cloneDeep(this.state.entryList)
        let index = this.state.entryList.findIndex((el) => el.type === e)
        arr.splice(index, 1)
        this.setState({
            entryList: arr,
            showModal: false
        });
    }
    showModal = (record) => {
        this.setState({
            showModalCount: this.state.showModalCount + 1,
            showModal: true,
            entryType: record.type,
            entryTypeName: record.name
        });
    }

    render() {
        const { t } = this.props;

        let info = () => {
            Modal.info({
                title: t('setup.entryTypes.infoTitle'),
                content: (
                    <div>
                        {t('setup.entryTypes.infoText')}
                    </div>
                ),
                onOk() {
                },
            });
        };
        let paginationSetup = {
            showSizeChanger: true,
            size: "small",
            showTotal: this.showTotal,
            defaultPageSize: this.state.advSearchPageSize,
            pageSizeOptions: ['10', '20', '30', '50', '100'],
            onShowSizeChange: this.handlePageSizeChanged
        };
        let modal = <Modal title={t('setup.entryTypes.deleteEntryType.modalHeadDeleteEntryType') + `"${this.state.entryTypeName}"`} footer={null} visible={this.state.showModal} onOk={this.handleOk} width={isMobile()|| isTablet() ? '100%' : "40%"} onCancel={this.handleCancel}>
            <ObjectDelete showModalCount={this.state.showModalCount} entryType={this.state.entryType} closeModal={this.handleCancel} refresh={this.refreshList} />
        </Modal>;

        return (
            <div>
                {modal}
                <PageHeader
                    title={t('setup.entryTypes.listTitle')}
                    subTitle={t('setup.entryTypes.listTitleHint')}
                    extra={[
                        <Button key="info-circle-button-key" icon={<InfoCircleOutlined />} /* type="primary" */ onClick={info}>
                        </Button>,
                    ]}
                >
                </PageHeader>
                <Table
                    dataSource={this.state.entryList}
                    rowKey={record => record.id}
                    size="small"
                    scroll={{ x: true }}
                    loading={this.props.listDataRequestResult.getState().isLoading()}
                    pagination={paginationSetup}
                    title={() => <div className="setupEntryType">
                        <Button key="button" className="setupEntryTypeBtnAdd" type="primary" style={{ marginLeft: '5px', marginBottom: '10px' }} onClick={this.createNewEntryType}><PlusOutlined /> {t('setup.entryTypes.btnAddEntryType')}</Button>
                        <ImportContentStructure/>
                        <ExportContentStructure/>
                        <Search
                            placeholder={t('setup.entryTypes.searchBoxHint')}
                            onSearch={this.filterEntryTypes}
                            style={{ width: "100%", marginBottom: '10px' }}
                            allowClear
                        />
                        <DuplicateAttribute className="setupEntryTypeBtnAtt" entryTypesRequestResult={this.props.listDataRequestResult} ></DuplicateAttribute>
                        <RelationVisualization className="setupEntryTypeBtnVizual" entryTypeList={this.props.listDataRequestResult}></RelationVisualization>
                    </div>}
                >
                    <Column
                        title={t('setup.entryTypes.tblAction')}
                        key="action"
                        render={(text, record) => (
                            <span>
                                <Button onClick={(e) => { this.editEntryType(e, record) }}><EditOutlined /> {t('setup.entryTypes.tblBtnEditEntryType')}</Button>
                                <Button onClick={() => { this.showModal(record) }}> <DeleteOutlined /></Button>
                            </span>
                        )}
                    />
                    <Column
                        title={t('setup.entryTypes.tblName')}
                        dataIndex="name"
                        key="name"
                        render={(text, record) => (
                            <span><b>{record.name}</b></span>
                        )}
                        filteredValue={this.state.searchNameText}
                        onFilter={(value, record) => record.name.toLowerCase().includes(value.toLowerCase())}
                        sorter={(a, b) => {
                            return (isUndefined(a["name"]) ? "" : a["name"]).localeCompare((isUndefined(b["name"]) ? "" : b["name"]));
                        }}
                    />
                    <Column
                        title={t('setup.entryTypes.tblAttributes')}
                        dataIndex={["properties", "attributes"]}
                        key="properties.attributes"
                        render={tags => (
                            <span>
                                {tags.map(tag => (
                                    <Tag color="blue" key={tag.name}>
                                        {getAttributeTypeIcon(tag.type)} {tag.name}
                                    </Tag>
                                ))}
                            </span>
                        )}
                    />
                    <Column title={t('setup.entryTypes.tblId')} dataIndex="id" key="id" />
                    <Column
                        title={t('setup.entryTypes.tblColor')}
                        dataIndex={["properties", "typeColor"]}
                        key="properties.typeColor"
                        render={color => (
                            <span>
                                <Tag color={isEmptyValue(color) ? "blue" : color}>
                                    {t('setup.entryTypes.tblColor')}
                                </Tag>
                            </span>
                        )}
                    />
                </Table>
            </div>
        );
    }
}

const ObjectListWithRouter = withRouter(ObjectList);

export default withTranslation()(ObjectListWithRouter);