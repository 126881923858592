import React from 'react';
import PropTypes, { string } from "prop-types";
import {isUndefined} from "../../../utils/JsObjectHelper";
import { Alert, Avatar, Card, Checkbox, Descriptions, Divider, Empty, Modal, Select, Spin, Tooltip } from 'antd';
import { FolderOutlined, ArrowDownOutlined, KeyOutlined, SelectOutlined, BuildOutlined } from '@ant-design/icons';
import update from 'immutability-helper';
import {withRouter} from "react-router-dom";
import { withTranslation } from 'react-i18next'
import { EMPTY_ENTRY_MANIPULATION_DEFINITION } from '../../../utils/EmptyEntryManipulationDefinition';
import {fetchEntries} from '../../../apicalls/fetchEntries';
import debounce from 'lodash/debounce';
import { bmcEntryShape } from '../../../shapes/BmcEntryShape';
import {Link} from "react-router-dom";
import { fetchEntryDetail } from '../../../apicalls/fetchEntryDetail';
import { fetchBreadcrumb } from '../../../apicalls/fetchBreadcrumb';

const { Option } = Select;

class EntryManipulationDialog extends React.Component {
    constructor(props) {
        super(props);
        this.onOk = this.onOk.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onFolderSelected = this.onFolderSelected.bind(this);
        this.onCheckBoxChanged = this.onCheckBoxChanged.bind(this);
        this.onFolderSearch = this.onFolderSearch.bind(this);
        this.onFolderSearch = debounce(this.onFolderSearch, 800);
        this.onFolderFetched = this.onFolderFetched.bind(this);
        this.loadFolderDetail = this.loadFolderDetail.bind(this);
        this.onFolderDetailLoaded = this.onFolderDetailLoaded.bind(this);
        this.onBreadCrumbLoaded = this.onBreadCrumbLoaded.bind(this);
        
        
        this.state = {
            manipulationData: EMPTY_ENTRY_MANIPULATION_DEFINITION,
            canAction: false,
            fetchedFolders: [],
            fetching: false,
            isLoadingDetail: false,
            loadedFolder: null,
            isBadFolderHierarchy: true,
            isSaving: false
        };
    }

    /**
     * 
     * @param {*} prevProps 
     * @param {*} prevState 
     */
    componentDidUpdate(prevProps, prevState) {
        if (this.props.isVisible && !prevProps.isVisible) {
            this.setState({ 
                manipulationData: EMPTY_ENTRY_MANIPULATION_DEFINITION, 
                canAction: false,
                fetchedFolders: [],
                fetching: false,
                isLoadingDetail: false,
                loadedFolder: null,
                isBadFolderHierarchy: true,
                isSaving: false
            });
        }
    }

    onOk () {
        if (isUndefined(this.props.newAfterCopyEntryId)) {
            if (this.state.canAction) {
    
                this.setState({ isSaving: true });
    
                this.props.onAction(this.state.manipulationData);
            }
        } else {
            this.props.history.push(`/entry/${this.props.newAfterCopyEntryId}`);
        }
    };

    onCancel () {
        this.props.onCancel();
    };

    onFolderSelected = (selectedFolderId) => {
        this.setState( (prevState, props) => {
            return update(prevState, {
                manipulationData : {
                    targetFolderId : {$set: selectedFolderId},
                }/*,
                canAction : {$set: true}*/
            });
        }, () => this.loadFolderDetail(selectedFolderId));

        //this.loadFolderDetail(selectedFolderId);
    };

    loadFolderDetail = (folderId) => {
        this.setState({ isLoadingDetail: true });

        fetchEntryDetail(folderId, data => this.onFolderDetailLoaded(data));
        
        if (this.props.entry.systemType === "folder") {
            fetchBreadcrumb(folderId, dataBC => this.onBreadCrumbLoaded(dataBC));
        }
    };

    onFolderDetailLoaded = (folderData) => {
        if (!isUndefined(folderData)) {
            let canActionThis = false;
            folderData.isRightOK = folderData.userEntryAccess === "EDIT";
            folderData.isTypeOK = false;
            
            if (!isUndefined(folderData.properties)) {
                if (this.props.entry.systemType === "folder") {
                    if (folderData.properties.childFolderEntryTypes.indexOf(this.props.entry.type)>-1) {
                        folderData.isTypeOK = true;
                    }
                } else {
                    if (folderData.properties.childObjectEntryTypes.indexOf(this.props.entry.type)>-1) {
                        folderData.isTypeOK = true;
                    }
                }
            }
            
            canActionThis = folderData.isRightOK && folderData.isTypeOK;

            this.setState({ isLoadingDetail: false, loadedFolder: folderData, canAction : canActionThis });
        } else {
            this.setState({ isLoadingDetail: false, canAction : false });
        }
    };

    onBreadCrumbLoaded = (breadcrumbData) => {
        if (!isUndefined(breadcrumbData)) {
            //Try find actual folder in destination folder path
            if (!isUndefined(breadcrumbData.find(bC => bC.id === this.props.entry.id))) {
                //If actual is parent of destination => No can do
                this.setState({ isBadFolderHierarchy: true });
            } else {
                //Folder can be moved or coppied
                this.setState({ isBadFolderHierarchy: false });
            }
        }
    };

    onCheckBoxChanged (chBoxVal) {
        this.setState( (prevState, props) => {
            return update(prevState, {
                manipulationData : {
                    rightsByTarget : {$set: chBoxVal.target.checked},
                }
            });
        });
    }

    onFolderSearch = (value) => {
        this.setState({fetching: true});
        fetchEntries(value,"entryManipulationDialog", data => this.onFolderFetched(data), null, ["folder"]);
    }

    onFolderFetched = (folderData) => {
        let filteredFolders = folderData.filter(
                f=> f.type !== "home" && 
                isUndefined(f.deleted) && 
                f.id !== this.props.entry.parent.id && 
                f.id !== this.props.entry.id
            );

        this.setState({ fetchedFolders: filteredFolders, fetching: false });
    }


    render() {
        const {t} = this.props;

        let enableAction = false;

        if (!isUndefined(this.props.entry)) {
            enableAction = this.state.canAction && (this.props.entry.systemType === "folder" ? !this.state.isBadFolderHierarchy : true);
        }

        let modalMessage = <span>{t('app.entry.tools.actions.modalManipulationInfo')}<br/>{t('app.entry.tools.actions.modalManipulationInfo2')}</span>;

        let folderOptions = [];

        if (!isUndefined(this.state.fetchedFolders)) {
            folderOptions = this.state.fetchedFolders.map(f=> <Option value={f.id} key={f.id} disabled={f.isDisabled}>{f.name}</Option>)
        }

        let infoPanel = null;
        let checkFolderHierarchyPanel = null;
        let afterCopyInfoPanel = null;

        if (!isUndefined(this.props.newAfterCopyEntryId) && this.props.isCopyMode) {
            afterCopyInfoPanel = <Alert
                                    message={t('app.entry.tools.actions.modelManipulationAfterCopyTitle')}
                                    description={<Link to={`/entry/${this.props.newAfterCopyEntryId}`}>{t('app.entry.tools.actions.modelManipulationAfterCopyGotoLink')}</Link>}
                                    type="success"
                                    showIcon
                                />;
        }

        if (!isUndefined(this.props.entry) && this.props.entry.systemType === "folder") {
            checkFolderHierarchyPanel = <div style={{marginTop: '5px'}}><Avatar shape="square" icon={<SelectOutlined />} 
                    style={{ backgroundColor: (!this.state.isBadFolderHierarchy ? "green" : "red")  }} />&nbsp;
                    { (this.state.isBadFolderHierarchy ? t('app.entry.tools.actions.modalManipulationSubfolderNOK') : t('app.entry.tools.actions.modalManipulationSubfolderOK'))}
                </div>;
        }

        if (!isUndefined(this.state.loadedFolder) || this.state.isLoadingDetail) {
            infoPanel = <Card loading={this.state.isLoadingDetail} style={{marginTop: '20px'}}>
                            <div>
                                <FolderOutlined /> {!isUndefined(this.props.entry) ? this.props.entry.parent.name : ""}
                            </div>
                            <div>
                                <ArrowDownOutlined />
                            </div>
                            <div>
                                <FolderOutlined />
                                    <Link key="target" name="target" to={`/entry/${ !isUndefined(this.state.loadedFolder) ? this.state.loadedFolder.id : ""}`} target="_blank">
                                        &nbsp;{ !isUndefined(this.state.loadedFolder) ? this.state.loadedFolder.name : ""}
                                    </Link> <br/>
                                    <Divider dashed />
                                    <div style={{marginTop: '5px'}}><Avatar shape="square" icon={<KeyOutlined />} 
                                        style={{ backgroundColor: !isUndefined(this.state.loadedFolder) ? (this.state.loadedFolder.isRightOK ? "green" : "red")  : "red" }} />&nbsp;
                                        { !isUndefined(this.state.loadedFolder) ? (this.state.loadedFolder.isRightOK ? t('app.entry.tools.actions.modalManipulationRightOK') : t('app.entry.tools.actions.modalManipulationRightNOK'))  : ""}
                                    </div>
                                    <div style={{marginTop: '5px'}}><Avatar shape="square" icon={<BuildOutlined />} 
                                        style={{ backgroundColor: !isUndefined(this.state.loadedFolder) ? (this.state.loadedFolder.isTypeOK ? "green" : "red")  : "red"  }} />&nbsp;
                                        { !isUndefined(this.state.loadedFolder) ? (this.state.loadedFolder.isTypeOK ? t('app.entry.tools.actions.modalManipulationTypeOK') : t('app.entry.tools.actions.modalManipulationTypeNOK'))  : ""}
                                    </div>
                                    {checkFolderHierarchyPanel}
                            </div>
                        </Card>;
        }

        return <Modal
                    visible={this.props.isVisible}
                    title={ this.props.isCopyMode ? t('app.entry.tools.actions.modalManipulationTitleCopy') : t('app.entry.tools.actions.modalManipulationTitleMove') }
                    okText={ 
                        isUndefined(this.props.newAfterCopyEntryId) ? 
                        (this.props.isCopyMode ? t('app.entry.tools.actions.modalManipulationBtnOnCopy') : t('app.entry.tools.actions.modalManipulationBtnOnMove')) : 
                        t('app.entry.tools.actions.modelManipulationAfterCopyGotoLink')
                    }
                    cancelText={t('app.entry.tools.actions.modalManipulationBtnCancel')}
                    onCancel={this.onCancel}
                    onOk={this.onOk}
                    okButtonProps={ { disabled: !enableAction, loading: (this.state.isSaving && isUndefined(this.props.newAfterCopyEntryId)) } }
                    width="800px"
                    //destroyOnClose={true}
                >
                    <Alert message={modalMessage} type="info" showIcon style={{marginBottom: '15px'}}></Alert>
                    <Descriptions bordered column={1} style={{marginBottom: '15px'}}>
                        <Descriptions.Item label={t('app.entry.tools.actions.modalManipulationSelectFolder')}>
                            <Tooltip
                                trigger={['focus']}
                                title={t('app.entry.tools.actions.modalManipulationSelectHint')}
                                placement="topLeft"
                                //overlayClassName="numeric-input"
                            >
                                <Select 
                                    placeholder={t('app.entry.tools.actions.modalManipulationSelectHint')}
                                    style={{ width: 450 }} 
                                    onSelect={this.onFolderSelected}
                                    value={this.state.manipulationData.targetFolderId}
                                    onSearch={this.onFolderSearch}
                                    filterOption={false}
                                    optionFilterProp="children"
                                    showSearch
                                    notFoundContent={this.state.fetching ? <Spin size="small" /> : <Empty></Empty>}
                                    >
                                        {folderOptions}
                                </Select> 
                            </Tooltip>
                            {infoPanel}
                        </Descriptions.Item>
                        {/* <Descriptions.Item label={t('app.entry.tools.actions.modalManipulationChkBox')}>
                            <Checkbox onChange={this.onCheckBoxChanged} checked={this.state.manipulationData.rightsByTarget}></Checkbox>
                        </Descriptions.Item> */}
                    </Descriptions>
                    {afterCopyInfoPanel}
                </Modal>;
    }
}

const EntryManipulationDialogWithRouter = withRouter(EntryManipulationDialog);

export default withTranslation() (EntryManipulationDialogWithRouter);

EntryManipulationDialogWithRouter.propTypes = {
    entry: bmcEntryShape.isRequired,
    isVisible: PropTypes.bool.isRequired,
    isCopyMode: PropTypes.bool.isRequired,
    onAction: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    newAfterCopyEntryId: string
};