import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';
import { rootReducer } from './reducers';
import { createBrowserHistory } from 'history';
import { routerMiddleware, ConnectedRouter } from 'connected-react-router';
import Keycloak from 'keycloak-js';
import {requestUserLogin, requestUserLogout, successfulLogin, successfulTokenUpdate} from "./actions/loggedUser";
import {config} from "./config/Config";
import {CookiesProvider} from "react-cookie";
import {wsConnect, wsRefreshToken} from "./modules/websocket/actions";
import websocketMiddleware from './modules/websocket/middleware/middleware';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'

const history = createBrowserHistory();

const loggerMiddleware = createLogger();
const middlewares = [
    thunkMiddleware,
    loggerMiddleware,
    routerMiddleware(history),
    websocketMiddleware
];

const store = createStore(rootReducer(history), applyMiddleware(...middlewares));

const keycloak = Keycloak(config.keycloak);

keycloak.init({ onLoad: 'login-required', checkLoginIframe: false, checkLoginIframeInterval: 1, promiseType: 'native'}).then(authenticated => {
    if (keycloak.authenticated) {
        //sessionStorage.setItem('kctoken', keycloak.token);

        //Updating some value in store to re-render the component
        store.dispatch(successfulLogin(keycloak));
        setInterval(() => {
            keycloak.updateToken(10).then((refreshed) => {
                if(refreshed) {
                    store.dispatch(successfulTokenUpdate(keycloak));
                    store.dispatch(wsRefreshToken(keycloak.token));
                } else {
                    // token is still valid
                }
            }).catch(() => {
                store.dispatch(requestUserLogout());
                keycloak.logout();
            });
        }, 10000);
        store.dispatch(wsConnect(
            {
                brokerURL: config.url.WS_URL,
                connectHeaders: {
                    application: "Aphinit react app2"
                },
                debug: (str) => {
                   // console.log(str);
                },
                reconnectDelay: 5000,
                heartbeatIncoming: 4000,
                heartbeatOutgoing: 4000
            },
            keycloak.token,
            ["/topic/editedEntries"]
        ));
    } else {
        store.dispatch(requestUserLogin());
        keycloak.login();
    }
}).catch((e) => {
    alert('failed to initialize keycloak' + e);
});

ReactDOM.render((
    <CookiesProvider>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <I18nextProvider i18n={i18n}>
                    <App keycloak={keycloak} />
                </I18nextProvider>
            </ConnectedRouter>
        </Provider>
    </CookiesProvider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
