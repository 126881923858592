import React from "react";
import { Button, Card, Empty, Table } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next'
import { bmcEntryShape } from "../../../../shapes/BmcEntryShape";
import { isEmptyObject, isEmptyValue, isUndefined } from "../../../../utils/JsObjectHelper";
import RunProcess from "../../../../containers/bpmn/RunProcess"
import { fetchBpmnGetRunningProcessCount } from "../../../../apicalls/bpmn/fetchBpmnGetRunningProcessCount";
import { fetchEntryDetail } from "../../../../apicalls/fetchEntryDetail";

const { Column } = Table;

class EntryOnDemandWorkflowList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            onDemWorkflows: null,
        }
    }

    componentDidMount() {
        this.props.onMount();
        this.parentDetail();
    }
    parentDetail = () => {
        fetchEntryDetail(this.props.entry.parent.id, (parent) => this.getProcessList(parent))
    }
    getProcessList = async (parent) => {
        let procesesList = await fetchBpmnGetRunningProcessCount(this.props.entry.id);
        let onDemWorkflows = [];
        if (this.props.entry && this.props.entry.systemType === "object") {
            let allowedWorkflow = [];
            if (parent && parent.properties && parent.properties.workflowForEntry) {
                allowedWorkflow = parent.properties.workflowForEntry.onRunByUser
            }
            onDemWorkflows = (!isEmptyObject(this.props.workflowSettingsList) ?
                this.props.workflowSettingsList.filter(w =>
                    w.value.entryTypes.includes(this.props.entry.type) &&
                    this.checkUserGroupAccess(w.value.groups) && allowedWorkflow.find((el) => el === w.name)
                ) :
                null
            );
        }
        if (this.props.entry && this.props.entry.systemType === "folder") {
            let allowedWorkflowForFolder = [];
            if (this.props.entry.properties && this.props.entry.properties.workflowForFolder) {
                allowedWorkflowForFolder = this.props.entry.properties.workflowForFolder.onRunByUser
            }
            onDemWorkflows = (!isEmptyObject(this.props.workflowSettingsList) ?
                this.props.workflowSettingsList.filter(w =>
                    w.value.entryTypes.includes(this.props.entry.type) &&
                    this.checkUserGroupAccess(w.value.groups) && allowedWorkflowForFolder.find((el) => el === w.name)
                ) :
                null
            );
        }
      

        if (!isEmptyValue(onDemWorkflows) ) {
            if(procesesList){
                onDemWorkflows.forEach((workflow) => {
                const matchingProcesses = procesesList.filter(
                    (element) => element.processDefinitionKey === workflow.value.bpmnKey
                );
        
                if (matchingProcesses) {
                    workflow.value.runningProceses = matchingProcesses.length;
                } else {
                    workflow.value.runningProceses = 0;
                }
            });
        }
            
            this.setState({ onDemWorkflows: onDemWorkflows });
        }
           
        
    }
    checkUserGroupAccess = (workflowGroups) => {
        if (isUndefined(this.props.loggedUser)) return false;
        let userGroups = this.props.loggedUser.user.groupsDetails;
        let hasGroup = false;
        userGroups.forEach(uG => {
            if (workflowGroups.includes(uG.id))
                hasGroup = true;
        });
        return hasGroup;
    };

    render() {
        const { t } = this.props;
        // let onDemWorkflows = (!isEmptyObject(this.props.workflowSettingsList) ?
        //     this.props.workflowSettingsList.filter(w =>
        //         w.value.entryTypes.includes(this.props.entry.type) &&
        //         this.checkUserGroupAccess(w.value.groups)
        //     ) :
        //     null
        // );


        let cardContent = <Empty></Empty>;
        if (!isEmptyValue(this.state.onDemWorkflows)) {

            cardContent = <Table size="small"
                dataSource={this.state.onDemWorkflows}
                rowKey={record => record.name}
                pagination={false}
            >
                <Column
                    title={t('app.entry.tools.actions.boxWorkflowstblAction')}
                    key="action"
                    render={(text, record) => (
                        <span>
                            {/* <Button onClick={(e) => {this.editEntryType(e,record)}}><Icon type="edit"/> {t('setup.entryTypes.tblBtnEditEntryType')}</Button> */}
                            <RunProcess id={record.name} entryId={this.props.entry.id} getProcessList={this.parentDetail} />

                        </span>
                    )}
                />
                <Column
                    title={t('app.entry.tools.actions.boxWorkflowstblName')}
                    dataIndex="value.workflowName"
                    key="name"
                    render={(text, record) => (
                        <span><b>{record.value.workflowName}</b></span>
                    )}
                />
                <Column
                    title={t('app.entry.tools.actions.boxWorkflowstblDescription')}
                    dataIndex="value.workflowDescription"
                    key="description"
                    render={(text, record) => (
                        (isEmptyValue(record.value.workflowDescription) ? "" : <span title={record.value.workflowDescription}>{(record.value.workflowDescription.length > 50 ? `${record.value.workflowDescription.substring(0, 50)}...` : record.value.workflowDescription)}</span>)
                    )}
                />
                <Column
                    title="Running proceses"
                    dataIndex="value.runningProceses"
                    key="runningProceses"
                    render={(text, record) => {
                        return <span>{isEmptyValue(record.value.runningProceses) ? 0 : record.value.runningProceses}</span>
                    }}
                />
            </Table>;
        };

        return <Card type="inner" size="small"
            title={t('app.entry.tools.actions.boxWorkflows')}
            style={{ marginBottom: '20px' }}
            bodyStyle={{ display: 'grid' }}>
            {cardContent}
        </Card>;
    }
};

export default withTranslation()(EntryOnDemandWorkflowList);

EntryOnDemandWorkflowList.propTypes = {
    entry: bmcEntryShape.isRequired //will be used for ID and type
};