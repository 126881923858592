import React from "react";
import { Spin, Select, Button, Modal, Result, notification } from "antd";
import { withTranslation } from "react-i18next";
import { PlayCircleOutlined } from "@ant-design/icons";
import { isObject, isUndefined } from "../../../../utils/JsObjectHelper";
import FormBpmn from "../../../bpmn/Form";
import { fetchBpmnProcessForm } from "../../../../apicalls/bpmn/fetchBpmnProcesForm";
import { fetchBpmnStartProcess } from "../../../../apicalls/bpmn/fetchBpmnStartProcess";
import { notificationError,notificationSuccess } from "../../../../utils/NotificationsHelper";
import { t } from "i18next";


class BpmnRunProcess extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			IsModalVisible: false,
			formData: null,
			load:false
		};
	}

	showModal = () => {
		fetchBpmnProcessForm(this.props.id, this.click);
		this.setState({
			isModalVisible: true,
		});
	};

	click = (value) => {
		if (value === "noform") {
			fetchBpmnStartProcess(this.props.id, this.props.entryId, false, this.click);
		}
		if (value === "success") {
			this.props.refreshTaskCount()
			notificationSuccess(t("app.bpmn.alertProcessRunTitle"), t("app.bpmn.alertProcessRunDescription"));
			this.props.getProcessList();
			this.setState({
				isModalVisible: false,
				loading: false,
			});
		}
		if (value === "error") {
			notificationError(t("app.bpmn.processStartedError"))
			this.setState({
				isModalVisible: false,
			});
		}
		if (isObject(value)) {
			this.setState({
				formData: value,
			});
		}
	};

	clickComplete = (valueForm) => {
		fetchBpmnStartProcess(this.props.id, this.props.entryId, valueForm, this.click);
		this.setState({
			load: true,
		});
	};

	handleCancel = () => {
		this.setState({
			isModalVisible: false,
			loading: true,

		});
	};

	render() {
		const { t } = this.props;
		let modalItem = null;
		modalItem = (

			<Spin spinning={isUndefined(this.state.formData)|| this.state.load} size="large" style={{ textAlign: "center" }}>
				<FormBpmn
					data={this.state.formData}
					complete={this.clickComplete}
					showForm={false}
					showOk={false}
					variables={[]}
				/>
			</Spin>
		);


		return (
			<>
				<Button type="primary" onClick={this.showModal}>
					<PlayCircleOutlined />
					{t("app.entry.tools.actions.boxWorkflowsBtnRun")}
				</Button>
				<Modal
					visible={this.state.isModalVisible}
					okButtonProps={{ hidden: true }}
					onCancel={this.handleCancel}
				>
					{modalItem}
				</Modal>
			</>
		);
	}
}

export default withTranslation()(BpmnRunProcess);
