import React from 'react';
import SearchHeader from "../../containers/header/SearchHeader";
import { withRouter, Link } from "react-router-dom";
import logoAph from '../../aphnit_simple_wide.svg';
import { Popover, Menu, Row, Col, Select, Radio,Badge } from 'antd';
import { SearchOutlined, SettingOutlined, LogoutOutlined, CalendarOutlined, MenuOutlined, CloseOutlined,UserOutlined,DashboardOutlined } from '@ant-design/icons';
import CreateNewButton from "../../containers/header/CreateNewButton";
import PropTypes from "prop-types";
import { loggedUserShape } from "../../shapes/LoggedUserShapes";
import { config } from "../../config/Config";
import { withTranslation } from 'react-i18next'
import { isMobile, isTablet } from '../../utils/AdaptiveLayout';
import { isUndefined } from '../../utils/JsObjectHelper';

const { Option } = Select;

class AppHeaderMobile extends React.Component {

    state = {
        visible: false,
    };

    constructor(props) {
        super(props);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
    }

    menuSelected = (e) => {
        if (e.key && e.key !== "language") {
            this.handleVisibleChange(false);
        }
    };

    handleLanguageChange(e) {
        this.props.i18n.changeLanguage(e.target.value);
    };

    handleVisibleChange = visible => {
        this.setState({ visible });
    };
    HandleBurger = () => {
        this.setState({ visible: true })
    }
    


    render() {
        const { t } = this.props;
        let taskCount = isUndefined(this.props.taskCount) ? 0 : this.props.taskCount.getData();
        let localTaskCount = JSON.parse(localStorage.getItem('taskCount'));
        let showCount = null;
        let newTask = taskCount;
        if (!isUndefined(localTaskCount) && !isUndefined(taskCount)) {
            if (localTaskCount < taskCount) {
                newTask = taskCount - localTaskCount;
                showCount = false;
            }
            if (localTaskCount === taskCount) {
                showCount = true;
            }
        };
        let content = (
            <Menu
                style={{ width: window.innerWidth - 32 }}
                mode="inline"
                onClick={this.menuSelected}
            >
                 <Menu.Item key="userdashboard"><Link to={`/userdashboard`}><DashboardOutlined />&nbsp;{t('app.header.userDashboard')}</Link></Menu.Item>
                <Menu.Item key="tasklist">
                <Link to={`/bpmn`}><CalendarOutlined />&nbsp;{t('app.header.userMenuTaskList')}<Badge count={taskCount} ></Badge>&nbsp;
                        {newTask !== taskCount ? <a style={{ color: '#52c41a',  }}> +{newTask}  </a> : null}
                    </Link>
                </Menu.Item>
               <Menu.Item key="search">
                    <Link to={`/search`}><SearchOutlined />&nbsp;{t('app.header.userMenuSearch')}</Link>
                </Menu.Item>
                {this.props.hasSetupRight && <Menu.Item key="setup">
                    <Link to={`/setup`}><SettingOutlined />&nbsp;{t('app.header.userMenuSetup')}</Link>
                </Menu.Item>}
                <Menu.Item key="logout" onClick={this.props.onLogout}>
                    <LogoutOutlined />&nbsp;{t('app.header.userMenuLogOut')}
                </Menu.Item>
                <Menu.Item key="language">
                    {/* <FlagOutlined />&nbsp; */}
                    <Radio.Group onChange={this.handleLanguageChange} value={this.props.i18n.language}>
                        <Radio value="en">English</Radio>
                        <Radio value="cs">Česky</Radio>
                        <Radio value="nl">Dutch</Radio>
                      
                    </Radio.Group>
                </Menu.Item>
            </Menu>
          
        );
        
        let main = (<div>
            <Row type="flex" justify="space-between" >
                <Col >
                    <div className="header_logo_adaptive">
                        <Link to={`/`}>
                            <img src={logoAph} alt="Logo" />
                        </Link>
                    </div>
                </Col>
             
                <Col  style={{marginTop:'10px'}} >
                   <div>
                   <CreateNewButton />
                  
                   
                   
                    <div className='burger' >
                   
                        <Popover
                            placement="bottomRight"
                            title={<a  href={"/id2entry/" + this.props.loggedUser.id} target="_blank" ><UserOutlined /> {this.props.loggedUser.name}</a>}
                           // title={this.props.loggedUser && this.props.loggedUser.name}
                            content={content}
                            trigger="click"
                            visible={this.state.visible}
                            onVisibleChange={this.handleVisibleChange}
                        >
                            <Badge count={newTask} dot={showCount}>
                            {this.state.visible ? <CloseOutlined style={{ fontSize: 32 }} /> : <MenuOutlined style={{ fontSize: 32 }} />}
                            </Badge>
                        </Popover>
                    </div>
                    </div>
                </Col>
            </Row>
        </div>)

        return <div>
            {main}
        </div>;
    }
}

const AppHeaderMobileWithRouter = withRouter(AppHeaderMobile);

export default withTranslation()(AppHeaderMobileWithRouter);

AppHeaderMobileWithRouter.propTypes = {
    onLogout: PropTypes.func.isRequired,
    loggedUser: loggedUserShape,
    isAdmin: PropTypes.bool.isRequired,
    hasSetupRight: PropTypes.bool.isRequired
};