import ObjectListPresenter from '../../components/object/ObjectList';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import {fetchObjectList} from "../../actions/objectList";

const listDataDefault = [
    { id: 1, name: 'User', attributes: [{id: 1, name: 'userName'}, {id: 2, name: 'email'}]},
    { id: 2, name: 'Folder', attributes: [{id: 1, name: 'name'}, {id: 2, name: 'type'}, {id: 3, name: 'info1'}, {id: 4, name: 'info2'}, {id: 5, name: 'info3'}, {id: 6, name: 'info4'}, {id: 7, name: 'info5'}, {id: 8, name: 'info6'}]},
    { id: 3, name: 'Table', attributes: [{id: 1, name: 'tableName'}, {id: 2, name: 'type'}]},
];

const mapStateToProps = (state, ownProps) => {
    return {
        listDataRequestResult: state.objectList,
        listDataOld: listDataDefault
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount() {
            dispatch(fetchObjectList(true));
        },
    }
};

const ObjectList = connect(
    mapStateToProps,
    mapDispatchToProps
)(ObjectListPresenter);

export default withRouter(ObjectList);