import LineagePresenter from '../../components/lineage/Lineage';
import { connect } from 'react-redux';
import { fetchDetail, unmountDetail } from "../../actions/entryDetail";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next'

import { fetchDetailIncomingRelations } from "../../actions/entryDetailIncomingRelations";
import { fetchDetailOutgoingRelations } from "../../actions/entryDetailOutgoingRelations";
import { fetchObjectList } from "../../actions/objectList";
import { fetchSettingsList } from "../../actions/settings";

import { isEmptyValue } from '../../utils/JsObjectHelper';


/**
 * 
 * @param {Object} lineageContainer 
 * @returns 
 */
const getCytoscapeOptions = (lineageContainer) => {
    let options = {
        container: lineageContainer,
        elements: [
        ],
        style: [
            {
                selector: 'node',
                style: {
                    'label': 'data(label)',
                    'font-size': '12px',
                    'text-valign': 'center',
                    'text-halign': 'center',
                    "shape": "rectangle",
                    'background-color': '#A3ADFF',
                    "text-wrap": "wrap",
                    "text-max-width": 100,
                    'width': 150,
                    'border-color': '#000',
                    'border-width': 1,
                    'border-opacity': 0.3,
                    "padding": "6px",
                    // 'text-outline-width' : '1px',
                    // 'text-outline-color' : '#000000',
                }
            },
            //Entry type styles will be added on the fly
            {
                selector: ':parent',
                css: {
                    'text-valign': 'top',
                    'text-halign': 'center',
                    'font-size': '14px',
                    // 'text-outline-width' : '1px',
                    // 'text-outline-color' : '#000000',
                    // 'text-outline-opacity' : 0.5
                }
            },
            {
                selector: 'edge',
                style: {
                    'label': 'data(label)',
                    'width': 2,
                    'target-arrow-shape': 'triangle',
                    'line-color': '#9dbaea',
                    'target-arrow-color': '#3632a8',
                    'curve-style': 'unbundled-bezier', /* 'segments', 'taxi' */
                    /*"edge-distances": "node-position",*/
                    'font-size': '11px',
                    'text-valign': 'top',
                    //"edge-text-rotation": "autorotate",
                    'text-outline-width' : '1px',
                    'text-outline-color' : '#ffffff'
                }
            },
            {
                selector: '.edgeTaxi',
                style: {
                    'curve-style': 'taxi',
                }
            },
            {
                selector: '.edgeSegments',
                style: {
                    'curve-style': 'segments',
                }
            },
            {
                selector: '.edgeBazier',
                style: {
                    'curve-style': 'unbundled-bezier',
                }
            },
            {
                selector: '.mainNode',
                style: {
                    'border-width': 3,
                    'border-opacity': 1,
                }
            },
            {
                selector: '.lastSelectedNode',
                style: {
                    'border-width': 3,
                    'border-opacity': 1,
                    'border-style' : 'dashed'
                }
            },
            {
                selector: '.highlightedFriends',
                style: {
                    'border-color': '#f70000', 
                    'border-width': '4px', 
                    'border-opacity': 0.6, 
                    'line-color': '#f70000'
                }
            },
            {
                selector: '.highlightedSearched',
                style: {
                    'border-color': '#f70000', 
                    'border-width': '4px', 
                    'border-opacity': 0.6, 
                    'line-color': '#f70000',
                    'border-style' : 'double'
                }
            },
            {
                selector: '.pathNode',
                style: {
                    'border-color': '#f70000', 
                    'border-width': '4px', 
                    'border-opacity': 0.6, 
                    'border-style' : 'dashed',
                    'line-style': 'dashed',
                    'line-color': '#f70000'
                }
            },
            {
                selector: '.hierarchyEdge',
                style: {
                    'target-arrow-shape': 'none',
                    'source-arrow-shape': 'diamond',
                    'source-arrow-fill' : 'fill',
                    'source-arrow-color': '#000000',
                    'line-style': 'dashed',
                    'line-color': '#868686'
                }
            }
        ],
        wheelSensitivity: 0.5,
    };

    return options;
};

const getMenuOptions = (callback, translator) => {
    let options = {
        // Customize event to bring up the context menu
        // Possible options https://js.cytoscape.org/#events/user-input-device-events
        evtType: ['cxttap', 'taphold'],
        // List of initial menu items
        // A menu item must have either onClickFunction or submenu or both
        menuItems: [/*
          {
            id: 'remove', // ID of menu item
            content: 'remove', // Display content of menu item
            tooltipText: 'remove', // Tooltip text for menu item
            image: {src : "remove.svg", width : 12, height : 12, x : 6, y : 4}, // menu icon
            // Filters the elements to have this menu item on cxttap
            // If the selector is not truthy no elements will have this menu item on cxttap
            selector: 'node, edge', 
            onClickFunction: function () { // The function to be executed on click
              console.log('remove element');
            },
            disabled: false, // Whether the item will be created as disabled
            show: false, // Whether the item will be shown or not
            hasTrailingDivider: true, // Whether the item will have a trailing divider
            coreAsWell: false // Whether core instance have this item on cxttap
            submenu: [] // Shows the listed menuItems as a submenu for this item. An item must have either submenu or onClickFunction or both.
          },*/
            {
                id: 'IN',
                content: translator('app.lineage.lineageMenuIn'),
                tooltipText: translator('app.lineage.lineageMenuInHint'),
                selector: 'node',
                onClickFunction: function (evnt) {
                    callback('IN', evnt);
                },
            },
            {
                id: 'OUT',
                content: translator('app.lineage.lineageMenuOut'),
                tooltipText: translator('app.lineage.lineageMenuOutHint'),
                selector: 'node',
                onClickFunction: function (evnt) {
                    callback('OUT', evnt);
                }
            },
            {
                id: 'BOTH',
                content: translator('app.lineage.lineageMenuBoth'),
                tooltipText: translator('app.lineage.lineageMenuBothHint'),
                selector: 'node',
                onClickFunction: function (evnt) {
                    callback('BOTH', evnt);
                }
            },
            {
                id: 'CLOSE',
                content: translator('app.lineage.lineageMenuClose'),
                tooltipText: translator('app.lineage.lineageMenuCloseHint'),
                selector: 'node',
                hasTrailingDivider: true,
                onClickFunction: function (evnt) {
                    callback('CLOSE', evnt);
                }
            },
            {
                id: 'OPEN',
                content: translator('app.lineage.lineageMenuOpen'),
                tooltipText: translator('app.lineage.lineageMenuOpenHint'),
                selector: 'node',
                hasTrailingDivider: true,
                onClickFunction: function (evnt) {
                    callback('OPEN', evnt);
                }
            },
            {
                id: 'HIGHLIGHT',
                content: translator('app.lineage.lineageMenuHighlight'),
                tooltipText: translator('app.lineage.lineageMenuHighlightHint'),
                selector: 'node',
                onClickFunction: function (evnt) {
                    callback('HIGHLIGHT', evnt);
                }
            },
            {
                id: 'PATH_SUB',
                content: translator('app.lineage.lineageMenuPath'),
                tooltipText: translator('app.lineage.lineageMenuPathHint'),
                //image: {src : "add.svg", width : 12, height : 12, x : 6, y : 4},
                selector: 'node',
                // onClickFunction: function (evnt) {
                //     callback('HIGHLIGHT', evnt);
                // },
                submenu: [
                    {
                        id: 'PATH_START',
                        content: translator('app.lineage.lineageMenuPathStart'),
                        tooltipText: translator('app.lineage.lineageMenuParhStartHint'),
                        //selector: 'node',
                        onClickFunction: function (evnt) {
                            callback('PATH_START', evnt);
                        }
                    },
                    {
                        id: 'PATH_END',
                        content: translator('app.lineage.lineageMenuPathEnd'),
                        tooltipText: translator('app.lineage.lineageMenuPathEndHint'),
                        //selector: 'node',
                        onClickFunction: function (evnt) {
                            callback('PATH_END', evnt);
                        }
                    }
                ],
                hasTrailingDivider: true
            },
            {
                id: 'FIT',
                content: translator('app.lineage.lineageMenuShowAll'),
                tooltipText: translator('app.lineage.lineageMenuShowAllHint'),
                selector: 'node, edge',
                onClickFunction: function (evnt) {
                    callback('FIT', evnt);
                },
                coreAsWell: true
            }
        ],
        // css classes that menu items will have
        menuItemClasses: [
            // add class names to this list
        ],
        // css classes that context menu will have
        contextMenuClasses: [
            // add class names to this list
        ],
        // Indicates that the menu item has a submenu. If not provided default one will be used
        //submenuIndicator: { src: 'assets/submenu-indicator-default.svg', width: 12, height: 12 }
    };

    return options;
};

/**
 * 
 * @returns 
 */
const getLayoutOptions = () => {
    let options = {
        name: 'klay',
        // dagre algo options, uses default value on undefined
        nodeSep: 20, // the separation between adjacent nodes in the same rank
        edgeSep: 30, // the separation between adjacent edges in the same rank
        rankSep: 70, // the separation between adjacent nodes in the same rank
        rankDir: 'LR', // 'TB' for top to bottom flow, 'LR' for left to right,
        //align: 'DR',
        //ranker: 'network-simplex', // Type of algorithm to assigns a rank to each node in the input graph.
        // Possible values: network-simplex, tight-tree or longest-path
        minLen: function minLen(edge) {
            return 1;
        }, // number of ranks to keep between the source and target of the edge
        edgeWeight: function edgeWeight(edge) {
            return 1;
        }, // higher weight edges are generally made shorter and straighter than lower weight edges
        // general layout options
        fit: true, // whether to fit to viewport
        padding: 50, // fit padding
        spacingFactor: undefined, // Applies a multiplicative factor (>0) to expand or compress the overall area that the nodes take up
        nodeDimensionsIncludeLabels: true, // whether labels should be included in determining the space used by a node
        animate: true, // whether to transition the node positions
        animateFilter: function animateFilter(node, i) {
            return true;
        }, // whether to animate specific nodes when animation is on; non-animated nodes immediately go to their final positions
        animationDuration: 200, // duration of animation in ms if enabled
        animationEasing: undefined, // easing of animation if enabled
        boundingBox: undefined, // constrain layout bounds; { x1, y1, x2, y2 } or { x1, y1, w, h }
        transform: function transform(node, pos) {
            return pos;
        }, // a function that applies a transform to the final node position
        ready: function ready() { }, // on layoutready
        stop: function stop() { }, // on layoutstop
        klay: {
            // Following descriptions taken from http://layout.rtsys.informatik.uni-kiel.de:9444/Providedlayout.html?algorithm=de.cau.cs.kieler.klay.layered
            addUnnecessaryBendpoints: true, // Adds bend points even if an edge does not change direction.
            aspectRatio: 10.6, // The aimed aspect ratio of the drawing, that is the quotient of width by height
            borderSpacing: 30, // Minimal amount of space to be left to the border
            compactComponents: false, // Tries to further compact components (disconnected sub-graphs).
            crossingMinimization: 'LAYER_SWEEP', // Strategy for crossing minimization.
            /* LAYER_SWEEP The layer sweep algorithm iterates multiple times over the layers, trying to find node orderings that minimize the number of crossings. The algorithm uses randomization to increase the odds of finding a good result. To improve its results, consider increasing the Thoroughness option, which influences the number of iterations done. The Randomization seed also influences results.
            INTERACTIVE Orders the nodes of each layer by comparing their positions before the layout algorithm was started. The idea is that the relative order of nodes as it was before layout was applied is not changed. This of course requires valid positions for all nodes to have been set on the input graph before calling the layout algorithm. The interactive layer sweep algorithm uses the Interactive Reference Point option to determine which reference point of nodes are used to compare positions. */
            cycleBreaking: 'GREEDY', // Strategy for cycle breaking. Cycle breaking looks for cycles in the graph and determines which edges to reverse to break the cycles. Reversed edges will end up pointing to the opposite direction of regular edges (that is, reversed edges will point left if edges usually point right).
            /* GREEDY This algorithm reverses edges greedily. The algorithm tries to avoid edges that have the Priority property set.
            INTERACTIVE The interactive algorithm tries to reverse edges that already pointed leftwards in the input graph. This requires node and port coordinates to have been set to sensible values.*/
            direction: 'RIGHT', // Overall direction of edges: horizontal (right / left) or vertical (down / up)
            /* UNDEFINED, RIGHT, LEFT, DOWN, UP */
            edgeRouting: 'ORTHOGONAL', // Defines how edges are routed (POLYLINE, ORTHOGONAL, SPLINES)
            edgeSpacingFactor: 5, //2 ; 0.5 Factor by which the object spacing is multiplied to arrive at the minimal spacing between edges.
            feedbackEdges: true, // Whether feedback edges should be highlighted by routing around the nodes.
            fixedAlignment: 'BALANCED', // Tells the BK node placer to use a certain alignment instead of taking the optimal result.  This option should usually be left alone.
            /* NONE Chooses the smallest layout from the four possible candidates.
            LEFTUP Chooses the left-up candidate from the four possible candidates.
            RIGHTUP Chooses the right-up candidate from the four possible candidates.
            LEFTDOWN Chooses the left-down candidate from the four possible candidates.
            RIGHTDOWN Chooses the right-down candidate from the four possible candidates.
            BALANCED Creates a balanced layout from the four possible candidates. */
            inLayerSpacingFactor: 2, // 1.5 Factor by which the usual spacing is multiplied to determine the in-layer spacing between objects.
            layoutHierarchy: true, // Whether the selected layouter should consider the full hierarchy
            linearSegmentsDeflectionDampening: 0.5, //10.5 ; 0.3 Dampens the movement of nodes to keep the diagram from getting too large.
            mergeEdges: false, // Edges that have no ports are merged so they touch the connected nodes at the same points.
            mergeHierarchyCrossingEdges: false, // If hierarchical layout is active, hierarchy-crossing edges use as few hierarchical ports as possible.
            nodeLayering: 'INTERACTIVE', // Strategy for node layering.
            /* NETWORK_SIMPLEX This algorithm tries to minimize the length of edges. This is the most computationally intensive algorithm. The number of iterations after which it aborts if it hasn't found a result yet can be set with the Maximal Iterations option.
            LONGEST_PATH A very simple algorithm that distributes nodes along their longest path to a sink node.
            INTERACTIVE Distributes the nodes into layers by comparing their positions before the layout algorithm was started. The idea is that the relative horizontal order of nodes as it was before layout was applied is not changed. This of course requires valid positions for all nodes to have been set on the input graph before calling the layout algorithm. The interactive node layering algorithm uses the Interactive Reference Point option to determine which reference point of nodes are used to compare positions. */
            nodePlacement: 'BRANDES_KOEPF', // Strategy for Node Placement
            /* BRANDES_KOEPF Minimizes the number of edge bends at the expense of diagram size: diagrams drawn with this algorithm are usually higher than diagrams drawn with other algorithms.
            LINEAR_SEGMENTS Computes a balanced placement.
            INTERACTIVE Tries to keep the preset y coordinates of nodes from the original layout. For dummy nodes, a guess is made to infer their coordinates. Requires the other interactive phase implementations to have run as well.
            SIMPLE Minimizes the area at the expense of... well, pretty much everything else. */
            randomizationSeed: 1, // Seed used for pseudo-random number generators to control the layout algorithm; 0 means a new seed is generated
            routeSelfLoopInside: false, // Whether a self-loop is routed around or inside its node.
            separateConnectedComponents: true, // Whether each connected component should be processed separately
            spacing: 10, //30 Overall setting for the minimal amount of space to be left between objects
            thoroughness: 20 // How much effort should be spent to produce a nice layout.. 7
        }
    };
    return options;
};

/**
 * 
 * @param {Object} elem 
 * @param {String} class
 * @returns 
 */
const createNodeElement = (elem, className) => {
    let classArr = [elem.type];

    if ( !isEmptyValue(className)) {
        classArr.push(className);
    }

    return {
        group: 'nodes', // 'nodes' for a node, 'edges' for an edge
        // NB the group field can be automatically inferred for you but specifying it
        // gives you nice debug messages if you mis-init elements
        data: { // element data (put json serialisable dev data here)
            id: elem.id, // mandatory (string) id for each element, assigned automatically on undefined
            parent: elem.parent, // indicates the compound node parent id; not defined => no parent
            // (`parent` can be effectively changed by `eles.move()`)
            label: elem.name,
            type: elem.type
        },
        /*
        // scratchpad data (usually temp or nonserialisable data)
        scratch: { _foo: 'bar' }, // app fields prefixed by underscore; extension fields unprefixed
        position: { x: 100,y: 100 }, // the model position of the node (optional on init, mandatory after)
        */
        selected: false, // whether the element is selected (default false)
        selectable: true, // whether the selection state is mutable (default true)
        locked: false, // when locked a node's position is immutable (default false)
        grabbable: true, // whether the node can be grabbed and moved by the user
        pannable: false, // whether dragging the node causes panning instead of grabbing
        classes: classArr // an array (or a space separated string) of class names that the element has
    };
};

/**
 * 
 * @param {Object} edg 
 * @returns 
 */
const createEdgeElement = (edg) => {
    return {
        group: 'edges',
        data: {
            id: `${edg.s}#${edg.t}`, //edg.s + '#' + edg.t,
            // inferred as an edge because `source` and `target` are specified:
            source: edg.s, // the source node id (edge comes from this node)
            //portsource: edg.s,
            target: edg.t,  // the target node id (edge goes to this node)
            //porttarget: edg.t,
            // (`source` and `target` can be effectively changed by `eles.move()`)
            label: edg.name,
            sourceType: edg.st,
            targetType: edg.tt,
            relType: edg.type
        },

        pannable: true // whether dragging on the edge causes panning
    };
};

const getSettingsList = (settingsListRequestResult) => {
    let settingsList = [];

    if (settingsListRequestResult.getState().isDone()) {
        settingsList = settingsListRequestResult.getData().filter(sL => sL.id === "lineage");
    }

    return settingsList;
};

const mapStateToProps = (state, ownProps) => {
    return {
        entryRequestResult: state.entryDetail,
        incomingRelationsRequestResult: state.entryDetailIncomingRelations,
        outgoingRelationsRequestResult: state.entryDetailOutgoingRelations,
        entryTypesRequestResult: state.objectList,
        lineageSettings: getSettingsList(state.settingsList),
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const {t} = ownProps;

    return {
        onMount: (entryId, shouldUpdateOnly = false) => {
            dispatch(fetchObjectList());
            dispatch(fetchSettingsList());
            dispatch(fetchDetail(entryId, shouldUpdateOnly));
            dispatch(fetchDetailIncomingRelations(entryId, shouldUpdateOnly));
            dispatch(fetchDetailOutgoingRelations(entryId, shouldUpdateOnly));
        },
        onUnmount: () => {
            dispatch(unmountDetail());
        },
        getCytoscapeOptions: (lineageContainer) => {
            return getCytoscapeOptions(lineageContainer);
        },
        getMenuOptions: (callback) => {
            return getMenuOptions(callback, t);
        },
        getLayoutOptions: () => {
            return getLayoutOptions();
        },
        createNodeElement: (elem, className) => {
            return createNodeElement(elem, className);
        },
        createEdgeElement: (edg) => {
            return createEdgeElement(edg);
        },
    }
};

const Lineage = connect(
    mapStateToProps,
    mapDispatchToProps
)(LineagePresenter);

export default withTranslation() (withRouter(Lineage));