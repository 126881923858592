import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Modal, Table, Select, Typography, Space, Tag } from 'antd';
import { withTranslation } from 'react-i18next'
import { fetchEntryDetailMinimal } from "../../../apicalls/fetchEntryDetail";
import { fetchIdentityListNoEntry } from "../../../apicalls/fetchIdentityListNoEntry";
import { isUndefined } from "../../../utils/JsObjectHelper";
import { fetchCreateEntryToIdentity } from "../../../apicalls/fetchCreateEntryToIdentity";
import { getColumnCustomAttributeProps } from "../../../utils/TableHelper";
import EntryTypeTag from "../../controls/EntryTypeTag";
const { Option } = Select;
const { Text } = Typography;

class WithoutAssignedEntriesTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRowKeys: [],
            isModalVisible: false,
            selectedMap: null,
            folderName: [],
            data: null

        };

    }
    componentDidMount(){
        if (isUndefined(this.state.data)) {
            this.getData()
        }
    }
    getData = () => {
        let data = [];
        fetchIdentityListNoEntry((res) => {
            res.filter((el) => el.deleted === null).forEach((element) => { element.key = element.id; data.push(element) })
            this.setState({ data: data })
        })
    }
    onSelectChange = (newSelectedRowKeys) => {
        this.setState({
            selectedRowKeys: newSelectedRowKeys
        });
    };
    handleOk = () => {
        this.setState({ isModalVisible: false });
        if (!isUndefined(this.state.selectedRowKeys) && !isUndefined(this.state.selectedMap)) {
            let obj = { identityIds: this.state.selectedRowKeys, mappingId: this.state.selectedMap.name }
            fetchCreateEntryToIdentity(obj, () => this.getData())

        }
    }
    handleCancel = () => {
        this.setState({ isModalVisible: false });
    }
    showModal = () => {
        this.setState({ isModalVisible: true });
    }
    onChangeSelect = (value) => {
        let idMap = this.props.idMapping.find((el) => el.name === value);
        fetchEntryDetailMinimal(idMap.value.folderId, search => this.setState({ folderName: search, selectedMap: idMap }));
    }


    render() {
        const { t } = this.props;
        let entryTypeNameList = null;

        if (this.props.entryTypesRequestResult.getState().isDone()) {
            entryTypeNameList = this.props.entryTypesRequestResult.getData().map((item, key) => { return { type: item.type, name: item.name, color: item.properties.typeColor }; });
        }
       

        let options = []
        if (this.props.idMapping) {
            options = this.props.idMapping.map((el) => <Option key={el.name} value={el.name}>{el.value.idMapName}</Option>)
        }


        const columns = [
            {
                title: t('setup.identity2entry.withoutAssignedEntriesTblName'),
                dataIndex: 'name',
            },
            {
                title: t('setup.identity2entry.withoutAssignedEntriesTblEmail'),
                dataIndex: 'email',
            },
            {
                title: t('setup.identity2entry.withoutAssignedEntriesTblID'),
                dataIndex: 'id',
            },
            {
                ...getColumnCustomAttributeProps(
                    "enabled",
                    t('setup.identity2entry.withoutAssignedEntriesTblEnabled'),
                    "boolean",
                    t
                )
            }


        ];
        let selectedRowKeys = this.state.selectedRowKeys
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        let detailMap = null
        if (this.state.selectedMap) {
            detailMap = <Space direction="vertical" style={{ marginTop: '20px', marginLeft: '10px' }}>
                <Text >{t('setup.identity2entry.modalItemEntryType')}<EntryTypeTag entryTypeName={this.state.selectedMap.value.entryType} entryTypeNameList={entryTypeNameList}></EntryTypeTag></Text>
                <Text>{t('setup.identity2entry.modalItemFolderName')}<span style={{ fontWeight: 'bold' }}>{this.state.folderName.name}</span></Text>
            </Space>
        }
        let modal = <Modal title={t('setup.identity2entry.modalTitle')} visible={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
            <div>
                <Select laceholder={t('setup.identity2entry.modalSelectPlaceholder')} style={{ width: "100%" }}
                    onChange={this.onChangeSelect}>
                    {options}
                </Select>
                {detailMap}
            </div>
        </Modal>

        return (
            <div>
                {modal}
                <Table rowSelection={rowSelection} columns={columns} loading={isUndefined(this.state.data)} dataSource={this.state.data} scroll={{
								x: true,
							  }}
                    footer={() => <Button type="primary" key="button" onClick={this.showModal} disabled={this.state.selectedRowKeys.length >0 ? false:true }>
                        {t('setup.identity2entry.btnMove')}
                    </Button>} />
            </div>
        );
    }
}

const WithoutAssignedEntriesTableWithRouter = withRouter(WithoutAssignedEntriesTable);

export default withTranslation()(WithoutAssignedEntriesTableWithRouter);